<div class="app-pillars-container" [class.app-pillars-cards-container]="cardsEnabled">
  <coh-app-breadcrumbs *ngIf="selectedPillar" [app]="selectedPillar.type"></coh-app-breadcrumbs>
  <div class="app-pillars-header" [class.cards-header]="cardsEnabled && !selectedPillar">
    <div class="app-pillars-header-subtitle" *ngIf="cardsEnabled && !selectedPillar">{{ 'welcomeTo' | translate }}</div>
    <div class="app-pillars-header-title">
      <span
        *ngIf="selectedPillar"
        class="app-pillars-header-icon"
        [class]="'app-pillars-header-' + selectedPillar.type">
      </span>
      <span [class.cards-title]="cardsEnabled && !selectedPillar">{{ title | translate }}</span>
    </div>
  </div>
  <div class="app-pillars-main-logo" *ngIf="selectedPillar || !cardsEnabled">
    <div *ngIf="globalEnabled && !selectedPillar"
      cogDataId="select-pillar-global"
      class="app-pillars-cloud app-pillars-cloud-global"
      [class]="selectedPillar ? ('app-pillars-cloud-' + selectedPillar.type) : null"
      uiSref="app-blank"
      [uiParams]="{ app: 'global' }"
      [matTooltip]="'global' | translate">
    </div>
    <div *ngIf="!globalEnabled || selectedPillar"
      class="app-pillars-cloud"
      [class]="selectedPillar ? ('app-pillars-cloud-' + selectedPillar.type) : null">
    </div>
  </div>
  <div class="pillar-item-container" *ngIf="!selectedPillar">
    <div
      class="pillar-item"
      *ngIf="cardsEnabled"
      cogDataId="select-pillar-global"
      uiSref="app-blank"
      [uiParams]="{ app: 'global' }"
      (mouseover)="isHover.global=true"
      (mouseleave)="isHover.global=false">
      <span class="pillar-icon">
        <span class="pillar-image pillar-image-global"></span>
      </span>
      <span class="pillar-title">{{ 'global' | translate }}</span>
    </div>
    <div
      class="pillar-item"
      *ngFor="let pillar of pillars"
      [cogDataId]="'select-pillar-' + pillar.type"
      (click)="select(pillar)"
      (mouseover)="isHover[pillar.type]=true"
      (mouseleave)="isHover[pillar.type]=false">
      <span class="pillar-icon">
        <span class="pillar-image {{'pillar-image-' + pillar.type}}"></span>
      </span>
      <span class="pillar-title">{{ pillar.title | translate }}</span>
    </div>
  </div>
  <div class="pillar-child-container" *ngIf="selectedPillar">
    <div
      class="pillar-child"
      *ngFor="let item of selectedPillar.items"
      [cogDataId]="'select-pillar-app' + item.app"
      uiSref="app-blank"
      [uiParams]="{ app: item.app }">
      <div class="pillar-title">{{ item.title | translate }}</div>
      <div *ngIf="item.titleDescription; else defaultContent">
        {{ item.titleDescription | translate }}
      </div>
      <ng-template #defaultContent>
        <div>{{ ('appPillars.description.' + item.app) | translate }}</div>
      </ng-template>
    </div>
  </div>
  <div class="cards-section jazz-dark-theme" *ngIf="!selectedPillar && cardsEnabled">
    <ng-content></ng-content>
  </div>
</div>
