import { Injectable } from '@angular/core';
import { ProtectedObject } from '@cohesity/api/v2';
import { NavItem } from '@cohesity/helix';
import { IrisContextService } from '@cohesity/iris-core';
import { StateService } from '@uirouter/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StateManagementService } from 'src/app/core/services';
import { RestoreConfigService } from 'src/app/modules/restore/restore-shared';
import { RecoveryAction } from 'src/app/shared';

import { MongodbPhysicalObjectActionCreator } from './mongodb-physical-action-creator';
import { ObjectActionCreator } from './object-action-creator';
import { ObjectActionProvider } from './object-action-provider';
import { ObjectInfoService } from './object-info.service';
import { SimpleObjectInfo } from './object-menu-provider';

/**
 * This is a simple implementation for construction object menu actions for MongoDB Physical.
 */
@Injectable()
export class MongodbPhysicalObjectActionProvider extends ObjectActionProvider {
  /**
   * The provider for this service is manually set up in object-actions-menu.service, which must provide
   * the list of providers as an array in the correct order. In order to maintain some kind of sanity,
   * the providers are listed here, they should match the order of the constructor args.
   */
  static MongodbPhysicalObjectActionProviderDependencies = [
    ObjectInfoService,
    RestoreConfigService,
    StateManagementService,
    StateService,
    ObjectActionCreator,
    IrisContextService,
    ObjectActionCreator,
    MongodbPhysicalObjectActionCreator,
  ];

  exclusiveProtection = true;

  supportsBulkActions = false;

  constructor(
    readonly objectStore: ObjectInfoService,
    readonly restoreConfig: RestoreConfigService,
    readonly stateManagementService: StateManagementService,
    readonly stateService: StateService,
    readonly actionCreator: ObjectActionCreator,
    readonly irisContextService: IrisContextService,
    private mongodbActionCreator: MongodbPhysicalObjectActionCreator,
  ) {
    super(objectStore, restoreConfig, stateManagementService, stateService, actionCreator, irisContextService);
  }

  /**
   * Returns whether we can protect the specified object.
   *
   * @param object The protected object.
   * @returns Whether it can be protected.
   */
  canProtect(object: SimpleObjectInfo): boolean {
    if (object.objectType !== 'kCluster') {
      return false;
    }

    return super.canProtect(object);
  }

  /**
   * Checks a protected object and recovery action to determine if the provided action is applicable.
   *
   * @param object The protected object
   * @param actionType A recovery action type
   * @returns True if the action can be applied to this object.
   */
  canRecover(actionType: RecoveryAction, object: ProtectedObject): boolean {
    return Boolean(object?.latestSnapshotsInfo) && (actionType === RecoveryAction.RecoverMongodbClusters);
  }

  getObjectActions(object: SimpleObjectInfo): Observable<NavItem[]> {
    return combineLatest([super.getObjectActions(object), this.getRecoverAction([object])]).pipe(
      map(([baseActions, mongodbActions]) => [
        ...this.filterProtectAction(baseActions, object.objectType),
        ...mongodbActions,
      ]),
      map(actions => actions.filter(action => Boolean(action)))
    );

  }

  /**
   * Creates and returns the MOngoDB physical action for the specified object.
   *
   * @param objects The protected objects.
   * @returns An observable, which yields the NavItem or null.
   */
  getRecoverAction(objects: SimpleObjectInfo[]): Observable<NavItem[]> {
    const recoveryAction = RecoveryAction.RecoverMongodbClusters;

    return super.getProtectedObjectSearch(objects).pipe(
      map(infos => infos.filter(info => info && this.canRecover(recoveryAction, info))),
      map(infos => [this.mongodbActionCreator.createMongodbPhysicalRecoverAction(infos[0], null, {
        accessClusterId: objects[0].accessClusterId,
        regionId: objects[0].regionId,
      })]),
    );
  }

  /**
   * Creates protect action only for the kCluster object.
   *
   * @param objects The protected objects.
   * @returns An observable, which yields the NavItem or null.
   */
  filterProtectAction(baseActions: NavItem[], objectType?: string): NavItem[] {
    // remove Protect action from object type
    if (objectType !== 'kCluster') {
      baseActions = baseActions.filter(action => action.displayName !== 'protect');
    }
    return baseActions;
  }
}
