import { NavItem } from '@cohesity/helix';
import { flagEnabled, IrisContext } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Generates the navigation list for Sizer/Platform Insights based on the feature enablement.
 *
 * @param translate translation service
 * @param irisCtx iris context
 * @returns navigation list for Sizer/Platform Insights
 */
export const getNavList = (translate: TranslateService, irisCtx: IrisContext): NavItem[] => {

  // for support users we need only live sizer section
  // we can enable others while integrating the non support user flow
  flagEnabled(irisCtx, 'liveSizerEnabled');
  // const navItems: NavItem[] = [
  //   flagEnabled(irisCtx, 'liveSizerEnabled') && {
  //     displayName: translate.instant('forecast'),
  //     icon: 'insights',
  //     subNavList: [
  //       flagEnabled(irisCtx, 'liveSizerEnabled') && {
  //         displayName: translate.instant('capacityPlanning'),
  //         href: '/resource-planning',
  //       },
  //       flagEnabled(irisCtx, 'simulationEnabled') && {
  //         displayName: translate.instant('simulation'),
  //         state: 'simulation.list',
  //         href: '/../monitoring/simulation/list',
  //       },
  //     ],
  //   },
  //   !flagEnabled(irisCtx, 'liveSizerEnabled') && flagEnabled(irisCtx, 'simulationEnabled') && {
  //     displayName: translate.instant('simulation'),
  //     state: 'simulation.list',
  //     href: '/../monitoring/simulation/list',
  //     icon: 'insights',
  //   },
  //   {
  //     displayName: translate.instant('reports'),
  //     state: 'reporting.list',
  //     href: '/../reporting/list',
  //     icon: 'assessment!outline',
  //     activeStates: ['reporting.detail', 'reporting.schedule'],
  //   }].filter(Boolean) as NavItem[];
  const navItems: NavItem[] = [
    {
      displayName: translate.instant('forecast'),
      icon: 'insights',
      subNavList: [
        {
          displayName: translate.instant('resourcePlanning'),
          href: '/resource-planning',
        },
      ],
    },
  ].filter(Boolean) as NavItem[];

  return navItems;
};
