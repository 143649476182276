/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FortknoxOnpremClusters } from '../models/fortknox-onprem-clusters';
import { FortknoxOnpremCluster } from '../models/fortknox-onprem-cluster';
import { CreateOrUpdateFortknoxOnpremClusterParams } from '../models/create-or-update-fortknox-onprem-cluster-params';
import { ClusterIdentifier } from '../models/cluster-identifier';
import { InitPairingParams } from '../models/init-pairing-params';
import { IsVaultClusterParams } from '../models/is-vault-cluster-params';
@Injectable({
  providedIn: 'root',
})
class FortknoxOnpremService extends __BaseService {
  static readonly GetFortknoxOnpremClustersPath = '/fortknox-onprem-clusters';
  static readonly RegisterFortknoxOnpremClusterPath = '/fortknox-onprem-clusters';
  static readonly InitPairingFortknoxOnpremClusterPath = '/fortknox-onprem-clusters/init-pairing';
  static readonly GetIsVaultClusterPath = '/fortknox-onprem-clusters/is-vault-cluster';
  static readonly UpdateIsVaultClusterPath = '/fortknox-onprem-clusters/is-vault-cluster';
  static readonly GetFortknoxOnpremClusterByIdPath = '/fortknox-onprem-clusters/{clusterId}';
  static readonly UpdateFortknoxOnpremClusterPath = '/fortknox-onprem-clusters/{clusterId}';
  static readonly DeleteFortknoxOnpremClusterPath = '/fortknox-onprem-clusters/{clusterId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get all registered Fortknox Onprem Clusters.
   *
   * List the Fortknox Onprem Clusters that are registered on this local Cluster and that matches the filter criteria specified using parameters. If this is a Primary Cluster, then Vault Clusters will be returned. If this is a Vault Cluster, then Primary Clusters will be returned.
   * @param params The `FortknoxOnpremService.GetFortknoxOnpremClustersParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `clusterNames`: Specifies a list of Cluster names to filter.
   *
   * - `clusterIds`: Specifies a list of Cluster ids to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFortknoxOnpremClustersResponse(params: FortknoxOnpremService.GetFortknoxOnpremClustersParams): __Observable<__StrictHttpResponse<FortknoxOnpremClusters>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.clusterNames || []).forEach(val => {if (val != null) __params = __params.append('clusterNames', val.toString())});
    (params.clusterIds || []).forEach(val => {if (val != null) __params = __params.append('clusterIds', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/fortknox-onprem-clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FortknoxOnpremClusters>;
      })
    );
  }
  /**
   * Get all registered Fortknox Onprem Clusters.
   *
   * List the Fortknox Onprem Clusters that are registered on this local Cluster and that matches the filter criteria specified using parameters. If this is a Primary Cluster, then Vault Clusters will be returned. If this is a Vault Cluster, then Primary Clusters will be returned.
   * @param params The `FortknoxOnpremService.GetFortknoxOnpremClustersParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `clusterNames`: Specifies a list of Cluster names to filter.
   *
   * - `clusterIds`: Specifies a list of Cluster ids to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFortknoxOnpremClusters(params: FortknoxOnpremService.GetFortknoxOnpremClustersParams): __Observable<FortknoxOnpremClusters> {
    return this.GetFortknoxOnpremClustersResponse(params).pipe(
      __map(_r => _r.body as FortknoxOnpremClusters)
    );
  }

  /**
   * Register a Fortknox Onprem Cluster.
   *
   * Register the Fortknox Onprem Cluster on this cluster. This API can only be called on the Vault Clusters and the Vault Cluster will be registered on the Primary Cluster automatically.
   * @param params The `FortknoxOnpremService.RegisterFortknoxOnpremClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the request to register a Primary Cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterFortknoxOnpremClusterResponse(params: FortknoxOnpremService.RegisterFortknoxOnpremClusterParams): __Observable<__StrictHttpResponse<FortknoxOnpremCluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/fortknox-onprem-clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FortknoxOnpremCluster>;
      })
    );
  }
  /**
   * Register a Fortknox Onprem Cluster.
   *
   * Register the Fortknox Onprem Cluster on this cluster. This API can only be called on the Vault Clusters and the Vault Cluster will be registered on the Primary Cluster automatically.
   * @param params The `FortknoxOnpremService.RegisterFortknoxOnpremClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the request to register a Primary Cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterFortknoxOnpremCluster(params: FortknoxOnpremService.RegisterFortknoxOnpremClusterParams): __Observable<FortknoxOnpremCluster> {
    return this.RegisterFortknoxOnpremClusterResponse(params).pipe(
      __map(_r => _r.body as FortknoxOnpremCluster)
    );
  }

  /**
   * Initialize the pairing of a Fortknox Onprem Cluster.
   *
   * Initialize the pairing of Fortknox Onprem cluster. This API can only be called on the Vault Clusters. This API will exchange CA certs and fetch the API key of the primary cluster.
   * @param params The `FortknoxOnpremService.InitPairingFortknoxOnpremClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the request to initialize the pairing of a Fortknox Onprem Cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  InitPairingFortknoxOnpremClusterResponse(params: FortknoxOnpremService.InitPairingFortknoxOnpremClusterParams): __Observable<__StrictHttpResponse<ClusterIdentifier>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/fortknox-onprem-clusters/init-pairing`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterIdentifier>;
      })
    );
  }
  /**
   * Initialize the pairing of a Fortknox Onprem Cluster.
   *
   * Initialize the pairing of Fortknox Onprem cluster. This API can only be called on the Vault Clusters. This API will exchange CA certs and fetch the API key of the primary cluster.
   * @param params The `FortknoxOnpremService.InitPairingFortknoxOnpremClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the request to initialize the pairing of a Fortknox Onprem Cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  InitPairingFortknoxOnpremCluster(params: FortknoxOnpremService.InitPairingFortknoxOnpremClusterParams): __Observable<ClusterIdentifier> {
    return this.InitPairingFortknoxOnpremClusterResponse(params).pipe(
      __map(_r => _r.body as ClusterIdentifier)
    );
  }

  /**
   * Get whether the cluster is a vault cluster.
   *
   * Get whether the cluster is a vault cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetIsVaultClusterResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<IsVaultClusterParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/fortknox-onprem-clusters/is-vault-cluster`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IsVaultClusterParams>;
      })
    );
  }
  /**
   * Get whether the cluster is a vault cluster.
   *
   * Get whether the cluster is a vault cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetIsVaultCluster(regionId?: string,
    accessClusterId?: number): __Observable<IsVaultClusterParams> {
    return this.GetIsVaultClusterResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as IsVaultClusterParams)
    );
  }

  /**
   * Update whether the cluster is a vault cluster.
   *
   * Update whether the cluster is a vault cluster. If a cluster is already vault cluster, it cannot be deconfigured.
   * @param params The `FortknoxOnpremService.UpdateIsVaultClusterParams` containing the following parameters:
   *
   * - `body`: Params to update whether the cluster is a vault cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateIsVaultClusterResponse(params: FortknoxOnpremService.UpdateIsVaultClusterParams): __Observable<__StrictHttpResponse<IsVaultClusterParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/fortknox-onprem-clusters/is-vault-cluster`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IsVaultClusterParams>;
      })
    );
  }
  /**
   * Update whether the cluster is a vault cluster.
   *
   * Update whether the cluster is a vault cluster. If a cluster is already vault cluster, it cannot be deconfigured.
   * @param params The `FortknoxOnpremService.UpdateIsVaultClusterParams` containing the following parameters:
   *
   * - `body`: Params to update whether the cluster is a vault cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateIsVaultCluster(params: FortknoxOnpremService.UpdateIsVaultClusterParams): __Observable<IsVaultClusterParams> {
    return this.UpdateIsVaultClusterResponse(params).pipe(
      __map(_r => _r.body as IsVaultClusterParams)
    );
  }

  /**
   * Get a Fortknox Onprem Cluster by id.
   *
   * Get a Fortknox Onprem Cluster by cluster id. If this is a Primary Cluster, then Vault Clusters will be returned. If this is a Vault Cluster, then Primary Clusters will be returned.
   * @param params The `FortknoxOnpremService.GetFortknoxOnpremClusterByIdParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of Fortknox Onprem Cluster to fetch.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFortknoxOnpremClusterByIdResponse(params: FortknoxOnpremService.GetFortknoxOnpremClusterByIdParams): __Observable<__StrictHttpResponse<FortknoxOnpremCluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/fortknox-onprem-clusters/${encodeURIComponent(params.clusterId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FortknoxOnpremCluster>;
      })
    );
  }
  /**
   * Get a Fortknox Onprem Cluster by id.
   *
   * Get a Fortknox Onprem Cluster by cluster id. If this is a Primary Cluster, then Vault Clusters will be returned. If this is a Vault Cluster, then Primary Clusters will be returned.
   * @param params The `FortknoxOnpremService.GetFortknoxOnpremClusterByIdParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of Fortknox Onprem Cluster to fetch.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFortknoxOnpremClusterById(params: FortknoxOnpremService.GetFortknoxOnpremClusterByIdParams): __Observable<FortknoxOnpremCluster> {
    return this.GetFortknoxOnpremClusterByIdResponse(params).pipe(
      __map(_r => _r.body as FortknoxOnpremCluster)
    );
  }

  /**
   * Update the registration of a Fortknox Onprem Cluster.
   *
   * Update the registration of the Fortknox Onprem Cluster specified by the cluster id. This API can only be called on the Vault Clusters. The updates will be relayed to the Primary Cluster automatically.
   * @param params The `FortknoxOnpremService.UpdateFortknoxOnpremClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of the Fortknox Onprem Cluster to update.
   *
   * - `body`: Specifies the request to update Fortknox Onprem Cluster config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateFortknoxOnpremClusterResponse(params: FortknoxOnpremService.UpdateFortknoxOnpremClusterParams): __Observable<__StrictHttpResponse<FortknoxOnpremCluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/fortknox-onprem-clusters/${encodeURIComponent(params.clusterId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FortknoxOnpremCluster>;
      })
    );
  }
  /**
   * Update the registration of a Fortknox Onprem Cluster.
   *
   * Update the registration of the Fortknox Onprem Cluster specified by the cluster id. This API can only be called on the Vault Clusters. The updates will be relayed to the Primary Cluster automatically.
   * @param params The `FortknoxOnpremService.UpdateFortknoxOnpremClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of the Fortknox Onprem Cluster to update.
   *
   * - `body`: Specifies the request to update Fortknox Onprem Cluster config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateFortknoxOnpremCluster(params: FortknoxOnpremService.UpdateFortknoxOnpremClusterParams): __Observable<FortknoxOnpremCluster> {
    return this.UpdateFortknoxOnpremClusterResponse(params).pipe(
      __map(_r => _r.body as FortknoxOnpremCluster)
    );
  }

  /**
   * Unregister an Fortknox Onprem Cluster.
   *
   * Unregister a Fortknox Onprem Cluster. This API can only be called on the Vault Clusters. The Vault Cluster will be removed from the Primary Cluster automatically.
   * @param params The `FortknoxOnpremService.DeleteFortknoxOnpremClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of the Fortknox Onprem Cluster to unregister.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteFortknoxOnpremClusterResponse(params: FortknoxOnpremService.DeleteFortknoxOnpremClusterParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/fortknox-onprem-clusters/${encodeURIComponent(params.clusterId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Unregister an Fortknox Onprem Cluster.
   *
   * Unregister a Fortknox Onprem Cluster. This API can only be called on the Vault Clusters. The Vault Cluster will be removed from the Primary Cluster automatically.
   * @param params The `FortknoxOnpremService.DeleteFortknoxOnpremClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of the Fortknox Onprem Cluster to unregister.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteFortknoxOnpremCluster(params: FortknoxOnpremService.DeleteFortknoxOnpremClusterParams): __Observable<null> {
    return this.DeleteFortknoxOnpremClusterResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module FortknoxOnpremService {

  /**
   * Parameters for GetFortknoxOnpremClusters
   */
  export interface GetFortknoxOnpremClustersParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies a list of Cluster names to filter.
     */
    clusterNames?: Array<string>;

    /**
     * Specifies a list of Cluster ids to filter.
     */
    clusterIds?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RegisterFortknoxOnpremCluster
   */
  export interface RegisterFortknoxOnpremClusterParams {

    /**
     * Specifies the request to register a Primary Cluster.
     */
    body: CreateOrUpdateFortknoxOnpremClusterParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for InitPairingFortknoxOnpremCluster
   */
  export interface InitPairingFortknoxOnpremClusterParams {

    /**
     * Specifies the request to initialize the pairing of a Fortknox Onprem Cluster.
     */
    body: InitPairingParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateIsVaultCluster
   */
  export interface UpdateIsVaultClusterParams {

    /**
     * Params to update whether the cluster is a vault cluster.
     */
    body: IsVaultClusterParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetFortknoxOnpremClusterById
   */
  export interface GetFortknoxOnpremClusterByIdParams {

    /**
     * Specifies the cluster id of Fortknox Onprem Cluster to fetch.
     */
    clusterId: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateFortknoxOnpremCluster
   */
  export interface UpdateFortknoxOnpremClusterParams {

    /**
     * Specifies the cluster id of the Fortknox Onprem Cluster to update.
     */
    clusterId: number;

    /**
     * Specifies the request to update Fortknox Onprem Cluster config.
     */
    body: CreateOrUpdateFortknoxOnpremClusterParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteFortknoxOnpremCluster
   */
  export interface DeleteFortknoxOnpremClusterParams {

    /**
     * Specifies the cluster id of the Fortknox Onprem Cluster to unregister.
     */
    clusterId: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { FortknoxOnpremService }
