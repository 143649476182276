import { AzureSqlSkuOptions } from '@cohesity/api/v2';

/**
 * Azure supported backup type definition.
 */
export enum AzureBackupType {
  kSQLServer = 'kSQLServer',
  kVirtualMachine = 'kVirtualMachine',
  kSQLDatabase = 'kSQLDatabase',
  kSQLManagedInstance = 'kSQLManagedInstance',
  kAzureEntraID = 'kAzureEntraID',
}

/**
 * Provides configuration data for Azure source detail workload tabs.
 */
export const azureWorkloadConfigs = [
  {
    dmsFlag: 'dmsAzureVmWorkload',
    onPremFlag: null,
    displayName: 'azureVm',
    workloadType: AzureBackupType.kVirtualMachine,
  },
  {
    dmsFlag: 'dmsAzureSqlWorkload',
    onPremFlag: null,
    displayName: 'azureSql',
    workloadType: AzureBackupType.kSQLDatabase,
  },
  {
    dmsFlag: 'dmsAzureEntraIdWorkload',
    onPremFlag: null,
    displayName: 'entraId',
    workloadType: AzureBackupType.kAzureEntraID,
  }
];

/**
 * Supported various Azure Leaf entities.
 */
export const AzureLeafTypes: string[] = [
  'kVirtualMachine',
  'kSQLDatabase',
  'kEntraID',
];

/**
 * Supported various Azure object types.
 */
export const AzureObjectTypes: string[] = [
  'kSQLServer',
  'kVirtualMachine',
  'kSQLDatabase',
  'kSQLManagedInstance',
  'kEntraID',
];

/**
 * Azure supported use cases type definition.
 */
export enum AzureUseCaseType {
  kSQL = 'kSQL',
  kVirtualMachine = 'kVirtualMachine',
  kEntraID = 'kEntraID',
}

/**
 * Azure supported workload type definition.
 */
export enum AzureSupportedWorkloadType {
  kSQL = 'kSQLDatabase',
  kVirtualMachine = 'kVirtualMachine',
  kAzureEntraID = 'kAzureEntraID',
}

/**
 * Azure workload to usecase map
 *
 * @see azureWorkloadConfigs
 */
export const AzureWorkloadUseCaseMap: Record<AzureBackupType, AzureUseCaseType> = {
  [AzureBackupType.kSQLDatabase]: AzureUseCaseType.kSQL,
  [AzureBackupType.kSQLServer]: AzureUseCaseType.kSQL,
  [AzureBackupType.kSQLManagedInstance]: AzureUseCaseType.kSQL,
  [AzureBackupType.kVirtualMachine]: AzureUseCaseType.kVirtualMachine,
  [AzureBackupType.kAzureEntraID]: AzureUseCaseType.kEntraID,
};

/**
 * Azure entity object type to workload type map
 *
 * @see azureWorkloadConfigs
 */
export const AzureObjectTypeWorkloadMap: Record<AzureBackupType, AzureSupportedWorkloadType> = {
  [AzureBackupType.kSQLDatabase]: AzureSupportedWorkloadType.kSQL,
  [AzureBackupType.kSQLServer]: AzureSupportedWorkloadType.kSQL,
  [AzureBackupType.kSQLManagedInstance]: AzureSupportedWorkloadType.kSQL,
  [AzureBackupType.kVirtualMachine]: AzureSupportedWorkloadType.kVirtualMachine,
  [AzureBackupType.kAzureEntraID]: AzureSupportedWorkloadType.kAzureEntraID,
};

/**
 * Azure usecase to workload map
 *
 * @see azureWorkloadConfigs
 */
export const AzureUseCaseWorkloadMap: Record<AzureUseCaseType, AzureBackupType> = {
  [AzureUseCaseType.kSQL]: AzureBackupType.kSQLDatabase,
  [AzureUseCaseType.kVirtualMachine]: AzureBackupType.kVirtualMachine,
  [AzureUseCaseType.kEntraID]: AzureBackupType.kAzureEntraID,
};

/**
 * Azure supported registration workflow type definition.
 */
export enum AzureRegistrationWorkflowType {
  kManual = 'kManual',
  kExpress = 'kExpress',
}

/**
 * Azure supported registration level type definition.
 */
export enum AzureRegistrationLevelType {
  kTenant = 'kTenant',
  kSubscription = 'kSubscription',
}

/**
 * Supported Azure SQL entities.
 */
export const AzureSQLEntityTypes: string[] = [
  AzureBackupType.kSQLServer,
  AzureBackupType.kSQLDatabase,
  AzureBackupType.kSQLManagedInstance,
];

/**
 * Azure SQL database available authentication type options.
 */
export enum AzureSqlDBAuthenticationOptions {
  ManagedIdentity,
  Credentials
};

/**
 * Specifies the type of leaf entities for Azure.
 */
export enum AzureLeafNodeType {
  /**
   * Specifies the leaf entity for Azure SQL workload.
   */
  kSQLDatabase = 'kSQLDatabase',

  /**
   * Specifies the leaf entity for Azure VM workload.
   */
  kVirtualMachine = 'kVirtualMachine',

  /**
   * Specifies the leaf entity for Azure Entra ID workload.
   */
  kEntraID = 'kEntraID',
}

/**
 * SKU Tier Type to available name mapping.
 */
export const skuTierTypeToAvailableNameOptionsMap = {
  Basic: [
    'Basic'
  ],
  Free: [
    'Free'
  ],
  Standard: [
    'Standard',
  ],
  Premium: [
    'Premium',
  ],
  BusinessCritical: [
    'BC_DC',
    'BC_Gen5',
    'BC_M',
  ],
  GeneralPurpose: [
    'GP_DC',
    'GP_Fsv2',
    'GP_Gen5',
    'GP_S_Gen5',
  ],
  Hyperscale: [
    'HS_DC',
    'HS_Gen5',
    'HS_S_Gen5',
    'HS_MOPRMS',
    'HS_PRMS',
  ],
  DataWarehouse: [
    'DataWarehouse',
  ],
  Stretch: [
    'Stretch'
  ],
};

/**
 * Azure SQL supported SKU options.
 */
export enum AzureSQLSKU {
  Basic = 'Basic',
  Standard = 'Standard',
  Premium = 'Premium',
  BusinessCritical = 'BusinessCritical',
  GeneralPurpose = 'GeneralPurpose',
  Hyperscale = 'Hyperscale',
  DataWarehouse = 'DataWarehouse',
  Stretch = 'Stretch',
}

/**
 * List of compression options available for Azure SQL Object Protection & Recovery.
 */
export const AzureSQLCompressionOptions = [
  {
    label: 'normal',
    value: 'Normal',
  },
  {
    label: 'maximum',
    value: 'Maximum',
  },
  {
    label: 'fast',
    value: 'Fast',
  },
  {
    label: 'superFast',
    value: 'SuperFast',
  },
  {
    label: 'notCompressed',
    value: 'NotCompressed',
  },
];

/**
 * List of SKU options available for for Azure SQL Object Protection & Recovery.
 */
export const AzureSQLSKUOptions = [
  {
    label: 'basic',
    name: AzureSQLSKU.Basic,
  },
  {
    label: 'standard',
    name: AzureSQLSKU.Standard,
  },
  {
    label: 'premium',
    name: AzureSQLSKU.Premium,
  },
  {
    label: 'businessCritical',
    name: AzureSQLSKU.BusinessCritical,
  },
  {
    label: 'generalPurpose',
    name: AzureSQLSKU.GeneralPurpose,
  },
  {
    label: 'hyperscale',
    name: AzureSQLSKU.Hyperscale,
  },
  {
    label: 'dataWarehouse',
    name: AzureSQLSKU.DataWarehouse,
  },
  {
    label: 'stretch',
    name: AzureSQLSKU.Stretch,
  },
];

/**
 * Default SKU value for Azure SQL
 */
export const DefaultAzureSQLSKUValue: AzureSqlSkuOptions = {
  name: 'HS_Gen5',
  tierType: AzureSQLSKU.Hyperscale,
  capacity: 4,
};

/**
 * Specifies Azure entra id supported object types.
 */
export enum AzureEntraIdObjectType {
  AdminUnit = 'AdminUnit',
  Application = 'Application',
  Contact = 'Contact',
  Device = 'Device',
  DirRole = 'DirRole',
  Group = 'Group',
  ServicePrincipal = 'ServicePrincipal',
  User = 'User',
}

/**
 * Specifies list of Azure entra id supported object types.
 */
export const AzureEntraIdObjectTypes: string[] = [
  AzureEntraIdObjectType.AdminUnit,
  AzureEntraIdObjectType.Application,
  AzureEntraIdObjectType.Contact,
  AzureEntraIdObjectType.Device,
  AzureEntraIdObjectType.DirRole,
  AzureEntraIdObjectType.Group,
  AzureEntraIdObjectType.ServicePrincipal,
  AzureEntraIdObjectType.User
];

/**
 * List of inclusion options available for Azure Entra ID Object Protection & Recovery.
 */
export const AzureEntraIdInclusionList = [
  {
    label: 'users',
    name: AzureEntraIdObjectType.User,
  },
  {
    label: 'applications',
    name: AzureEntraIdObjectType.Application,
  },
  {
    label: 'groups',
    name: AzureEntraIdObjectType.Group,
  },
  {
    label: 'administrativeUnits',
    name: AzureEntraIdObjectType.AdminUnit,
  },
  {
    label: 'servicePrincipals',
    name: AzureEntraIdObjectType.ServicePrincipal,
  },
  {
    label: 'contacts',
    name: AzureEntraIdObjectType.Contact,
  },
  {
    label: 'rolesAndAdministrators',
    name: AzureEntraIdObjectType.DirRole,
  },
  {
    label: 'devices',
    name: AzureEntraIdObjectType.Device,
  },
];

/**
 * Specifies Azure entra id supported node types.
 */
export enum AzureEntraIdNodeType {
  AdministrativeUnit = 'administrativeUnit',
  Application = 'application',
  Contact = 'orgContact',
  Device = 'device',
  DirectoryRole = 'directoryRole',
  Group = 'group',
  ServicePrincipal = 'servicePrincipal',
  User = 'user',
}

/**
 * Specifies Azure AD inclusion options.
 */
export interface AzureAdInclusionOptions {

  // Inclusion list
  inclusionList: string[];
}

/**
 * Definition of Azure Entra ID recovery type.
 */
export enum AzureEntraIdRecoveryType {
  PropertyAndRelation = 'PropertyAndRelation',
  PropertyOnly = 'PropertyOnly',
  RelationOnly = 'RelationOnly',
}

/**
 * Subscription Url
 */
export const SubscriptionUrl = 'https://management.azure.com/subscriptions?api-version=2022-12-01';

/**
 * Scope for Microsoft Graph API permissions
 */
export const GraphScope = 'Application.ReadWrite.All';

/**
 * Scope for Azure Management API permissions
 */
export const ManagementScope = 'https://management.azure.com/user_impersonation';

/**
 * The subscription returned from the management API is in the form of /subscription/id.
 * Creating a constant to truncate /subscription/ and preserve only the id.
 */
export const subcription = '/subscriptions/';

/**
 * Constant representing Azure applications.
 * This is used to identify and manage Azure apps in the application.
 */
export const azureApps = 'azureApps';

/**
 * Enums representing the Azure registration type
 */
export enum AzureRegistrationType {
  /**
   * Represents an express registration type in Azure.
   * This type streamlines the setup process with predefined configurations,
   * authenticates the user within the organization, creates an application
   * in their tenant, and fetches subscriptions without user intervention.
   * Users can then select the subscriptions they want to protect as part of
   * the registration flow, aiming for quicker deployment of Azure services or
   * resources.
   */
  EXPRESS = 'kExpress',

  /**
   * Represents a manual registration type in Azure.
   * This type involves user-initiated setup where users manually configure
   * and manage their Azure resources and services.
   * It allows for customized deployment and control over subscription choices,
   * suitable for users who prefer a more hands-on approach in managing Azure environments.
   */
  MANUAL = 'kManual',
}

/**
 * Component states for authorization and app creation
 */
export enum ExpressRegistrationStatus {
  PENDING = 'Pending',
  IN_PROGRESS = 'InProgress',
  FAILED = 'Failed',
  SKIP = 'Skip',
  SUCCESS = 'Completed',
}

/**
 * Enum representing the Azure express registration stages
 */
export enum ExpressRegistrationStep {
  AUTHORIZATION = 'authorization',
  APP_CREATION = 'appCreation',
  SUBSCRIPTION_DISCOVERY = 'subscriptionDiscovery',
  DELETE_SOURCE = 'deleteSource',
}

/*
 * Azure AD node type to relation type mapper definition
 */
export const AzureADRelationTypeMapping = {
  ['#microsoft.graph.user']: [
    'MEMBER-OF-GROUP',
    'MEMBER-OF-ADMINUNIT',
    'OWNER',
    'MANAGER',
    'REPORTS-TO',
    'PRINCIPAL-OF-ARA',
  ],
  ['#microsoft.graph.application']: [
    'OWNED-BY'
  ],
  ['#microsoft.graph.group']: [
    'GROUP-MEMBER',
    'OWNED-BY',
  ],
  ['#microsoft.graph.administrativeUnit']: [
    'ADMINUNIT-MEMBER'
  ],
  ['#microsoft.graph.servicePrincipal']: [],
  ['#microsoft.graph.orgContact']: [],
  ['#microsoft.graph.directoryRole']: [],
  ['#microsoft.graph.device']: [],
};

/**
 * Azure AD node type to icon mapper definition
 */
export const AzureAdNodeTypeIconMapper = {
  ['#microsoft.graph.user']: 'person_outline',
  ['#microsoft.graph.application']: 'helix:object-ad-applications',
  ['#microsoft.graph.group']: 'helix:object-user-group',
  ['#microsoft.graph.administrativeUnit']: 'helix:object-agent-management',
  ['#microsoft.graph.servicePrincipal']: 'helix:object-ad-applications',
  ['#microsoft.graph.orgContact']: 'helix:object-o365-contact',
  ['#microsoft.graph.directoryRole']: 'helix:object-ad-org',
  ['#microsoft.graph.device']: 'helix:vault',
};

/**
 * Azure AD node type to object type mapper definition
 */
export const AzureAdNodeTypeToObjectTypeMapper = {
  ['#microsoft.graph.user']: 'kUser',
  ['#microsoft.graph.application']: 'kApplication',
  ['#microsoft.graph.group']: 'kGroup',
  ['#microsoft.graph.administrativeUnit']: 'kAdminUnit',
  ['#microsoft.graph.servicePrincipal']: 'kServicePrincipal',
  ['#microsoft.graph.orgContact']: 'kContact',
  ['#microsoft.graph.directoryRole']: 'kDirRole',
  ['#microsoft.graph.device']: 'kDevice',
};

/**
 * Azure Entra Id node type enum definition.
 */
export enum AzureAdNodeType {
  User = '#microsoft.graph.user',
  Application = '#microsoft.graph.application',
  Group = '#microsoft.graph.group',
  AdministrativeUnit = '#microsoft.graph.administrativeUnit',
  ServicePrincipal = '#microsoft.graph.servicePrincipal',
  Contact = '#microsoft.graph.orgContact',
  DirectoryRole = '#microsoft.graph.directoryRole',
  Device = '#microsoft.graph.device'
}

/**
 * Maps each ExpressRegistrationStatus to a corresponding icon state.
 * This mapping is used to display the appropriate icon based on the registration status.
 */
export const statusIconMap: Record<ExpressRegistrationStatus, string> = {
  [ExpressRegistrationStatus.PENDING]: 'pending',
  [ExpressRegistrationStatus.IN_PROGRESS]: 'in_progress',
  [ExpressRegistrationStatus.SKIP]: 'success',
  [ExpressRegistrationStatus.SUCCESS]: 'success',
  [ExpressRegistrationStatus.FAILED]: 'failed'
};

/**
 * Maps each ExpressRegistrationStep to a corresponding title.
 * This mapping is used to retrieve the title for display based on the registration step.
 */
export const stepTitleMap: Record<ExpressRegistrationStep, string> = {
  [ExpressRegistrationStep.AUTHORIZATION]: 'authorization',
  [ExpressRegistrationStep.APP_CREATION]: 'azureApp',
  [ExpressRegistrationStep.DELETE_SOURCE]: 'deleteSource',
  [ExpressRegistrationStep.SUBSCRIPTION_DISCOVERY]: 'discoverSubscriptions',
};

/**
 * DataAccess Session Status definition
 */
export enum DataAccessSessionStatus {
  Pending = 'Pending',
  Established = 'Established',
  Terminated = 'Terminated',
  RefreshInitiated = 'RefreshInitiated',
}
