import { pickBy } from 'lodash-es';
import { get } from 'lodash-es';
// Module: Roles Management - Edit

;(function(angular) {
  'use strict';

  angular
    .module('C.manageRoles')
    .controller('editRoleController', editRoleControllerFn);

  function editRoleControllerFn($rootScope, $scope, $state, $q,
    cModal, UserService, evalAJAX, FORMATS) {

    $scope.text = $rootScope.text.adminRolesEdit;

    $scope.errorText = $rootScope.text.adminRolesEdit.errorText;

    $scope.isEditMode = $state.current.name === 'edit-role';

    // List of all custome roles in the cluster.
    $scope.roles = [];

    // Model for the new/edited role
    $scope.role = {
      name: '',
      description: '',
      privileges: ['MANAGE_PROTECTION_SOURCE_ALL'],
    };

    // Hash of privileges grouped by category. key = 'name'
    $scope.privileges = {};

    this.$onInit = function $onInit() {
      if ($state.params.roleCopy) {
        // If a role copy object is present, use that for the new role.
        const {label, description, privileges} = $state.params.roleCopy;

        $scope.role = {
          label,
          description,
          privileges,
        };
      }

      if ($state.params.name) {
        UserService.getRole({
          name: $state.params.name,

          // Set allUnderHierarchy true to only view the role which are assigned
          // to a tenant.
          allUnderHierarchy: true,
        }).then(function handleResponse(role) {
          $scope.roleLoaded = true;
          $scope.role = role;
        });
      }

      _getAllRoles();
    };

    /**
     * Call API service to get all roles
     *
     * @method     getAllRoles
     */
    function _getAllRoles() {
      UserService.getAllRoles().then(
        function getAllRolesSuccess(roles) {
          $scope.roles = (roles || []).map(function reduceRoleName(role) {
            return role.label.toLowerCase();
          });
        },
      );
    }

    /**
     * Checks if the user can modify the given role.
     *
     * @method   canModify
     * @param    {Object}   role   The role object to check against.
     * @returns  {Boolean}  True, if the user is able to modify else false.
     */
    $scope.canModify = function canModify(role) {
      return role.isCustomRole && $rootScope.user.privs.PRINCIPAL_MODIFY &&
        role._isRoleOwner;
    };

    /**
     * Cancel form and redirect to list view
     *
     * @method     cancelForm
     */
    $scope.cancelForm = function cancelForm() {
      // goto parent state
      $state.go('access-management.roles', {}, {location:'replace'});
    };

    /**
     * Checks the Role name against the list of existing Roles to see if it
     * is a duplicate.
     *
     * @method  validateDuplicate
     */
    $scope.validateDuplicate = function validateDuplicate(newName) {
      var isDuplicate = $scope.roles.includes(newName.toLowerCase());
      $scope.roleForm.name.$setValidity('duplicate', !isDuplicate);
    }

    /**
     * On form submit, prepare request object and delegate next action.
     *
     * @method     submitForm
     * @param      {Object}  form    The form
     */
    $scope.submitForm = function submitForm(form) {
      var sourceManagementCategory;
      var protectionSourceModifyPrivs;

      // Trim leading/trailing spaces.
      $scope.role.label = $scope.role.label.trim();

      if (form.$invalid) {
        return;
      }

      // When creating a new role, copy the label to the name (key) property
      // because backend does not generate a unique key.
      if (!$scope.isEditMode) {
        $scope.role.name = $scope.role.label;
      }

      $scope.role.privileges.length = 0;

      sourceManagementCategory = $scope.privileges['Source Management'];
      protectionSourceModifyPrivs = get($scope.privileges,
          `['Data Protection'].privileges['PROTECTION_SOURCE_MODIFY'].checked`, false);
      // For Source Management if select all is provided, then add the
      // super set privilege MANAGE_PROTECTION_SOURCE_ALL, this will
      // eliminate the need to add support for new adapter for the custom
      // role and maintains backward compatibility.
      if (sourceManagementCategory.selectAll && protectionSourceModifyPrivs) {
        $scope.role.privileges.push('MANAGE_PROTECTION_SOURCE_ALL');
      }

      // Iterate over each category and its privileges. Check whether the user
      // selected the whole category or the single privilege. If either, add the
      // privilege to the list in the request object
      angular.forEach($scope.privileges, function parseCategories(cat, name) {
        var privileges = {};
        var roles = [];

        // Since we have handled 'Source Management' earlier, skip it.
        var pickAll = cat.selectAll && name !== 'Source Management';

        // Select only the privileges that are checked or category is selected.
        privileges = pickBy(cat.privileges, function pickPriv(priv) {
          return pickAll || priv.checked;
        });
        roles = Object.keys(privileges);
        $scope.role.privileges = $scope.role.privileges.concat(roles);
      });

      delegateServiceCall($scope.role);
    };

    /**
     * Calls the create or update API accordingly
     *
     * @method     delegateServiceCall
     * @param      {object}  role    The role object
     */
    function delegateServiceCall(role) {
      var serviceCall = $scope.isEditMode ?
        UserService.updateRole : UserService.createRole;

      $scope.submitting = true;

      serviceCall(role)
        .then($scope.cancelForm, evalAJAX.errorMessage)
        .finally(
          function modifyRoleFinally() {
            $scope.submitting = false;
          }
        );
    }
  }

})(angular);
