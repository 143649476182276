import { identity } from 'lodash-es';
import { merge } from 'lodash-es';
import { map } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Service: Policy

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.policyService', ['C.policyServiceFormatter'])
    .service('PolicyService', PolicyServiceFn);

  function PolicyServiceFn($http, $q, API, cUtils, TenantService, FEATURE_FLAGS,
    RemoteClusterService, SlideModalService, PolicyServiceFormatter) {

    var scheduleKeys = [
      'incrementalSchedulingPolicy',
      'fullSchedulingPolicy',
      'logSchedulingPolicy',
      'systemSchedulingPolicy',
    ];

    var policyCache = {};
    var globalPolicyCache = {};

    return {
      addProtectionObject: addProtectionObject,
      addProtectionObjectInCluster: addProtectionObjectInCluster,
      policyCache: policyCache,
      createPolicy: createPolicy,
      deletePolicy: deletePolicy,
      getFilteredPolicies: getFilteredPolicies,
      getGlobalPolicies: getGlobalPolicies,
      getNGPolicies: getNGPolicies,
      getPeriodicityString: getPeriodicityString,
      getPolicies: getPolicies,
      getPoliciesFromCluster: getPoliciesFromCluster,
      getPoliciesHashmap: getPoliciesHashmap,
      getPolicy: getPolicy,
      getRemotePolicy: getRemotePolicy,
      getScheduleKeys: getScheduleKeys,
      modifyPolicyModal: modifyPolicyModal,
      openEditPolicyModal: openEditPolicyModal,
      updatePolicy: updatePolicy,
    };

    ////////////////////////////////////////
    // PUBLIC METHODS
    ////////////////////////////////////////

    /**
     * Getter method for accessing a list of scheduleKeys which are used in
     * policy configuration.
     *
     * @method     getScheduleKeys
     * @return     {Array}  list of schedule keys
     */
    function getScheduleKeys() {
      // return a copy so requesting code can't change original array
      return angular.copy(scheduleKeys);
    }

    /**
     * call API to create a new policy
     *
     * @return {object}    promise to resolve request for creation,
     *                     resolving with the newly created policy object
     */
    function createPolicy(policy) {
      // untransform makes a copy internally, save it to a new var so any
      // transforms aren't exposed in the UI via reference.
      var policyCopy = PolicyServiceFormatter.untransformPolicy(policy);

      return $http({
        method: 'post',
        url: API.public('protectionPolicies'),
        data: policyCopy,
      }).then(
        function createPolicySuccess(response) {
          return policyCache[response.data.id] =
            PolicyServiceFormatter.transformPolicy(response.data);
        }
      );

    }

    /**
     * call API to get a specific policy
     *
     * @param {Integer}   id of the policy to get
     * @return {object}   promise to resolve request for policies.
     *                    resolves with the updated policy object on success
     */
    function updatePolicy(policy) {
      // untransform makes a copy internally, save it to a new var so any
      // transforms aren't exposed in the UI via reference.
      var policyCopy = PolicyServiceFormatter.untransformPolicy(policy);

      return $http({
        method: 'put',
        url: API.public('protectionPolicies', policyCopy.id),
        data: policyCopy,
      }).then(
        function updatePolicySuccess(response) {
          return policyCache[response.data.id] =
            PolicyServiceFormatter.transformPolicy(response.data);
        }
      );

    }

    /**
     * Gets the policies from a cluster provided
     *
     * @method   getPoliciesFromCluster
     * @param    {object}   params      for filtering policies
     * @param    {string}   clusterId   The cluster identifier
     * @return   {array}   promise to resolve request for policies
     */
    function getPoliciesFromCluster(params, clusterId) {
      return getPolicies(params, RemoteClusterService.getApiProxyHeader(clusterId))
    }

    /**
     * call API to get policies
     *
     * @method   getPolicies
     * @param    {object}   [params={}]   The parameters
     * @param    {object}   [headers]     The request headers
     * @return   {object}   promise to resolve request for policies. resolves
     *                      with array of policies on success
     */
    function getPolicies(params, headers) {
      params = params || {};
      return $http({
        method: 'get',
        params: params,
        url: API.public('protectionPolicies'),
        headers: headers,
      }).then(
        function getPoliciesSuccess(response) {
          var policies = (response.data || []).map(
            function mapPolicies(policy) {
              // If RPO Policy is not enabled, then filter them out from
              // policies list.
              if (!FEATURE_FLAGS.rpoPolicyEnabled && policy.type === 'kRPO') {
                return false;
              }

              policyCache[policy.id] =
                PolicyServiceFormatter.transformPolicy(policy);

              return cUtils.simpleCopy(policyCache[policy.id]);
            }
          ).filter(identity);

          if(get(params, '_includeTenantInfo')) {
            return TenantService.resolveTenantDetails(policies, 'tenantIds');
          }
          return policies;
        }
      );
    }

    /**
     * call V2 API to get policies
     *
     * @method   getNGPolicies
     * @param    {object}   [params={}]   The parameters
     * @param    {object}   [headers]     The request headers
     * @return   {object}   promise to resolve request for policies. resolves
     *                      with array of policies on success
     */
    function getNGPolicies(params, headers) {
      params = assign({
        includeTenants: true,
        excludeLinkedPolicies: false,
      }, params || {});

      return $http({
        method: 'get',
        url: API.publicV2('data-protect/policies'),
        params: params,
        headers: headers,
      }).then(function getPoliciesSuccess(response) {
        return get(response, 'data.policies');
      });
    }

    /**
     * Adds a protection object to RPO Policy in cluster provided.
     *
     * @method   addProtectionObjectInCluster
     * @param    {object}   data        The data
     * @param    {string}   clusterId   The cluster identifier
     * @return   {object}   promise to resolve request after adding object
     */
    function addProtectionObjectInCluster(data, clusterId) {
      return addProtectionObject(data, RemoteClusterService.getApiProxyHeader(clusterId));
    }

    /**
     * API method to add a protection object to RPO Policy
     *
     * @method   addProtectionObject
     * @param    {object}   data      The data
     * @param    {object}   headers   The headers
     * @return   {object}   promise to resolve request after adding object
     */
    function addProtectionObject(data, headers) {
      return $http({
        method: 'post',
        url: API.public('protectionObjects'),
        data: data,
        headers: headers,
      }).then(
        function addProtectionObjectSuccess(response) {
          return response.data;
        }
      );
    }

    /**
     * returns a promise to resolve a request for a id based hashmap of
     * policies.
     *
     * @method   getPoliciesHashmap
     * @param    {Object}   params   optional params that will be passed on to
     *                               API call
     * @return   {object}   Promise to resolve request. resolves with hashmap
     *                      object rejects with server response from API
     *                      request
     */
    function getPoliciesHashmap(params) {
      params = params || {};

      return getPolicies(params).then(function getPoliciesSuccess() {
        return policyCache;
      });
    }

    /**
     * Determines and returns the string value of the periodicity for provided
     * schedulKey. This string value is necessary to help distinguish between
     * periodicities that have the same kValue but are treated differently in
     * the UI. For example, a periodicity of kContinuous is stored as minutes in
     * the backend, but can be represented by minutes or hours in the UI
     *
     * @param      {object}  policy       The policy in question
     * @param      {string}  scheduleKey  represents the scheduling object key.
     *                                    schedulingPolicy,
     *                                    fullSchedulingPolicy, or
     *                                    logSchedulingPolicy
     * @return     {string}  the UI periodicity string, or undefined
     */
    function getPeriodicityString(policy, scheduleKey) {

      var schedulingPolicy;

      scheduleKey = scheduleKey || 'schedulingPolicy';
      schedulingPolicy = policy[scheduleKey];

      // exit early if there's no such schedulingPolicy
      if (!schedulingPolicy) { return; }

      switch (schedulingPolicy.periodicity) {
        case 'kContinuous':
          return schedulingPolicy.continuousSchedule.backupIntervalMins % 60 === 0 ?
            'hourly' : 'minutes';
        case 'kDaily':
          // dailySchedule.days[] refers to an exact day of the week,
          // but if none are provided, then the API assumes to set the schedule
          // for EVERY day of the week.
          return (schedulingPolicy.dailySchedule.days &&
            schedulingPolicy.dailySchedule.days.length) ?
              'weekly' : 'daily';
        case 'kMonthly':
          return 'monthly';
      }
    }


    /**
     * gets a filtered list of Policies based on provided params.
     *
     * @param {Object}   params  to filter the policies by. can include
     *                           any of the following
     *                           {
     *                               // viewBoxId will be used to determine
     *                               // if the Policies replication schedules are
     *                               // supported via appropriate View Box pairing
     *                               viewBoxId: {Integer},
     *                               envType: {Integer}
     *                           }
     *
     * @return {object}   promise to resolve request for
     */
    function getFilteredPolicies(params) {
      var deferred = $q.defer();
      var promiseArray = [];
      var policyParams = {};
      params = params || {};

      if (params.envType) {
        policyParams.envTypes = [params.envType];
      }
      promiseArray.push(getPolicies(policyParams));

      if (params.viewBoxId) {
        // we need remote cluster pairings to filter
        // out unsupported policies
        promiseArray.push(RemoteClusterService.getRemoteClusters());
      }

      $q.all(promiseArray).then(
        function promiseArraySuccess(responses) {
          var policies = [];
          var remoteClusters = [];
          var viewBoxPairingMappings = {};

          policies = responses[0];

          if (responses.length > 1 && responses[1].length) {
            // get remotes and filter promises accordingly
            remoteClusters = responses[1];
            remoteClusters.forEach(
              function loopRemotes(remote) {
                if (remote.hasOwnProperty('viewBoxPairInfo') && remote.viewBoxPairInfo.length) {
                  remote.viewBoxPairInfo.forEach(
                    function loopPairings(pairing) {
                      if (!viewBoxPairingMappings.hasOwnProperty(pairing.localViewBoxId)) {
                        viewBoxPairingMappings[pairing.localViewBoxId] = [remote.clusterId];
                      } else {
                        viewBoxPairingMappings[pairing.localViewBoxId].push(remote.clusterId);
                      }
                    }
                  );
                }
              }
            );

            // now filter the policies, removing any that have replication schedules that
            // don't support the ViewBoxId provided
            policies = policies.filter(
              function filterPolicy(policy) {

                // the meat and potatoes, check if there are snapshotTargetPolicy's,
                // if so, loop through them to determine if any are replication. If
                // a replication schedule is found and the cluster id isn't included
                // in the viewBoxPairingMappings array, its not supported and should
                // be filtered out
                if (policy.templateDetails.jobPolicy.hasOwnProperty('snapshotTargetPolicyVec') && policy.templateDetails.jobPolicy.snapshotTargetPolicyVec.length) {
                  return policy.templateDetails.jobPolicy.snapshotTargetPolicyVec.every(
                    function checkForMismatchedRemote(snapshotTargetPolicy) {
                      if (snapshotTargetPolicy.snapshotTarget.type !== 2) {
                        return true;
                      }
                      return (viewBoxPairingMappings.hasOwnProperty(params.viewBoxId) &&
                        viewBoxPairingMappings[params.viewBoxId].indexOf(snapshotTargetPolicy.snapshotTarget.replicationTarget.clusterId) > -1);
                    }
                  );
                }

                // no snapshotTargetPolicies, no problem. include it.
                return true;
              }
            );
          }

          deferred.resolve(policies);
        },
        deferred.reject
      );

      return deferred.promise;
    }

    /**
     * call API to get a specific policy
     *
     * @param  {Integer}  id       of the policy to get
     * @param  {boolean}  force    True when skipping policy cache.
     * @return {object}   promise to resolve request for specific policy.
     */
    function getPolicy(id, force) {
      if (policyCache[id] && !force) {
        // return a copy else any 2-way binding can update the cache object.
        return $q.resolve(cUtils.simpleCopy(policyCache[id]));
      }

      return $http({
        method: 'get',
        url: API.public('protectionPolicies', id),
      }).then(
        function getPolicySuccess(response) {
          policyCache[response.data.id] =
            PolicyServiceFormatter.transformPolicy(response.data);

          return cUtils.simpleCopy(policyCache[response.data.id]);
        }
      );
    }

    /**
     * call Remote API to get a specific policy
     *
     * @param {Integer}   clusterId   for remote cluster where policy resides
     * @param {Integer}   policyId    of the policy to get
     * @return {object}   promise to resolve request for specific policy.
     */
    function getRemotePolicy(clusterId, policyId) {
      if (policyCache[policyId]) {
        return $q.resolve(cUtils.simpleCopy(policyCache[policyId]));
      }

      return $http({
        method: 'get',
        url: API.public('remoteClusterApi', clusterId, 'templates', policyId),

        // Set a reasonable timeout
        // When the remote cluster is down/unreachable we want
        // to resolve the promise fairly quickly
        timeout: 4000
      }).then(
        function getRemotePolicySuccess(response) {
          policyCache[response.data.id] =
            PolicyServiceFormatter.transformPolicy(response.data);

          return policyCache[response.data.id];
        }
      );
    }

    /**
     * makes a request to the API to a delete a specific policy
     *
     * @method   deletePolicy
     * @param    {Integer}   policyId   id of the policy to be deleted
     * @return   {object}    promise to resolve delete request, resolves with
     *                       boolean value
     */
    function deletePolicy(policyId) {
      return $http({
        method: 'delete',
        url: API.public('protectionPolicies', policyId),
      }).then(
        function deletePolicySuccess(response) {
          policyCache[policyId] = undefined;

          return response.data;
        }
      );
    }

    /**
     * Initiates a modal for creating or editing a policy.
     *
     * @param      {string}  policyId  The policy identifier, if editing
     * @return     {object}  promise to resolve modal
     */
    function modifyPolicyModal(policyId) {
      return SlideModalService.newModal({
        templateUrl: 'app/protection/policies/modify/modify.html',
        controller: 'policyModifyModalController',
        size: 'xl',
        resolve: {
          policyId: function resolvePolicyIdFn() { return policyId; },
        },
      });
    }

    /**
     * Opens the edit policy modal to edit the policy and updates the same.
     *
     * @method   openEditPolicyModal
     * @param    {Object}   policy   The policy to be edited.
     */
    function openEditPolicyModal(policy) {
      modifyPolicyModal(policy.id).then(
        function policyEditSuccess(updatedInfo) {
          var newPolicy = updatedInfo.policy;

          // Update the exisiting policy.
          merge(policy, newPolicy);
        }
      );
    }

    /**
     * Fetches list of global policy templates.
     *
     * @method     getGlobalPolicies
     * @return     {Object}  Promise to resolve the API request.
     */
    function getGlobalPolicies() {
      return $http({
        method: 'get',
        url: API.mcm('globalProtectionPolicies'),
      }).then(function getGlobalPoliciesSuccess(response) {
        var policies = map(response.data, function mapPolicies(policy) {
          globalPolicyCache[policy.id] =
            PolicyServiceFormatter.transformPolicy(policy);
          return cUtils.simpleCopy(globalPolicyCache[policy.id]);
        });
        return policies;
      });
    }
  }


}(angular));
