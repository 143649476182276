import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IconConfig } from '@cohesity/shared/core';

/**
 * The base path to load helix assets from.
 */
const assetsIcnBase = '/assets/i';

/**
 * The base path to load helix icons from.
 */
const icnBase = `${assetsIcnBase}/icn-ng`;

/**
 * The base path to load old icons from.
 */
const icnBaseOld = `${assetsIcnBase}/icn`;

/**
 * These are all of the icons currently defined by iris. More icons can be added
 * here as available. Please give consideration as to whether your icon belongs
 * in Iris or Helix. Might it be used by other projects/apps? If so, put it
 * in Helix.
 */
export const icons = {
  // 'Empty' state icons
  'empty-access-management': `${icnBase}/empty/access-management.svg`,
  'empty-cluster-claim': `${icnBase}/empty/cluster-claim.svg`,
  'empty-docs': `${icnBase}/empty/docs.svg`,
  'empty-license': `${icnBase}/empty/license.svg`,
  'empty-rest-api': `${icnBase}/empty/rest-api.svg`,
  'empty-support': `${icnBase}/empty/support.svg`,
  'empty-view': `${icnBase}/empty/view.svg`,

  // Other icons
  'datacenter-resting': `${icnBase}/datacenter-resting.svg`,
  'shield-line': `${icnBase}/shield-line.svg`,
  'ethernet-up': `${icnBase}/ethernet-up.svg`,
  'ethernet-down': `${icnBase}/ethernet-down.svg`,
  'view-protected': `${icnBase}/view-protected.svg`,
  inventory: `${icnBase}/inventory.svg`,
  'activate-h': `${icnBaseOld}/activate-h.svg`,
  'audit-log': `${icnBase}/audit-log.svg`,
  'early-access': `${icnBase}/early-access.svg`,
  'union': `${icnBase}/union.svg`,
  'union-border': `${icnBase}/union-border.svg`,

  // Cluster Hardware related icons.
  'node-active': `${icnBase}/node-active.svg`,
  'node-inactive': `${icnBase}/node-inactive.svg`,
  'node-running': `${icnBase}/node-running.svg`,
  'node-ack-running': `${icnBase}/running.svg`,
  'node-info': `${icnBase}/node-info.svg`,
  'disk-active': `${icnBase}/disk-active.svg`,
  'disk-inactive': `${icnBase}/disk-warning.svg`,
  'disk-resting': `${icnBase}/disk-resting.svg`,
  'disk-running': `${icnBase}/disk-running.svg`,
  'disk-info': `${icnBase}/disk-info.svg`,
  'led-on': `${icnBase}/led-on.svg`,
  'led-off': `${icnBase}/led-off.svg`,
  'run-removal-precheck': `${icnBase}/run-removal-precheck.svg`,
  'available-capacity': `${icnBase}/available-capacity.svg`,
  'vips-selection': `${icnBase}/vips-selection.svg`,
  'register-remote-storage': `${icnBase}/register-remote-storage.svg`,
  'flashrecover-landing': `${icnBase}/flashrecover-landing.svg`,
  'success-filled': `${icnBase}/success-filled.svg`,
  'failed-filled': `${icnBase}/failed-filled.svg`,

  // Health icons
  'health-antivirus': `${icnBase}/health/antivirus.svg`,
  'health-appsInfra': `${icnBase}/health/appsInfra.svg`,
  'health-archivalRestore': `${icnBase}/health/archivalRestore.svg`,
  'health-backupRestore': `${icnBase}/health/backupRestore.svg`,
  'health-cdp': `${icnBase}/health/cdp.svg`,
  'health-chassis': `${icnBase}/health/chassis.svg`,
  'health-cluster': `${icnBase}/health/cluster.svg`,
  'health-clusterManagement': `${icnBase}/health/clusterManagement.svg`,
  'health-configuration': `${icnBase}/health/configuration.svg`,
  'health-cpu': `${icnBase}/health/cpu.svg`,
  'health-dataPath': `${icnBase}/health/dataPath.svg`,
  'health-disasterRecovery': `${icnBase}/health/disasterRecovery.svg`,
  'health-disk': `${icnBase}/health/disk.svg`,
  'health-encryption': `${icnBase}/health/encryption.svg`,
  'health-failover': `${icnBase}/health/failover.svg`,
  'health-fan': `${icnBase}/health/fan.svg`,
  'health-faultTolerance': `${icnBase}/health/faultTolerance.svg`,
  'health-firmware': `${icnBase}/health/firmware.svg`,
  'health-flashblade': `${icnBase}/health/flashblade.svg`,
  'health-genericCategory': `${icnBase}/health/genericCategory.svg`,
  'health-indexing': `${icnBase}/health/indexing.svg`,
  'health-license': `${icnBase}/health/license.svg`,
  'health-marketPlace': `${icnBase}/health/marketPlace.svg`,
  'health-memory': `${icnBase}/health/memory.svg`,
  'health-metadata': `${icnBase}/health/metadata.svg`,
  'health-networking': `${icnBase}/health/networking.svg`,
  'health-nic': `${icnBase}/health/nic.svg`,
  'health-node': `${icnBase}/health/node.svg`,
  'health-nodeHealth': `${icnBase}/health/nodeHealth.svg`,
  'health-operatingSystem': `${icnBase}/health/operatingSystem.svg`,
  'health-powerSupply': `${icnBase}/health/powerSupply.svg`,
  'health-quota': `${icnBase}/health/quota.svg`,
  'health-replication': `${icnBase}/health/replication.svg`,
  'health-security': `${icnBase}/health/security.svg`,
  'health-storageUsage': `${icnBase}/health/storageUsage.svg`,
  'health-success': `${icnBase}/health-success.svg`,
  'health-systemService': `${icnBase}/health/systemService.svg`,
  'health-temperature': `${icnBase}/health/temperature.svg`,
  'health-upgrade': `${icnBase}/health/upgrade.svg`,

  // Quorum icons
  'quorum-send-request': `${icnBase}/quorum/send-request.svg`,
  'quorum-no-sim': `${icnBase}/quorum/no-sim.svg`,
  'quorum-user-resting': `${icnBase}/quorum/user-resting.svg`,
  'quorum-operation-template': `${icnBase}/quorum/operations-template.svg`,

  // Reporting icons
  'reporting-all-objects': `${icnBase}/reporting/all-objects.svg`,
  'reporting-backup-run': `${icnBase}/reporting/backup-run.svg`,
  'reporting-cluster-connections': `${icnBase}/reporting/cluster-connections.svg`,
  'reporting-external-targets': `${icnBase}/reporting/external-targets.svg`,
  'reporting-failures': `${icnBase}/reporting/failures.svg`,
  'reporting-m365': `${icnBase}/reporting/m365.svg`,
  'reporting-object-growth': `${icnBase}/reporting/object-growth.svg`,
  'reporting-protected-objects': `${icnBase}/reporting/protected-objects.svg`,
  'reporting-protection-activity': `${icnBase}/reporting/protection-activity.svg`,
  'reporting-protection-group-summary': `${icnBase}/reporting/protection-group-summary.svg`,
  'reporting-protection-runs': `${icnBase}/reporting/protection-runs.svg`,
  'reporting-recovery': `${icnBase}/reporting/recovery.svg`,
  'reporting-sc-cluster': `${icnBase}/reporting/sc-cluster.svg`,
  'reporting-sc-group': `${icnBase}/reporting/sc-group.svg`,
  'reporting-sc-object': `${icnBase}/reporting/sc-object.svg`,
  'reporting-sc-organization': `${icnBase}/reporting/sc-organization.svg`,
  'reporting-sc-storage-domain': `${icnBase}/reporting/sc-storage-domain.svg`,
  'reporting-sc-view': `${icnBase}/reporting/sc-view.svg`,
  'reporting-service-consumption': `${icnBase}/reporting/service-consumption.svg`,
  'reporting-sla-violations': `${icnBase}/reporting/sla-violations.svg`,
  'reporting-source-growth': `${icnBase}/reporting/source-growth.svg`,
  'reporting-storage-consumption': `${icnBase}/reporting/storage-consumption.svg`,
  'reporting-system-protection': `${icnBase}/reporting/system-protection.svg`,

  // capabilities icons
  'capability-multi-cluster': `${icnBase}/capability/multi-cluster.svg`,
  'capability-single-cluster': `${icnBase}/capability/single-cluster.svg`,
  'capability-smart-licensing': `${icnBase}/capability/smart-licensing.svg`,
  'capability-support-automation': `${icnBase}/capability/support-automation.svg`,
  'capability-what-if-analysis': `${icnBase}/capability/what-if-analysis.svg`,

  // blank card bg images
  'no-data-img-systems': `${assetsIcnBase}/systems.svg`,
  'no-data-img-access': `${assetsIcnBase}/access.svg`,
  'no-data-img-backup-source-and-objects': `${assetsIcnBase}/backup-source-and-objects.svg`,
  'no-data-img-organization': `${assetsIcnBase}/no-organization.svg`,
  'no-data-img-no-activity-found': `${assetsIcnBase}/no-activity-found.svg`,
  'no-data-img-no-data-migration-jobs': `${assetsIcnBase}/no-data-migration-jobs.svg`,
  'no-data-img-external-target-cloud': `${assetsIcnBase}/blank-register-external-target-cloud.svg`,
  'no-data-img-gflag-recipes': `${assetsIcnBase}/no-gflag-recipes.svg`,
  'no-data-img-blank-file-services-dashboard': `${assetsIcnBase}/blank-file-services-dashboard.svg`,
  'no-package-available': `${assetsIcnBase}/no-package-available.svg`,
  'no-data-cloud': `${assetsIcnBase}/no-data-cloud.svg`,
  'no-data-view': `${assetsIcnBase}/no-data-view.svg`
};

/**
 * The namespace to register the icons.
 */
const iconNamespace = 'iris';

/**
 * This service registers Iris icons with the MatIconRegistry.
 * Each icon can be accessed with `iris:<iconName>`
 */
@Injectable({
  providedIn: 'root',
})
export class IconService {
  /**
   * This flag is used to make sure the icon service is only ever initialized once.
   */
  initialized = false;

  /**
   * A list of all of the icons in the helix icon set.
   */
  get allIcons(): IconConfig {
    return icons;
  }

  constructor(protected iconRegistry: MatIconRegistry, protected sanitizer: DomSanitizer) {}

  /**
   * Sanitize and register icon in a namespace.
   *
   * @param namespace Namespace for registering the icon
   * @param name Name of the icon used as shape in cog-icon
   * @param path Path of the svg file
   */
  registerIconInNamespace(namespace: string = '', name: string, path: string): void {
    this.iconRegistry.addSvgIconInNamespace(namespace, name, this.sanitizer.bypassSecurityTrustResourceUrl(path));
  }

  /**
   * Registers all of the Helix Icons with the MatIconRegistry.
   */
  registerIcons() {
    if (!this.initialized) {
      for (const [name, path] of Object.entries(this.allIcons)) {
        this.registerIconInNamespace(iconNamespace, name, path);
      }

      this.initialized = true;
    }
  }
}
