import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

// Things like API Key can be accessed by anyone, even without any privileges.
const apiViewAccess = (ctx: StateAccessContext) => ctx.PRINCIPAL_VIEW || ctx.MCM_VIEW_SUPER_ADMIN;
const apiModifyAccess = (ctx: StateAccessContext) => ctx.PRINCIPAL_VIEW || ctx.MCM_VIEW_SUPER_ADMIN;

const ssoViewAccess = (ctx: StateAccessContext) => ctx.PRINCIPAL_VIEW || ctx.MCM_VIEW_SUPER_ADMIN;

const modifyAccess = (ctx: StateAccessContext) => ctx.PRINCIPAL_MODIFY || ctx.MCM_MODIFY_SUPER_ADMIN;
const activeDirectoryViewAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.adMcmAvailability && ctx.AD_LDAP_VIEW;
const activeDirectoryModifyAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.adMcmAvailability && ctx.AD_LDAP_MODIFY;

const viewAccess = (ctx: StateAccessContext) => ctx.PRINCIPAL_VIEW || ctx.MCM_VIEW_SUPER_ADMIN;

const modifyRoleAccess = (ctx: StateAccessContext) => ctx.PRINCIPAL_MODIFY || ctx.MCM_MODIFY_SUPER_ADMIN;
const viewRoleAccess = (ctx: StateAccessContext) => ctx.PRINCIPAL_VIEW || ctx.MCM_VIEW_SUPER_ADMIN;

// 'data protect' is a cohesity service which encapsulates all clusters.
const viewAccessForHeliosOnly = (ctx: StateAccessContext) =>
  (ctx.PRINCIPAL_VIEW || ctx.MCM_VIEW_SUPER_ADMIN)
  && (!ctx.clusterInfo?._cohesityService || ctx.clusterInfo?._allClusters);
const viewAccessForHeliosOnPremOnly = (ctx: StateAccessContext) =>
  (ctx.PRINCIPAL_VIEW || ctx.MCM_VIEW_SUPER_ADMIN) && ctx.isHeliosOnPrem;
const mfaViewAccessForHelios = (ctx: StateAccessContext) =>
  ctx.MCM_MODIFY_MFA && !ctx.isHeliosOnPrem && ctx.FEATURE_FLAGS.mfaHeliosSaas && !ctx.isHeliosTenantUser;
const mfaViewAccessForHeliosOnPrem = (ctx: StateAccessContext) =>
  viewAccessForHeliosOnPremOnly(ctx) && ctx.FEATURE_FLAGS.mfaHeliosOnPrem && !ctx.isHeliosTenantUser;

const m365SelfServiceAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.dmsOffice365SelfServiceRecoveryEnabled;
const manageAccessScopes = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.heliosCustomRbacEnabled;
const manageSessionManagement = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.heliosSessionManagementEnabled;

export const HeliosAccessManagementConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'helios-access-management.**',
      url: '/admin/helios-access-management',
      loadChildren: () => import('./helios-access-management.module').then(m => m.HeliosAccessManagementModule),
    },
    {
      name: 'helios-access-management-add-user.**',
      url: '/admin/helios-access-management/users/add',
      loadChildren: () => import('./helios-access-management.module').then(m => m.HeliosAccessManagementModule),
    },
    {
      name: 'helios-edit-user.**',
      url: '/admin/helios-access-management/users/edit/{userId}',
      loadChildren: () => import('./helios-access-management.module').then(m => m.HeliosAccessManagementModule),
    },
    {
      name: 'helios-view-user.**',
      url: '/admin/helios-access-management/users/view/{userId}',
      loadChildren: () => import('./helios-access-management.module').then(m => m.HeliosAccessManagementModule),
    },
    {
      name: 'helios-access-management.active-directory.**',
      url: '^/admin/helios-access-management/active-directory',
      loadChildren: () => import('./helios-access-management.module').then(m => m.HeliosAccessManagementModule),
    },
    {
      name: 'helios-active-directory-add.**',
      url: '^/admin/helios-access-management/active-directory/create',
      loadChildren: () => import('./helios-access-management.module').then(m => m.HeliosAccessManagementModule),
    },
  ],
  allClusterMap: {
    'helios-access-management': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-access-management.users',
      globalContext: true,
    },
    'helios-access-management.users': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-access-management.users',
      globalContext: true,
    },
    'helios-access-management-add-user': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-access-management-add-user',
      globalContext: true,
    },
    'helios-edit-user': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-edit-user',
      globalContext: true,
    },
    'helios-view-user': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-view-user',
      globalContext: true,
    },
    'helios-access-management.roles': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-access-management.roles',
      globalContext: true,
    },
    'helios-add-role': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-add-role',
      globalContext: true,
    },
    'helios-view-role': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-view-role',
      globalContext: true,
    },
    'helios-edit-role': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-edit-role',
      globalContext: true,
    },
    'helios-access-management.api-keys': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-access-management.api-keys',
      globalContext: true,
    },
    'helios-access-management.tokens': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-access-management.tokens',
      globalContext: true,
    },
    'helios-add-api-key': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-add-api-key',
      globalContext: true,
    },
    'helios-view-api-key': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-view-api-key',
      globalContext: true,
    },
    'helios-access-management.sso': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-access-management.sso',
      globalContext: true,
    },
    'helios-access-management.mfa': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-access-management.mfa',
      globalContext: true,
    },
    'helios-access-management.onprem-mfa': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-access-management.onprem-mfa',
      globalContext: true,
    },
    'helios-access-management.active-directory': {
      heliosOnly: true,
      singleClusterState: 'access-management.active-directory-ng',
      allClustersState: 'helios-access-management.active-directory',
      globalContext: true,
    },
    'helios-active-directory-add': {
      heliosOnly: true,
      singleClusterState: 'active-directory-add',
      allClustersState: 'helios-active-directory-add',
      globalContext: true,
    },
    'helios-active-directory-edit': {
      heliosOnly: true,
      singleClusterState: 'active-directory-add',
      allClustersState: 'helios-active-directory-edit',
      globalContext: true,
    },
    'helios-active-directory-view': {
      heliosOnly: true,
      singleClusterState: 'active-directory-view',
      allClustersState: 'helios-active-directory-view',
      globalContext: true,
    },
    'helios-access-management.m365-self-service': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-access-management.users',
      globalContext: true,
    },
    'helios-access-management.m365-self-service.mailbox': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-access-management.users',
      globalContext: true,
    },
    'helios-access-management.m365-self-service.one-drive': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-access-management.users',
      globalContext: true,
    },
    'helios-access-management.access-scopes': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-access-management.users',
      globalContext: true,
    },
    'helios-access-management.manage-sessions': {
      heliosOnly: true,
      singleClusterState: 'access-management.user-groups',
      allClustersState: 'helios-access-management.manage-sessions',
      globalContext: true,
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'helios-access-management': viewAccess,
      'helios-access-management.users': viewAccess,
      'helios-access-management-add-user': modifyAccess,
      'helios-edit-user': modifyAccess,
      'helios-view-user': viewAccess,
      'helios-access-management.roles': viewRoleAccess,
      'helios-add-role': modifyRoleAccess,
      'helios-view-role': viewAccessForHeliosOnly,
      'helios-edit-role': modifyRoleAccess,
      'helios-access-management.api-keys': apiViewAccess,
      'helios-access-management.tokens': apiViewAccess,
      'helios-add-api-key': apiModifyAccess,
      'helios-view-api-key': apiViewAccess,
      'helios-access-management.sso': ssoViewAccess,
      'helios-access-management.mfa': mfaViewAccessForHelios,
      'helios-access-management.onprem-mfa': mfaViewAccessForHeliosOnPrem,
      'helios-access-management.active-directory': activeDirectoryViewAccess,
      'helios-active-directory-add': activeDirectoryModifyAccess,
      'helios-active-directory-edit': activeDirectoryModifyAccess,
      'helios-active-directory-view': activeDirectoryModifyAccess,
      'helios-access-management.m365-self-service': m365SelfServiceAccess,
      'helios-access-management.m365-self-service.mailbox': m365SelfServiceAccess,
      'helios-access-management.m365-self-service.one-drive': m365SelfServiceAccess,
      'helios-access-management.access-scopes': manageAccessScopes,
      'helios-access-management.manage-sessions': manageSessionManagement
    };
  },
};
