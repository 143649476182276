import { forIn } from 'lodash-es';
import { intersection } from 'lodash-es';
import { isEqual } from 'lodash-es';
import { isNaN } from 'lodash-es';
import { concat } from 'lodash-es';
import { assign } from 'lodash-es';
// Module: Global Search Controller

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.globalSearch',
      [
        'C.sources',
        'C.pubRestoreService',
        'C.pubJobService',
        'C.pubJobServiceFormatter',
        'C.jobModify',
      ]
    )
    .config(globalSearchConfigFn)
    .controller('globalSearchController', globalSearchControllerFn);

  function globalSearchConfigFn($stateProvider) {
    var _sharedParamConfig = {
      dynamic: true,
      value: undefined,
      squash: true,
    };

    $stateProvider
      .state('search', {
        url: '/search?' + [
          '{searchString}',
          '{environments}',
          '{physicalServerHostTypes}',
          '{lastProtectionJobRunStatus}',
          '{protectionStatus}',
        ].join('&'),
        params: {
          searchString: _sharedParamConfig,
          environments: _sharedParamConfig,
          physicalServerHostTypes: _sharedParamConfig,
          lastProtectionJobRunStatus: _sharedParamConfig,
          protectionStatus: _sharedParamConfig,
        },
        help: 'helios_global_search',
        title: 'Search',
        canAccess: 'OBJECT_SEARCH && ' +
          '(FEATURE_FLAGS.globalSearchEnabled || basicClusterInfo.mcmMode)',
        allClustersSupport: true,
        templateUrl: 'app/global-search/global-search.html',
        controller: 'globalSearchController as $ctrl',
        onExit: function handleStateExit(GlobalSearchService) {
          // Clear the global search input text input when navigated out of the
          // global search list page
          GlobalSearchService.setCurrentQuery('');
        },
      });
  }

  /**
   * Controller function
   *
   * @method   globalSearchControllerFn
   */
  function globalSearchControllerFn(_, $q, $scope, $timeout, $state,
    $transition$, $rootScope, cUtils, GlobalSearchService, RemoteClusterService,
    HeliosService, moment, ENV_GROUPS, ENUM_ENV_TYPE, ENUM_HOST_TYPE,
    FEATURE_FLAGS) {

    var $ctrl = this;

    assign($ctrl, {
      // Life cycle hooks
      $onInit: $onInit,

      // Controller methods
      selectResult: selectResult,
      shouldShowHypervisorType: shouldShowHypervisorType,
      shouldShowParentSource: shouldShowParentSource,

      // Controller properties
      remoteClustersHash: {},

      // Track if the hash is loaded and show global-search-protect only if
      // hash is built
      remoteClustersHashLoaded: false,
    });

    /**
     * Indicates if the parent source should be displayed for the provided
     * search result.
     *
     * @method shouldShowParentSource
     * @param  {object}     selectedResult   the search result to evaluate
     * @return {boolean}    True if parent source should be displayed, false
     *                      otherwise.
     */
    function shouldShowParentSource(selectedResult) {
      return !ENV_GROUPS.cohesityGroups.includes(
        selectedResult.source.environment) &&
        !!selectedResult.parentSource.name &&
        selectedResult.parentSource.name !== 'NoEntityName';
    }

    /**
     * Indicates if a hypervisor type should be displayed for the provided
     * search result.
     *
     * @method shouldShowParentSource
     * @param  {object}     selectedResult   the search result to evaluate
     * @return {boolean}    True if hypervisor type should be displayed, false
     *                      otherwise.
     */
    function shouldShowHypervisorType(selectedResult) {
      return ENV_GROUPS.hypervisor.includes(
          selectedResult.parentSource.environment) &&
        selectedResult.parentSource.name !== 'NoEntityName';
    }

    /**
     * Initialization function
     *
     * @method   $onInit
     */
    function $onInit() {
      assign($ctrl, {
        results: GlobalSearchService.getCurrentResults(),
        selectedResult: GlobalSearchService.getSelectedResult(),
        updateParams: updateParams,
      });

      var promises = {
        // Passing the true flag will tell the service function to bypass
        // appending the clusterId header in the event that altClusterSelector
        // is currently on a remote cluster. Global search is only interested in
        // having a list of remote clusters from a 'local' perspective... said
        // another way: if a specific cluster context is set in
        // AltClusterSelector we still to query for remote clusters "locally"
        // and not get a list of remote clusters via proxied API call to the
        // selected cluster.
        // NOTE: Using "all clusters" so local gets added to the list for
        // simpler implementation of on-prem global search.
        remoteClusterHash: RemoteClusterService.getAllClustersHash(true),
        cacheDbJobs: GlobalSearchService.cacheDbJobs(),
      };

      if ($rootScope.basicClusterInfo.mcmMode) {
        // For Helios, we only want to allow selecting clusters which are
        // currently connected.
        promises.clusterStatuses = HeliosService.getClustersStatus();
      }

      $q.all(promises).then(function processResponses(responses) {
        $ctrl.remoteClustersHash = responses.remoteClusterHash;

        // Hydrate remoteClustersHash with cluster connection status information
        (responses.clusterStatuses || []).forEach(
          function processClusterStatus(clusterStatus) {
            var clusterItem = $ctrl.remoteClustersHash[clusterStatus.clusterId];

            if (clusterItem) {
              clusterItem.connectedToCluster = clusterStatus.connectedToCluster;
            }
          }
        );

        $ctrl.remoteClustersHashLoaded = true;
      }, angular.noop);


      if ($transition$.params().searchString) {
        GlobalSearchService.setCurrentQuery($transition$.params().searchString);
      }

      $ctrl.filters = _initFilters();
      updateParams();
    }

    /**
     * Indicates if a particular environment type should be a filter option.
     *
     * @method   _isFilterable
     * @param    {string}    kEnv   The environment kValue to evalutate
     * @return   {boolean}   True if it should be a filter, false otherwise.
     */
    function _isFilterable(kEnv) {
      return !ENV_GROUPS.notGlobalSearchFilterable.includes(kEnv) &&
        checkFeatureFlags(kEnv);
    }

    /**
     * Check the feature flag for the filters environments which are recently
     * introduced and the feature flags are turned off by default. In such cases
     * the environment filters will be removed from the global search.
     *
     * @method   checkFeatureFlags
     * @param    {string}    environment   The environment type
     * @return   {boolean}   True if the feature is turned on, False otherwise
     */
    function checkFeatureFlags(environment) {
      switch(environment) {
        case 'kGPFS':
          return FEATURE_FLAGS.gpfsSourceEnabled;
        case 'kElastifile':
          return FEATURE_FLAGS.elastifileSourceEnabled;
        case 'kFlashBlade':
          return FEATURE_FLAGS.flashBladeSourceEnabled;
        default:
          return true;
      }
    }

    /**
     * Provides an object configuration for search filters.
     *
     * @method   _initFilters
     * @return   {object}   The filters.
     */
    function _initFilters() {
      var params = $transition$.params();

      // Leveraging concat() to create a new array, as the params can be a
      // single string, an array of strings, or undefined.
      var envParams = concat([], params.environments || []);
      var statusParams = concat([], params.protectionStatus || []);
      var lastRunParams = concat([], params.lastProtectionJobRunStatus || []);
      var hypervisorEnvs =
        cUtils.onlyStrings(ENV_GROUPS.hypervisor).filter(_isFilterable);
      var storageVolumeEnvs =
        cUtils.onlyStrings(ENV_GROUPS.storageVolumes).filter(_isFilterable);
      var databaseEnvs =
        cUtils.onlyStrings(ENV_GROUPS.databaseSources).filter(_isFilterable);
      var physicalHostTypes = cUtils.onlyStrings(
        Object.keys(ENUM_HOST_TYPE).filter(function isKValue(key) {
          return isNaN(+key);
        })
      ).filter(_isFilterable);
      var selectedHypervisorEnvs = intersection(hypervisorEnvs, envParams);
      var selectedStorageVolumeEnvs =
        intersection(storageVolumeEnvs, envParams);
      var selectedDatabaseEnvs = intersection(databaseEnvs, envParams);
      var selectedHostTypes =
        concat([], (params.physicalServerHostTypes || []));

      var filters = {
        environments: {
          header: 'type',
          options: {
            virtual: {
              label: 'virtualInfrastructure',
              value: !!selectedHypervisorEnvs.length,
              subOptions: {
                header: 'virtualInfrastructureType',
                labelLookup: ENUM_ENV_TYPE,
                options: {
                  // Note: populated based on ENV_GROUPS.hypervisor below.
                },
              },
            },
            storageVolume: {
              label: 'storageVolume',
              value: !!selectedStorageVolumeEnvs.length,
              subOptions: {
                header: 'storageVolumeType',
                labelLookup: ENUM_ENV_TYPE,
                options: {
                  // Note: populated based on ENV_GROUPS.storageVolume below.
                },
              },
            },
            database: {
              label: 'database',
              value: !!selectedDatabaseEnvs.length,
              subOptions: {
                header: 'databaseType',
                labelLookup: ENUM_ENV_TYPE,
                options: {
                  // Note: populated based on ENV_GROUPS.databaseSources below.
                },
              },
            },
            kPhysical: {
              label: 'physicalServer',
              value: !!selectedHostTypes.length,

              // queryParamOverride property can be leveraged to include this
              // option (and sub-options) in the filter set for display purposes
              //  but actually push its values to a separate query param.
              queryParamOverride: 'physicalServerHostTypes',
              subOptions: {
                header: 'physicalHostType',
                labelLookup: ENUM_HOST_TYPE,
                options: {
                  //populated based on ENUM_HOST_TYPE below.
                }
              },
            },
            kView: {
              label: 'cohesityView',
              value: envParams.includes('kView'),
            },
          },
        },
        protectionStatus: {
          header: 'status',
          options: {
            protected: {
              label: 'protected',
              value: statusParams.includes('protected'),
            },
            unprotected: {
              label: 'unprotected',
              value: statusParams.includes('unprotected'),
            },
          }
        },
        lastProtectionJobRunStatus: {
          header: 'lastRun',
          options: {
            kSuccess: {
              label: 'success',
              value: lastRunParams.includes('kSuccess'),
            },
            kFailure: {
              label: 'error',
              value: lastRunParams.includes('kFailure'),
            },
          }
        },
      };

      hypervisorEnvs.forEach(
        function addFn(env) {
          filters.environments.options.virtual.subOptions.options[env] = {
            label: env,

            // NOTE: Only mark this particular sub item as selected if its
            // included in the selected params and the selected params count
            // doesn't match the total number of options. This is because all
            // options are considered selected if just the parent is selected,
            // and this mechanism will correctly restore this behavior.
            value: envParams.includes(env) &&
              selectedHypervisorEnvs.length < hypervisorEnvs.length,
          };
        }
      );

      storageVolumeEnvs.forEach(
        function addFn(env) {
          filters.environments.options.storageVolume.subOptions.options[env] = {
            label: env,

            // See note above in hypervisors that explains this evaluation.
            value: envParams.includes(env) &&
              selectedStorageVolumeEnvs.length < storageVolumeEnvs.length,
          };
        }
      );

      databaseEnvs.forEach(
        function addFn(env) {
          filters.environments.options.database.subOptions.options[env] = {
            label: env,

            // See note above in hypervisors that explains this evaluation.
            value: envParams.includes(env) &&
              selectedDatabaseEnvs.length < databaseEnvs.length,
          };
        }
      );

      // Populate Physical Host Type sub options.
      physicalHostTypes.forEach(function addFn(hostType) {
        filters.environments.options.kPhysical.subOptions.options[hostType] = {
          label: hostType,

          // See note above in hypervisors that explains this evaluation.
          value: selectedHostTypes.includes(hostType) &&
            selectedHostTypes.length < physicalHostTypes.length,
        };
      });

      return filters;
    }

    /**
     * Handles changes to filters, converting them to state params
     * TODO: Consider spinning up a globalSearchFilters component to house all
     * this logic.
     *
     * @method   updateParams
     */
    function updateParams() {
      var params = _getSearchParams();
      $ctrl.params = params;

      // Don't inherit the params as the params here can be removed when the
      // filters are unselected. With the default inherit true behavior, the
      // filters in params never get removed - they only get added.
      $state.go('search', params, {inherit: false});
    }

    /**
     * Sets provided result as the selected result.
     *
     * @method   selectResult
     * @param    {object}              result         The result
     * @param    {boolean|undefined}   forceTimeout   True to force a timeout to
     *                                                select the result
     */
    function selectResult(result, forceTimeout) {
      // Eliminate timeout if there isn't a result selected already.
      var timeoutMsecs = $ctrl.selectedResult || forceTimeout ? 301 : 0;

      // If user clicked on same object, there's nothing to do.
      if ($ctrl.selectedResult && isEqual(result, $ctrl.selectedResult)) {
        return;
      }

      $ctrl.selectedResult = undefined;

      // Also inform the service of the selection, as this will allow the state
      // to be restored when returning to the search results from elsewhere.
      GlobalSearchService.setSelectedResult(result);

      // Update the actual selection of the item in a timeout for animation
      // purposes and to clear one way bindings.
      $timeout(function selectIt() {
        $ctrl.selectedResult = result;
      }, timeoutMsecs);
    }

    /**
     * Builds and returns the API query params object based on filters object.
     *
     * @method   _getSearchParams
     */
    function _getSearchParams() {
      var params = {
        searchString: $transition$.params().searchString,
      };

      // Iterate through the filters (objects and subobjects) and build the
      // params based on selections.
      forIn($ctrl.filters, function iterateFilterObj(filter, filterName) {
        forIn(filter.options, function iterateOptions(option, optionName) {
          var allSubOptions = [];
          var selectedSubOptions = [];
          var paramsKey = option.queryParamOverride || filterName;

          // If the checkbox wasn't selected, there's nothing to do here. Skip
          // this particular filter.
          if (!option.value) {
            return;
          }

          // From here on, something will get added to the search params.

          // Itterate through supOptions (if any) and populate allSubOptions[]
          // and selectedSubOptions[].
          forIn(option.subOptions ? option.subOptions.options : [],
            function iterateSubOpts(subOpt, subName) {
              allSubOptions.push(subName);
              if (subOpt.value) {
                selectedSubOptions.push(subName);
              }
            }
          );

          //If there are no sub options, the option itself will get added.
          if (!option.subOptions) {
            params[paramsKey] = (params[paramsKey] || []).concat(optionName);
          } else {
          // If there are subOptions selected only those that are explicitly
          // selected will be added. Otherwise, all of the subOptions will be
          // added as they are implied by the selection of the parent option.
          params[paramsKey] = (params[paramsKey] || []).concat(
            selectedSubOptions.length ? selectedSubOptions : allSubOptions);
          }
        });
      });

      return params;
    }

    // Whenever the search results are changed via getResults in
    // global-search-input.js, select the very first item in the array as the
    // selected result in the global search page.
    $scope.$watch('$ctrl.results[0]', function selectFirstItem(newValue) {
      if (newValue) {
        // Empty results array will have the first result as undefined, don't
        // select that.
        selectResult(newValue, true);
      }
    });

    // Whenever the activeDate in calendar is changed, that is month view set to
    // a new month, load all snapshots from that particular month.
    $scope.$on('uibDaypicker.activeDateChanged',
      function newActiveDate(_, newDate) {
        var momentDate = moment(newDate);

        GlobalSearchService.getSnapshotsForTimeRange(
          momentDate.startOf('month').toUsecDate(),
          momentDate.endOf('month').toUsecDate()
        )
      }
    );
  }

})(angular);
