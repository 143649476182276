import { Injectable } from '@angular/core';
import { flagEnabled, getUserTenantId, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { Transition } from '@uirouter/core';
import { filter, map } from 'rxjs/operators';
import { getHeliosMfaStatus } from 'src/app/util';

import { AppStateService, DmsService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

@Injectable({
  providedIn: 'root'
})
export class DmsSetupGuard implements StateGuard {

  /**
   * The guard priority for the State.
   */
  guardPriority = GuardPriority.ImpersonationOrSwitch;

  /**
   * List of states that are configurable for the user
   * if the user has not setup any regions.
   */
  readonly dmsInitialAccessStates = [
    'app-blank',
    'app-pillars',
    'app-pillars-home',
    'dms.regions',
    'dms.setup',
    'dmsOnboarding',
    'dmsOnboarding.finish',
    'dmsOnboarding.regions',
    'dmsOnboarding.saasConnections',
    'dmsOnboarding.sources',
    'dmsOnboarding.users',
    'dmsOnboarding.welcome',
    'feature-flags',
    'helios-access-management',
    'helios-access-management-add-user',
    'helios-access-management.api-keys',
    'helios-access-management.mfa',
    'helios-access-management.manage-sessions',
    'helios-access-management.roles',
    'helios-access-management.access-scopes',
    'helios-access-management.tokens',
    'helios-access-management.sso',
    'helios-access-management.users',
    'helios-add-api-key',
    'helios-add-role',
    'helios-edit-role',
    'helios-edit-user',
    'helios-view-api-key',
    'helios-view-role',
    'helios-view-user',
  ];

  /**
   * constructor
   */
  constructor(
    private dmsService: DmsService,
    private irisCtx: IrisContextService,
    private appStateService: AppStateService,
  ) {}

  /**
   * OnStart of UI Router State Transition
   */
  onStart(transition: Transition): GuardResult {
    if (!isDmsScope(this.irisCtx.irisContext)) {
      return true;
    }

    // If this is a DMaaS user, then make sure the service is initialized.
    // Based on the right context, we will run the setup guard.
    this.dmsService.getUserTenantRegions({ tenantId: getUserTenantId(this.irisCtx.irisContext) });

    if (this.appStateService.selectedScope?._serviceType !== 'dms') {
      return true;
    }

    if (this.dmsInitialAccessStates.includes(transition.to().name)) {
      return true;
    }

    if (getHeliosMfaStatus(this.irisCtx.irisContext) === 'Unknown') {
      return true;
    }

    // 1. If setup is not done => goto dashboards.dms
    // 2. If setup is done => continue transition.
    return this.dmsService.isSetupDone$.pipe(
      filter(result => !!result),
      map(status => {
        if (status === 'NotDone') {
          if (flagEnabled(this.irisCtx.irisContext, 'dmsOnboardingEnabled')) {
            // Show the onboarding wizard only when no regions are set up.
            return transition.router.stateService.target('dmsOnboarding.welcome');
          }

          return transition.router.stateService.target('dms.setup');
        } else if (status === 'PartiallyDone') {
          return transition.router.stateService.target('dms.regions');
        }
        return true;
      }),
    );
  }
}
