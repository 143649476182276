<ng-container *ngIf="!isLocked && !selfManagedMenu">
  <cog-quick-filter-button *ngIf="hasMenu"
    #menuTrigger="matMenuTrigger"
    (click)="clickHandler($event)"
    (clearClicked)="clearTags()"
    (menuClosed)="menuClose.emit()"
    [noClear]="noClear"
    [isSelected]="isSelected"
    [formStyle]="formStyle"
    [matMenuTriggerFor]="matMenu"
    [isMenuOpen]="menuTrigger.menuOpen"
    [ariaLabel]="ariaLabel"
    [label]="getLabel()"
    [matTooltip]="getLabel()">
  </cog-quick-filter-button>
  <!-- supports showing filter chips without the quick filter menu -->
  <cog-quick-filter-button *ngIf="!hasMenu"
    (click)="clickHandler($event)"
    (clearClicked)="clearTags()"
    [noClear]="noClear"
    [isSelected]="isSelected"
    [formStyle]="formStyle"
    [ariaLabel]="ariaLabel"
    [label]="getLabel()"
    [matTooltip]="getLabel()">
  </cog-quick-filter-button>
</ng-container>
<cog-quick-filter-button *ngIf="selfManagedMenu"
  (clearClicked)="clearTags()"
  (menuClosed)="menuClose.emit()"
  [isSelected]="isSelected"
  [formStyle]="formStyle"
  [ariaLabel]="ariaLabel"
  [label]="getLabel()"
  [isMenuOpen]="isMenuOpen"
  [selfManagedMenuPanelId]="matMenuTriggerFor?.panelId"
  [noClear]="noClear">
</cog-quick-filter-button>
<cog-quick-filter-button *ngIf="isLocked"
  [isSelected]="isSelected"
  [ariaLabel]="ariaLabel"
  [label]="getLabel()"
  [locked]="isLocked"
  [formStyle]="formStyle"
  [noClear]="true"
  [matTooltip]="lockedMessage">
</cog-quick-filter-button>
<mat-menu #matMenu [xPosition]="xPosition" class="cog-quick-filters-menu">
  <form (click)="formClick($event)" (keydown)="formKeyDown($event)">
    <div class="filter-container">
      <div *ngIf="note" class="menu-note">{{note}}</div>
      <ng-content></ng-content>
    </div>
    <div *ngIf="!hideButtons" class="cog-quick-filter-buttons-container">
      <button cogDataId="apply-filters-button"
        *ngIf="!hideApplyButton"
        [inheritDataId]="true"
        [trackingEvent]="{properties: {name: label}}"
        [disabled]="disableApplyButton?.disable"
        mat-flat-button
        color="primary"
        (click)="applyFilters()">
        {{ intl.dataFilters.apply }}
      </button>
      <button cogDataId="clear-filters-button"
        [attr.aria-label]="intl.dataFilters.clearFilters"
        *ngIf="!noClear"
        [inheritDataId]="true"
        [trackingEvent]="{properties: {name: label}}"
        mat-button
        color="primary"
        (click)="clearFilters()">
        {{ intl.dataFilters.clearFilters }}
      </button>
      <button
        [cogDataId]="'custom-button-' + disableApplyButton?.customButtonLabel"
        *ngIf="disableApplyButton?.customButtonLabel"
        [inheritDataId]="true"
        mat-flat-button
        color="primary"
        (click)="customButtonClicked.emit()">
        {{ disableApplyButton?.customButtonLabel }}
      </button>
    </div>
  </form>
</mat-menu>
