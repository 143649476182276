import { indexOf } from 'lodash-es';
import { flatten } from 'lodash-es';
import { omit } from 'lodash-es';
import { each } from 'lodash-es';
import { isArray } from 'lodash-es';
import { filter } from 'lodash-es';
import { find } from 'lodash-es';
import { set } from 'lodash-es';
import { clone } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Controllers: Policy Create/Edit

;(function(angular) {
  'use strict';

  var defaultFlowSettings = {
    inNewMode: true,
    inEditMode: false,
    inModal: false,
    hasSchedule: {
      fullSchedulingPolicy: false,
      logSchedulingPolicy: false,
      systemSchedulingPolicy: false,
    },
    uiPeriodicity: {
      incrementalSchedulingPolicy: undefined,
      fullSchedulingPolicy: undefined,
      logSchedulingPolicy: undefined,
      systemSchedulingPolicy: undefined,
    },
    editRetrySettings: false,
    editPolicyId: undefined,
    submitting: false,
    isGlobalPolicy: false,
  };

  // two distinct controllers, that pass through to sharedControllerFn.
  angular.module('C.policies')
    .controller('policyModifyController', policyModifyControllerFn)
    .controller('policyModifyModalController', policyModifyModalControllerFn);

  /**
   * controller function for state based access to the create/edit policy flows.
   */
  function policyModifyControllerFn($scope, $state, $q, cModal, $translate,
    evalAJAX, PolicyService, PubSourceService, JobRunsService,
    StateManagementService,  cMessage, RemoteClusterService, DAY_KVAL,
    FEATURE_FLAGS, cUtils, cFocus, ENV_GROUPS, ENV_TYPE_CONVERSION) {

    $scope.flowSettings = angular.merge({}, defaultFlowSettings, {
      inEditMode: !!$state.params.policyId,
      inNewMode: !$state.params.policyId,
      editPolicyId: $state.params.policyId,
      isGlobalPolicy: $state.params.isGlobalPolicy
    });

    sharedControllerFn.apply(this, arguments);
  }

  /**
   * controller function for modal based (slideModalService) access to the
   * create policy flows. recieves $uibModalIstance in addition to other
   * shared injections.
   */
  function policyModifyModalControllerFn($scope, $state, $q, cModal, $translate,
    evalAJAX, PolicyService, PubSourceService, JobRunsService,
    StateManagementService,  cMessage, RemoteClusterService, DAY_KVAL,
    FEATURE_FLAGS, cUtils, cFocus, ENV_GROUPS, ENV_TYPE_CONVERSION,
    $uibModalInstance, policyId) {

    $scope.flowSettings = angular.merge({}, defaultFlowSettings, {
      inEditMode: angular.isDefined(policyId),
      inNewMode: angular.isUndefined(policyId),
      inModal: true,
      editPolicyId: policyId,
    });

    sharedControllerFn.apply(this, arguments);
  }

  /**
   * shared controller function that provides common functionality for
   * standard state based controller access and modal based (slideModalService)
   * access
   */
  function sharedControllerFn($scope, $state, $q, cModal, $translate, evalAJAX,
    PolicyService, PubSourceService, JobRunsService,
    StateManagementService, cMessage, RemoteClusterService, DAY_KVAL,
    FEATURE_FLAGS, cUtils, cFocus, ENV_GROUPS, ENV_TYPE_CONVERSION,
    $uibModalInstance) {

    var now = new Date();

    // doesLoadedPolicyRequireStartTimes holds the editing policy _requireStartTimes
    // state and undefined while creating or cloning the policy.
    var doesLoadedPolicyRequireStartTimes;

    // if this flow is instantiated as a modal, this var will hold
    // the parent state's help id so we can restore it when the
    // modal is closed
    var parentHelpId;

    // to be merged with $scope.policy.incrementalSchedulingPolicy so as not to
    // destroy any custom settings for backup attempts, interval, etc...
    // TODO: is the above comment still relevant/accurate? These things have
    // been spunoff
    var defaultRepeatingBackupPolicy = {
      periodicity: 'kDaily',
      dailySchedule: {
        days: [],
      },
    };

    var defaultDaysToKeep = 14;

    /** @type {Object} the default policy settings for new policies */
    var defaultPolicyConfig = {
      name: '',
      incrementalSchedulingPolicy: angular.copy(defaultRepeatingBackupPolicy),
      daysToKeep: defaultDaysToKeep,
      retries: 3,
      retryIntervalMins: 30,
      blackoutPeriods: [],
      extendedRetentionPolicies: [],
      snapshotReplicationCopyPolicies: [],
      snapshotArchivalCopyPolicies: [],
      cloudDeployPolicies: [],
    };

    /**
     * default settings per periodicity string,
     * applied to policy when switching between periodicities
     *
     * @type {Object}
     */
    var periodicityStringPolicyDefaults = {
      minutes: {
        periodicity: 'kContinuous',
        continuousSchedule: {
          backupIntervalMins: 10,
        },
      },
      hourly: {
        periodicity: 'kContinuous',
        continuousSchedule: {
          backupIntervalMins: 60,
        },
      },
      daily: {
        periodicity: 'kDaily',
        dailySchedule: {
          days: [],
        },
      },
      weekly: {
        periodicity: 'kDaily',
        dailySchedule: {
          days: [DAY_KVAL[now.getDay()]],
        },
      },
      monthly: {
        periodicity: 'kMonthly',
        monthlySchedule: {
          dayCount: 'kFirst',
          day: 'kSunday',
        },
      }
    };

    var defaultSchedulingPolicies = {
      incrementalSchedulingPolicy: angular.copy(defaultRepeatingBackupPolicy),
      fullSchedulingPolicy: angular.copy(defaultRepeatingBackupPolicy),
      logSchedulingPolicy: {
        periodicity: 'kContinuous',
        continuousSchedule: {
          backupIntervalMins: 60,
        },
      },
      systemSchedulingPolicy: {
        periodicity: 'kDaily',
        dailySchedule: {
          days: [DAY_KVAL[now.getDay()]],
        },
      },
    };

    var defaultBlackoutPeriod = {
      days: [DAY_KVAL[now.getDay()]],
      startTime: {
        hour: 0,
        minute: 0,
      },
      endTime: {
        hour: 0,
        minute: 30,
      },
    };

    var defaultReplicationCopyPolicy = {
      copyPartial: true,
      daysToKeep: 90,
      multiplier: 1,
      periodicity: 'kEvery',
      target: {
        clusterId: undefined,
        clusterName: undefined,
      },
      cloudTarget: undefined,
      _replicateTo: 'remoteCluster',
    };

    var defaultArchivalCopyPolicy = {
      copyPartial: true,
      daysToKeep: 90,
      multiplier: 1,
      periodicity: 'kDay',
      target: {
        vaultId: undefined,
        vaultName: undefined,
        vaultType: undefined,
      },
    };

    var defaultCloudDeployPolicy = {
      copyPartial: true,
      daysToKeep: 90,
      multiplier: 1,
      periodicity: 'kEvery',
      target: {
        id: undefined,
        name: undefined,
        type: undefined,
      },
    };

    /**
     * schedule periodicity name mapping with its weight.
     *
     * Scheduling Policy      Allowed Retention, Replication & Archival policy.
     * minutes, hourly        minutes, hourly, daily, weekly, monthly, yearly.
     * daily                  daily, weekly, monthly, yearly.
     * weekly                 weekly, monthly, yearly.
     * monthly                monthly, yearly.
     * "after every run" is allowed for all
     *
     * @example
     * <schedule_periodicity_name>: <weight>
     *
     * @type       {object}
     */
    var schedulePeriodicityWeightMap = {
      minutes: 1,
      hourly: 1,
      daily: 2,
      weekly: 3,
      monthly: 4,
    };

    /**
     * all possible Granularity Periodicities
     *
     * @property   {string}     kValue    kValue mapping key.
     * @property   {number}     weight    weight value.
     * @property   {string}     nameKey   ui.json key.
     *
     * @type       {object[]}   allGranularityPeriodicities
     */
    var allGranularityPeriodicities = [
      {
        kValue: 'kEvery',
        weight: 10,
        nameKey: 'granularity.kEvery',
      },
      {
        kValue: 'kHour',
        weight: 1,
        nameKey: 'granularity.kHour',
      },
      {
        kValue: 'kDay',
        weight: 2,
        nameKey: 'granularity.kDay',
      },
      {
        kValue: 'kWeek',
        weight: 3,
        nameKey: 'granularity.kWeek',
      },
      {
        kValue: 'kMonth',
        weight: 4,
        nameKey: 'granularity.kMonth',
      },
      {
        kValue: 'kYear',
        weight: 5,
        nameKey: 'granularity.kYear',
      },
    ];

    $scope.scheduleKeys = PolicyService.getScheduleKeys();

    /**
     * policy object to be modified for creation or update
     *
     * @type {Object}
     */
    $scope.policy = {};

    /**
     * if editing an existing policy, this array will be populated
     * with jobs using the policy
     * @type {Array}
     */
    $scope.policyJobs = [];

    /**
     * for tracking and persisting job settings when editing the policy
     * @type {Object}
     */
    $scope.policyJobSettings = {};

    $scope.dayCountsInMonth = [
      'kFirst',
      'kSecond',
      'kThird',
      'kFourth',
      'kLast',
    ];

    $scope.hasFullScheduleOptions = [
      {
        textKey: 'incrementalOnly',
        value: false,
      },
      {
        textKey: 'incrementalWithFullEvery',
        value: true,
      },
    ];

    // Cloud Spin can be full/incremental
    $scope.cloudDeployTypes = [
      {
        textKey: 'incremental',
        value: true,
      },
      {
        textKey: 'full',
        value: false,
      },
    ];

    // Backup Snapshots can be replicated to the following
    $scope.replicationOptions = ['remoteCluster'];

    $scope.ENV_TYPE_CONVERSION = ENV_TYPE_CONVERSION;

    $scope.cloudDeploySourceLoading = [];
    $scope.cloudDeploySourceIds =
      cUtils.onlyNumbers(ENV_GROUPS.cloudDeploySources);
    $scope.cloudDeploySources =
      cUtils.onlyStrings(ENV_GROUPS.cloudDeploySources);

    /**
     * injected into a given job policy's backupPolicy
     * when custom retry settings is toggled on
     * @type {Object}
     */
    $scope.defaultCustomRetrySettings = {
      numRetries: 3,
      retryDelayMins: 30,
    };

    $scope.periodicities = [
      {
        kValue: 'kContinuous',
        uiValue: 'minutes',
        nameKey: 'periodicityOptions.mins',
      },
      {
        kValue: 'kContinuous',
        uiValue: 'hourly',
        nameKey: 'periodicityOptions.hours',
      },
      {
        kValue: 'kDaily',
        uiValue: 'daily',
        nameKey: 'periodicityOptions.day',
      },
      {
        kValue: 'kDaily',
        uiValue: 'weekly',
        nameKey: 'periodicityOptions.week',
      },
      {
        kValue: 'kMonthly',
        uiValue: 'monthly',
        nameKey: 'periodicityOptions.month',
      },
    ];

    // Make a copy for fullSchedulingPolicy options, but remove the kContinuous
    // options.
    $scope.fullPeriodicities = $scope.periodicities.slice(2);

    // Make a copy for logSchedulingPolicy options, but remove non kContinuous
    // options.
    $scope.logPeriodicities = $scope.periodicities.slice(0, 2);

    // Make a copy for systemSchedulingPolicy options, but remove non
    // kContinuous, min, hour, daily options.
    $scope.systemPeriodicities = $scope.periodicities.slice(2);

    // deepClone Granularity Periodicities
    $scope.granularityPeriodicities = cUtils.simpleCopy(
      allGranularityPeriodicities
    );

    // snapshotTargetGranularity can always be 1 (after every backup run), but
    // anything greater and the minimum is dictated by
    // $scope.policy[policyKey].backupPolicy.periodicity
    // default is set to 3 (daily) as this is the default policy periodicity
    $scope.minimumSnapshotTargetGranularity = {
      jobPolicy: 3,

      // logBackupJobPolicy always must be after every run,
      // but maintaining a value here for the sake of looping
      // without extra conditional
      logBackupJobPolicy: 1,
      fullBackupJobPolicy: 3,
    };

    // Global policy settings
    $scope.globalPolicySettings = {
      type: 'global',
      selected: '',
      templates: [],
    };

    /**
     * activation function.
     */
    function activate() {

      $scope.flowSettings.inModal = typeof $uibModalInstance === 'object';

      // Disabling this for 6.4.1. Will be enabled later
      // when backend support is available.
      // if (FEATURE_FLAGS.azureSnapshotManager) {
      //   $scope.replicationOptions.unshift('azure');
      // }

      if (FEATURE_FLAGS.awsSnapshotManager) {
        $scope.replicationOptions.unshift('aws');
      }

      if ($scope.flowSettings.inModal) {
        parentHelpId = $state.current.help;
      }

      getPolicy().then(
        function getPolicySuccess(policy) {

          $scope.policy = policy;

          if ($scope.flowSettings.inEditMode) {
            doesLoadedPolicyRequireStartTimes = policy._requireStartTimes;

            // For azure Cloud Deploy Policies, determine if temporary
            // resourecs are present
            each(policy.cloudDeployPolicies || [],
              function eachCDPolicy(cdPolicy) {
                if (cdPolicy.target.azureParams) {
                  cdPolicy._hasTempResources =
                    get(cdPolicy, 'target.azureParams.tempVmResourceGroupId');
                }
              });
          }

          setUiPeriodicityStrings();
          _setCloudReplicationTargetParams(policy);

          if ($scope.flowSettings.inNewMode) {
            $scope.policyReady = true;

            // Auto focus the Policy name field.
            cFocus('input-policy-name');

            // Exit early for new Policies.
            return;
          }

          // If the Policy has retry settings that don't match the default
          // ensure that they are visible to the user.
          $scope.flowSettings.editRetrySettings =
            policy.retries !== defaultPolicyConfig.retries ||
            policy.retryIntervalMins !== defaultPolicyConfig.retryIntervalMins;

          $scope.policyJobSettings.updateJobs = true;

          getPolicyJobs().then(
            function getPolicyJobsSuccess(policyJobs) {
              $scope.policyJobs = policyJobs;
              if ($scope.policyJobs.length) {
                // The Policy has Jobs associated with it. Capture some initial
                // settings for determining form options that should be
                // presented to the user.
                $scope.policyJobSettings.policyLoaded = {
                  // This flag to be used to determine if user needs to provide
                  // job start times when updating an existing policy and
                  // changing from a continuous schedule (no start time) to a
                  // non-continuous schedule
                  withContinuousSchedule:
                    $scope.policy.incrementalSchedulingPolicy &&
                    $scope.policy.incrementalSchedulingPolicy.periodicity ===
                      'kContinuous',
                  // this flag to be used to determine if user needs to assign
                  // full backup job run times
                  withNoFullSchedule:
                    !$scope.policy.fullSchedulingPolicy,
                };
              }

              $scope.policyReady = true;
            },
            function getPolicyJobsFail(response) {
              // If Jobs aren't retrieved successfully, do not allow user to
              // proceed as it may result in Jobs being out of sync with
              // Policy.
              // TODO: evaluate this statement. May not be true any more since
              // its no longer necessary for the frontend to apply a policy to
              // associated Jobs.

              // show message as provided from api response
              evalAJAX.errorMessage(response, { persist: true });

              // and abort flow
              $scope.endFlow();
            }
          );

        },
        function getPolicyFail(response) {
          // show message as provided from api response
          evalAJAX.errorMessage(response, { persist: true });

          // and abort flow
          $scope.endFlow();
        }
      ).finally(getRemoteClusters);
    }

    /**
     * Finds all the cloud replication targets (if any) and sets UI params for
     * them
     *
     * @method  _setCloudReplicationTargetParams
     * @param   {Object}  policy  The policy object
     */
    function _setCloudReplicationTargetParams(policy) {
      each(policy.snapshotReplicationCopyPolicies, function(copyPolicy) {
        copyPolicy._isCloudReplication = !!copyPolicy.cloudTarget;

        if (copyPolicy._isCloudReplication) {
          copyPolicy._replicateTo =
            copyPolicy.cloudTarget.type === 'kAWS' ? 'aws' : 'azure';
        } else {
          copyPolicy._replicateTo = 'remoteCluster';
        }
      });
    }

    /**
     * Sets the UI interface periodicity strings. These strings allow the UI
     * to differentiate shared kValues for periodicity. For example, kContinuous
     * can be represented as both 'minutes' and 'hourly'.
     */
    function setUiPeriodicityStrings() {
      var uiPeriodicity = $scope.flowSettings.uiPeriodicity;
      var hasSchedule = $scope.flowSettings.hasSchedule;

      $scope.scheduleKeys.forEach(function loopScheduleKeys(scheduleKey) {
        uiPeriodicity[scheduleKey] =
          PolicyService.getPeriodicityString($scope.policy, scheduleKey);
        hasSchedule[scheduleKey] = !!$scope.policy[scheduleKey];

      });
    }

    /**
     * Cancels the flow, dismissing the modal or changing $state.
     */
    $scope.cancel = function cancel() {

      if ($scope.flowSettings.inModal) {
        $state.current.help = parentHelpId;
        return $uibModalInstance.dismiss('user.cancel');
      }

      StateManagementService.goToPreviousState('policies');
    };

    /**
     * if edit mode, gets the policy from the API
     * otherwise uses the default policy settings.
     *
     * @return {object} to resolve the request for policy
     */
    function getPolicy() {

      // use $q.defer so we can either return API response or
      // default policy object depending on the circumstances
      var deferred = $q.defer();
      var copyPolicy;

      if ($scope.flowSettings.inEditMode) {
        // edit mode, need to get policy object from the API
        return PolicyService.getPolicy($scope.flowSettings.editPolicyId);
      } else {
        if ($state.params && $state.params.copyPolicy) {
          // not in edit mode, but a policy was provided to be copied.
          // build the new policy based on the provided policy
          copyPolicy = $state.params.copyPolicy;

          // prefix the name with "copy-of-" or some similiar translation
          copyPolicy.name = [
            $translate.instant('policyModify.copyOfPrefix'),
            copyPolicy.name,
          ].join('');

          deferred.resolve(copyPolicy);
        } else {
          // not in edit mode, this is a new policy and we'll build the
          // policy object based on above defined defaults
          deferred.resolve(angular.copy(defaultPolicyConfig));
        }
      }

      return deferred.promise;

    }

    /**
     * Load the protection Jobs associated with the current Policy.
     *
     * @return     {object}  Promise to resolve request for the Policy's Jobs.
     */
    function getPolicyJobs() {
      return JobRunsService.getPolicyJobs($scope.policy.id);
    }

    /**
     * calls the API to get a list of remote clusters for replication
     * TODO: make a component for this similar to Spencer's externalTargetSelect
     * component.
     */
    function getRemoteClusters() {
      $scope.remoteClusters = [];

      RemoteClusterService.getRemoteClusters().then(
        function getRemoteClustersSuccess(remoteClusters) {
          $scope.remoteClusters = remoteClusters;
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Shared getterSetter function to handle hours to minutes conversion for
     * the provided Policy schedule.
     *
     * @param      {integer}  hours        The hours (if called as a setter)
     * @param      {string}   scheduleKey  The schedule key
     * @param      {boolean}  setter       True if called as a setter, false if
     *                                     called as a getter
     * @return     {integer}  the hours representation of the stored minutes
     */
    function sharedHoursToMinutes(hours, scheduleKey, setter) {

      var contSchedule =
        $scope.policy[scheduleKey].continuousSchedule;

      if (setter) {
        contSchedule.backupIntervalMins = Number.isInteger(hours) ?
          hours * 60 : undefined;
      }

      return (contSchedule &&
        angular.isDefined(contSchedule.backupIntervalMins)) ?
        (contSchedule.backupIntervalMins / 60) : undefined;
    }

    /**
     * Getter setter for incremental hours which proxies the UI presented hours
     * value into a minutes value on the model. Returning the hours value.
     *
     * @param      {integer}  [hours]   The new hours value
     * @return     {integer}  current hours value
     */
    $scope.incrementalHoursToMinutes = function incrementalHoursToMinutes(hours) {
      return sharedHoursToMinutes(
        hours,
        'incrementalSchedulingPolicy',
        !!arguments.length
      );
    };

    /**
     * Getter setter for log hours which proxies the UI presented hours value
     * into a minutes value on the model. Returning the hours value.
     *
     * @param      {integer}  hours   The new hours value
     * @return     {integer}  current hours value
     */
    $scope.logHoursToMinutes = function logHoursToMinutes(hours) {
      return sharedHoursToMinutes(
        hours,
        'logSchedulingPolicy',
        !!arguments.length
      );
    };

    /**
     * Removes irrelevant schedules from the schedulingPolicy indicated by
     * scheduleKey. Irrelvant schedules are those that don't match the
     * schedulingPolicies current periodicity.
     *
     * @param      {string}  scheduleKey  Indicates the schedulingPolicy to
     *                                    update (schedulingPolicy,
     *                                    fullSchedulingPolicy,
     *                                    logSchedulingPolicy)
     */
    function cleanupSchedules(scheduleKey) {

      var schedulingPolicy;

      schedulingPolicy = $scope.policy[scheduleKey];

      switch (schedulingPolicy.periodicity) {
        case 'kContinuous':
          schedulingPolicy.dailySchedule = undefined;
          schedulingPolicy.monthlySchedule = undefined;
          break;
        case 'kDaily':
          schedulingPolicy.continuousSchedule = undefined;
          schedulingPolicy.monthlySchedule = undefined;

          if ($scope.flowSettings.uiPeriodicity[scheduleKey] === 'daily') {
            // Ensure "daily" doesn't retain any specific days.
            schedulingPolicy.dailySchedule.days = [];
          }
          break;
        case 'kMonthly':
          schedulingPolicy.continuousSchedule = undefined;
          schedulingPolicy.dailySchedule = undefined;
      }

    }

    /**
     * filter out invalid granularity periodicities & update the extended
     * retention, replication and archival policies accordingly.
     *
     * @method   updateGranularityPeriodicities
     */
    function updateGranularityPeriodicities() {
      var policy = $scope.policy;
      var periodicityValidity = {};
      var minimumGranularityPeriodicity = _getMinimumGranularityPeriodicity();

      // filter out invalid granularity periodicities.
      $scope.granularityPeriodicities = allGranularityPeriodicities.filter(
        function (periodicity) {
          var isValidPeriodicity = periodicity.weight >=
            schedulePeriodicityWeightMap[
              $scope.flowSettings.uiPeriodicity.incrementalSchedulingPolicy
            ];

          return periodicityValidity[periodicity.kValue] = isValidPeriodicity;
        }
      );

      // reset extended retention policies that are invalid
      policy.extendedRetentionPolicies.forEach(function eachItem(item) {
        if (!periodicityValidity[item.periodicity]) {
          item.periodicity = minimumGranularityPeriodicity;
        }
      });
      // reset replication policies that are invalid
      policy.snapshotReplicationCopyPolicies.forEach(function eachItem(item) {
        if (!periodicityValidity[item.periodicity]) {
          item.periodicity = minimumGranularityPeriodicity;
        }
      });
      // reset archival policies that are invalid
      policy.snapshotArchivalCopyPolicies.forEach(function eachItem(item) {
        if (!periodicityValidity[item.periodicity]) {
          item.periodicity = minimumGranularityPeriodicity;
        }
      });
    }

    /**
     * Returns the minimum viable granularity periodicity for use with snapshot
     * policies (extended retention, replication, archival, cloud spin).
     *
     * @method   _getMinimumGranularityPeriodicity
     * @return   {string}   The minimum granularity periodicity.
     */
    function _getMinimumGranularityPeriodicity() {
      var incrementalSchedulingPolicy =
        $scope.policy.incrementalSchedulingPolicy;

      switch (incrementalSchedulingPolicy.periodicity) {
        case 'kDaily':
          return !get(
            incrementalSchedulingPolicy, 'dailySchedule.days.length'
          ) ? 'kDay' : 'kWeek';

        case 'kMonthly':
          return 'kMonth';
      }

      return 'kDay';
    }


    /**
     * updates the Policy and flowSettings to refect a periodicity change for
     * the provided scheduleKey
     *
     * @param      {string}  scheduleKey  The schedule key, or
     *                                    'incrementalSchedulingPolicy'
     */
    $scope.periodicityChange = function periodicityChange(scheduleKey) {

      var schedulingPolicy;
      var uiPeriodicity;

      scheduleKey = scheduleKey || 'incrementalSchedulingPolicy';

      schedulingPolicy = $scope.policy[scheduleKey];
      uiPeriodicity = $scope.flowSettings.uiPeriodicity[scheduleKey];

      angular.merge(
        schedulingPolicy,
        periodicityStringPolicyDefaults[uiPeriodicity]
      );

      cleanupSchedules(scheduleKey);

      updateGranularityPeriodicities();
    };

    /**
     * Ensures a copy policy's (extended retention, archival, rx) multiplier
     * is set to 1 if periodicity is changed to kEvery
     *
     * @method   checkMultiplier
     * @param    {object}   copyPolicy   The copy policy object
     */
    $scope.checkMultiplier = function checkMultiplier(copyPolicy) {
      if (copyPolicy.periodicity === 'kEvery') {
        copyPolicy.multiplier = 1;
      }
    };

    /**
     * Updates the Policy and flowSettings to reflect the addition or removal of
     * a schedulingPolicy
     *
     * @param      {string}  scheduleKey  The schedule key representing the
     *                                    schedulingPolicy to be added or
     *                                    removed
     */
    $scope.toggleSchedule = function toggleSchedule(scheduleKey) {

      var addingSchedule = $scope.flowSettings.hasSchedule[scheduleKey];

      $scope.policy[scheduleKey] = addingSchedule ?
        angular.copy(defaultSchedulingPolicies[scheduleKey]) : undefined;

      $scope.flowSettings.uiPeriodicity[scheduleKey] = addingSchedule ?
        PolicyService.getPeriodicityString($scope.policy, scheduleKey) : undefined;

      // If the selected schedule is NOT fullSchedulingPolicy, we erase the
      // 'Use Full Snapshots' checkbox value.
      if (!$scope.flowSettings.hasSchedule.fullSchedulingPolicy) {
        $scope.policy.extendedRetentionPolicies.forEach(policy => {
          policy.backupRunType = undefined;
        });
      }
    };

    /**
     * Handles toggling of Log Schedule, removing or adding properties as
     * appropriate.
     */
    $scope.toggleLogSchedule = function toggleLogSchedule() {

      var enablingLogSchedulingPolicy =
        !$scope.flowSettings.hasSchedule.logSchedulingPolicy;

      $scope.flowSettings.hasSchedule.logSchedulingPolicy =
        enablingLogSchedulingPolicy;

      // if enabling Log Backups, set daysToKeepLog to match that of the
      // incremental schedule. Otherwise, remove the value completely.
      $scope.policy.daysToKeepLog = enablingLogSchedulingPolicy ?
        $scope.policy.daysToKeep : undefined;

      $scope.toggleSchedule('logSchedulingPolicy');
    };

    /**
     * Handles toggling of Log Schedule, removing or adding properties as
     * appropriate.
     *
     * @method   toggleSystemSchedule
     */
    $scope.toggleSystemSchedule = function toggleSystemSchedule() {

      var enablingSystemSchedulingPolicy =
        !$scope.flowSettings.hasSchedule.systemSchedulingPolicy;

      $scope.flowSettings.hasSchedule.systemSchedulingPolicy =
        enablingSystemSchedulingPolicy;

      // if enabling BMR Backups, set daysToKeepSystem to match that of the
      // incremental schedule. Otherwise, remove the value completely.
      $scope.policy.daysToKeepSystem = enablingSystemSchedulingPolicy ?
        $scope.policy.daysToKeep : undefined;

      $scope.toggleSchedule('systemSchedulingPolicy');
    };

    /**
     * Adds a new extendedRetentionPolicy{} to
     * $scope.policy.extendedRetentionPolicies[] with some sensible default
     * values set.
     */
    $scope.addExtendedRetentionPolicy = function addExtendedRetentionPolicy() {

      var defaultExtPolicies = {
        default: [
          {
            periodicity: 'kWeek',
            multiplier: 1,
            daysToKeep: 90,
          },
          {
            periodicity: 'kMonth',
            multiplier: 1,
            daysToKeep: 365,
          },
          {
            periodicity: 'kMonth',
            multiplier: 3,
            daysToKeep: 730,
          },
        ],

        // kContinuous represents jobs by # of minutes or hours (they all
        // translate to minutes). This will only serve 'minutes' though as
        // different defaults will be used for hourly based jobs.
        kContinuous: [
          {
            periodicity: 'kDay',
            multiplier: 1,
            daysToKeep: 90,
          },
          {
            periodicity: 'kWeek',
            multiplier: 1,
            daysToKeep: 365,
          },
          {
            periodicity: 'kMonth',
            multiplier: 1,
            daysToKeep: 730,
          },
        ],

        // This isn't a real periodicity. We must sniff the number of minutes
        // to determine if a schedule is hourly.
        kHourly: [
          {
            periodicity: 'kDay',
            multiplier: 1,
            daysToKeep: 90,
          },
          {
            periodicity: 'kWeek',
            multiplier: 1,
            daysToKeep: 365,
          },
          {
            periodicity: 'kMonth',
            multiplier: 1,

            // This particular value will be overwritten below if the hourly
            // schedule is equal to or more frequent than 4 hours (gold policy).
            daysToKeep: 730,
          },
        ],
      };

      var extendedRetentionPolicies = $scope.policy.extendedRetentionPolicies;

      // Value not to exceed the number of defaults provided in the above
      // configurations. This means any extended retention schedules added
      // beyond this will just repeatedly apply the last default for the
      // appropriate incrementalPeriodicity.
      var defaultIndex = extendedRetentionPolicies.length >= 2 ?
        2 : extendedRetentionPolicies.length;
      var incrementalPeriodicity =
        $scope.policy.incrementalSchedulingPolicy.periodicity;
      var continuousSchedule =
        $scope.policy.incrementalSchedulingPolicy.continuousSchedule;
      var isHourly = incrementalPeriodicity === 'kContinuous' &&
        (continuousSchedule.backupIntervalMins % 60) === 0;
      var newExtRetentionPolicy;

      switch (true) {
        case isHourly:
          newExtRetentionPolicy =
            clone(defaultExtPolicies.kHourly[defaultIndex]);
          break;

        default:
          // If the periodicity is represented in the config object, use it.
          // Otherwise simply use the default configration.
          newExtRetentionPolicy = defaultExtPolicies[incrementalPeriodicity] ?
            clone(defaultExtPolicies[incrementalPeriodicity][defaultIndex]) :
            clone(defaultExtPolicies.default[defaultIndex]);
      }

      // Per ENG-48771, its desirable for a longer 3rd extended retention if the
      // this is an hourly job having less than or equal to four hours (gold).
      if (isHourly && defaultIndex === 2 &&
        continuousSchedule.backupIntervalMins <= 240) {
        newExtRetentionPolicy.daysToKeep = 1096;
      }

      // Scale up the daysToKeep value if it doesn't make sense based on the
      // policy retention. This is "extended" retention so it should be kept
      // longer than a standard run, at least by default.
      if ($scope.policy.daysToKeep >= newExtRetentionPolicy.daysToKeep) {
        newExtRetentionPolicy.daysToKeep = $scope.policy.daysToKeep * 2;
      }

      $scope.policy.extendedRetentionPolicies.push(newExtRetentionPolicy);
    };

    /**
     * Adds an additional replication copy policy.
     */
    $scope.addReplication = function addReplication() {
      $scope.policy.snapshotReplicationCopyPolicies.push(
        angular.copy(defaultReplicationCopyPolicy)
      );
    };

    /**
     * Adds an additional archival copy policy.
     */
    $scope.addArchival = function addArchival() {
      $scope.policy.snapshotArchivalCopyPolicies.push(
        angular.copy(defaultArchivalCopyPolicy)
      );
    };

    /**
     * Adds an additional cloudDeploy copy policy.
     */
    $scope.addCloudDeploy = function addCloudDeploy() {
      $scope.policy.cloudDeployPolicies.push(
        angular.copy(defaultCloudDeployPolicy)
      );
    };

    /**
     * Handles updating on remote cluster selection.
     *
     * @param      {object}  cluster   the selected remote cluster
     * @param      {object}  rxPolicy  to be updated with cluster info
     */
    $scope.updateRxTarget = function updateRxTarget(cluster, rxPolicy) {
      var rxTarget = rxPolicy.target;

      rxTarget.clusterId = cluster.clusterId;
      rxTarget.clusterName = cluster.name;
    };

    /**
     * Gets the addNew replication target function for a particular rxPolicy.
     * This mechamism allows for storing a localized version of the rxPolicy so
     * it can be updated on successful registration of a new target.
     *
     * @method   getRegisterRxTargetFn
     * @param    {object}     rxPolicy   The repolication policy
     * @return   {function}   The register replication target function for use
     *                        with uiSelect addNew
     */
    $scope.getRegisterRxTargetFn = function getRegisterRxTargetFn(rxPolicy) {
      var rxTarget = rxPolicy.target;

      return  registerRxTarget;

      /**
       * Function to handle registering a new replication target.
       *
       * @method   registerRxTarget
       */
      function registerRxTarget() {
        // Unset the clusterId and clusterName and launch the new rx modal.
        rxTarget.clusterId = undefined;
        rxTarget.clusterName = undefined;

        RemoteClusterService.registerRemoteSlider().then(
          function registerRemoteSuccess(remote) {
            $scope.remoteClusters.unshift(remote);
            $scope.updateRxTarget(remote, rxPolicy);
          }
        );
      }
    };

    /**
     * Handles updating on external target selection. If "new" option is
     * selected launches slide modal for external target registration.
     *
     * @param      {object}  archPolicy  to be updated with target info
     */
    $scope.updateArchivalTarget = function updateArchivalTarget(archPolicy) {
      var target = archPolicy.target;
      var vault = archPolicy._vault;

      target.vaultId = vault.id;
      target.vaultName = vault.name;
      target.vaultType =
        (vault.externalTargetType === 'kQStarTape') ? 'kTape' : 'kCloud';
    };

    /**
     * Handles updating on cloudDeploy target selection.
     *
     * @param      {Object}  cloudDeployPolicy  to be updated with target info
     * @param      {Number}  index              index of cloudDeploy policy
     */
    $scope.updateCloudDeployTarget =
      function updateCloudDeployTarget(cloudDeployPolicy, index) {
        var type;
        var selectedTarget = cloudDeployPolicy._source.protectionSource;
        var kSourceType;
        var sourceType;
        var paramsStr;

        angular.extend(cloudDeployPolicy.target, {
          type: selectedTarget.environment,
          id: selectedTarget.id,
          name: selectedTarget.name,
        });

        type = cloudDeployPolicy.target.type;

        $scope.cloudDeploySourceLoading[index] = true;

        $scope.cloudDeployLists = $scope.cloudDeployLists || {};

        if (type === 'kAWS') {
          cloudDeployPolicy.isIncremental =
            // either there should be no awsParams set in policy (default state)
            // or the no of params set should be more than 1
            !cloudDeployPolicy.target.awsParams ||
              Object.keys(cloudDeployPolicy.target.awsParams).length > 1;

          // Reset dropdowns on source change only if source was changed
          // manually. This function gets called even when policy is loaded in
          // edit mode. The reset should not happen in that case.
          if (cloudDeployPolicy._edited) {
            set(cloudDeployPolicy, 'target.awsParams', {});
          }
        } else if (type === 'kAzure') {
          if (cloudDeployPolicy._edited) {
            // reset dropdowns on source change
            set(cloudDeployPolicy, 'target.azureParams', {});
          }
        }

        PubSourceService.getSource(selectedTarget.id).then(
          function getSourceSuccess(sources) {
            var cloudNodes = get(sources, '[0].nodes');

            switch (type) {
              case 'kAzure':
                kSourceType = 'kResourceGroup';
                sourceType = 'resourceGroup';
                paramsStr = 'azureParams';
                break;
              case 'kAWS':
                kSourceType = 'kRegion';
                sourceType = 'region';
                paramsStr = 'awsParams';
                break;
            }

            $scope.cloudDeployLists[sourceType + index] =
              filterNodes(cloudNodes, kSourceType);

            // Azure needs temp resources for linux VMs.
            // Arrange those options.
            if (type === 'kAzure') {
              $scope.cloudDeployLists[sourceType + index + 'Id'] =
                filterNodes(cloudNodes, kSourceType);
            }

            // updateCloudDeployTarget gets called on ng-change of
            // "select-parent-source". So it also gets called when we provide
            // a default value to the directive after the sources are loaded
            // which is the case in edit mode.
            // So in edit mode, if a value is available, this will execute
            if (cloudDeployPolicy.target[paramsStr] &&
              cloudDeployPolicy.target[paramsStr][sourceType]) {
              populateSource(type, sourceType,
                cloudDeployPolicy.target[paramsStr][sourceType],
                cloudDeployPolicy, index);

              // Azure needs temp resources for linux VMs.
              // Prefill those options.
              if (type === 'kAzure') {
                populateSource(type, sourceType,
                  cloudDeployPolicy.target[paramsStr]['tempVmResourceGroupId'],
                    cloudDeployPolicy, index + 'Id');
              }
            }
          }, evalAJAX.errorMessage
        ).finally(function getSourcesFinally() {
           $scope.cloudDeploySourceLoading[index] = false;

           // This key will be absent if the policy is loaded from API (edit
           // mode), since this is getting added in 'finally' after all loading
           // is finished. Adding this key will help in clearing fields
           // subsequently if policy target is modified
           cloudDeployPolicy._edited = true;
        });
      };

    /**
     * Handles updating on cloud replication target selection.
     *
     * @method     updateCloudDeployTarget
     * @param      {Object}  replicationPolicy  to be updated with target info
     * @param      {Number}  index              index of replication policy
     */
    $scope.updateCloudReplicationTarget =
      function updateCloudReplicationTarget(replicationPolicy, index) {
        var type;
        var selectedTarget =
          replicationPolicy.cloudTarget._source.protectionSource;
        var azureResourceGroup;
        var azureStorageAccount;

        angular.extend(replicationPolicy.cloudTarget, {
          type: selectedTarget.environment,
          id: selectedTarget.id,
          name: selectedTarget.name,
        });

        type = replicationPolicy.cloudTarget.type;

        switch (type) {
          case 'kAWS':
            $scope.replicationSources = $scope.replicationSources || [];
            $scope.replicationSources[index] = {
              aws: {},
            };

            PubSourceService.getSource(replicationPolicy.cloudTarget.id)
              .then(function getSourceSuccess(sources) {
                var nodes = get(sources, '[0].nodes');

                $scope.replicationSources[index]['aws'].regions = nodes.filter(
                  function forEachNode(node) {
                    return node._type === 'kRegion';
                  });

                if (replicationPolicy._canEdit) {
                  replicationPolicy.cloudTarget.awsParams = {};
                }
              }).finally(function afterSourcesLoaded() {
                replicationPolicy._canEdit = true;
              });

            break;

          case 'kAzure':
            $scope.replicationSources = $scope.replicationSources || [];
            $scope.replicationSources[index] = {
              azure: {},
            };

            $scope.replicationSources[index]['azure'].storageContainers = [];
            $scope.replicationSources[index]['azure'].storageAccounts = [];

            PubSourceService.getSource(replicationPolicy.cloudTarget.id)
              .then(function getSourceSuccess(sources) {
                var nodes = get(sources, '[0].nodes');

                $scope.replicationSources[index]['azure'].resourceGroups =
                  nodes.filter(function forEachNode(node) {
                    return node._type === 'kResourceGroup';
                  });

                $scope.replicationSources[index]['azure'].nodes = nodes;

                if (replicationPolicy._canEdit) {
                  replicationPolicy.cloudTarget.azureParams = {};
                }

                // Edit Mode
                if (get(replicationPolicy,
                  'cloudTarget.azureParams.storageAccount')) {

                  // find resourceGroup object using id available in policy
                  azureResourceGroup = find(
                    $scope.replicationSources[index]['azure'].resourceGroups,
                      function eachResourceGroup(resourceGroup) {
                        return resourceGroup.protectionSource.id ===
                          replicationPolicy.cloudTarget
                            .azureParams.resourceGroup;
                      });

                  $scope.replicationSources[index]['azure'].location =
                    azureResourceGroup._envProtectionSource.location;

                  // populate storageAccount dropdown based on resourceGroup
                  _populateStorageAccounts(index);
                }
                if (get(replicationPolicy,
                  'cloudTarget.azureParams.storageContainer')) {

                  // find storageAccount object using id available in policy
                  azureStorageAccount = find(
                    $scope.replicationSources[index]['azure'].storageAccounts,
                      function eachResourceGroup(resourceGroup) {
                        return find(
                          resourceGroup.nodes, function findNode(node) {

                          return node.protectionSource.id === replicationPolicy
                            .cloudTarget.azureParams.storageContainer;
                        });
                      });

                  // populate storageContainer dropdown based on storageAccount
                  if (azureStorageAccount) {
                    _populateStorageContainers(
                      azureStorageAccount.nodes, index);
                  }
                }
              }).finally(function afterSourcesLoaded() {
                replicationPolicy._canEdit = true;
              });

            break;
        }
      };

    /**
     * Populate Storage Accounts on selection of an Azure resource group for
     * replication.
     *
     * @method  selectReplicationResourceGroup
     * @param   {Object}  replicationPolicy   The associated policy
     * @param   {Object}  resourceGroup       The selected resource group
     * @param   {Number}  index               replicationPolicy index
     */
    $scope.selectReplicationResourceGroup =
      function selectReplicationResourceGroup(
        replicationPolicy, resourceGroup, index) {

        $scope.replicationSources[index]['azure'].location =
          resourceGroup._envProtectionSource.location;

        $scope.replicationSources[index]['azure'].storageAccounts = [];
        $scope.replicationSources[index]['azure'].storageContainers = [];

        replicationPolicy.cloudTarget.azureParams.storageAccount =
          replicationPolicy.cloudTarget.azureParams.storageResourceGroupId =
            replicationPolicy.cloudTarget.azureParams.storageContainer =
              undefined;

        _populateStorageAccounts(index);
      };

    /**
     * populate storage accounts dropdown for azure replication
     *
     * @method  _populateStorageAccounts
     * @param   {Number}  index          replicationPolicy index
     */
    function _populateStorageAccounts(index) {
      each($scope.replicationSources[index]['azure'].resourceGroups,
        function eachResourceGroup(resourceGroup) {
          each(resourceGroup.nodes, function forEachNode(node) {
            if (node._type === 'kStorageAccount' &&
              (node._envProtectionSource.location ===
                $scope.replicationSources[index]['azure'].location)) {
                  node._resourceGroup = resourceGroup.protectionSource;
                  node._resourceGroupName =
                    resourceGroup.protectionSource.name;
                  $scope.replicationSources[index]['azure']
                    .storageAccounts.push(node);
                }
          });
      });
    }

    /**
     * Populate Storage Containers on selection of an Azure storage account for
     * replication.
     *
     * @method  selectReplicationStorageAccount
     * @param   {Object}  replicationPolicy   The associated policy
     * @param   {Object}  storageAccount  The selected sotrage account
     * @param   {Number}  index          replicationPolicy index
     */
    $scope.selectReplicationStorageAccount =
      function selectReplicationStorageAccount(
        replicationPolicy, storageAccount, index) {

        var nodes = storageAccount.nodes;

        replicationPolicy.cloudTarget.azureParams.storageContainer = undefined;

        _populateStorageContainers(nodes, index);

        replicationPolicy.cloudTarget.azureParams.storageResourceGroupId =
          storageAccount._resourceGroup.id;
      };

    /**
     * Populate storage containers dropdown for azure replication
     *
     * @method  _populateStorageContainers
     *
     * @param   {Object[]}  nodes   List of nodes to iterate for finding
     *                              storageContainers
     * @param   {Number}    index   replicationPolicy index
     */
    function _populateStorageContainers(nodes, index) {
      $scope.replicationSources[index]['azure'].storageContainers =
        nodes.filter(function forEachNode(node) {
          return node._type === 'kStorageContainer';
        });
    }

    /**
     * Handles selection of an Azure entity as cloudDeploy Target.
     *
     * @method   selectAzureEntity
     * @param    {Object}   cloudDeployPolicy   The associated policy
     * @param    {Object}   item                The selected azure entity object
     * @param    {Number}   index               CloudDeploy Policy index
     * @param    {String}   suffix              A suffix to identify special
     *                                          entities (like inline VM)
     */
    $scope.selectAzureEntity =
      function selectAzureEntity(cloudDeployPolicy, item, index, suffix) {
        var azureParams;
        var nextKSourceType;
        var nextSourceType;
        var nextTempSourceType;
        var sourceId;

        // when an entity is selected, dependent entities in subsequent
        // dropdowns need to be reset. Populate the entities to be reset in
        // these variables.
        var resetEntities;
        var tempResetEntities;

        suffix = index + (suffix || '');

        cloudDeployPolicy.target.azureParams =
          cloudDeployPolicy.target.azureParams || {};

        azureParams = cloudDeployPolicy.target.azureParams;

        switch (item._type) {
          case 'kResourceGroup':
            nextKSourceType = 'kStorageAccount';
            nextSourceType = 'storageAccount';
            nextTempSourceType = 'tempVmStorageAccountId';
            resetEntities = ['storageAccount', 'storageContainer'];
            tempResetEntities = ['tempVmStorageAccountId',
              'tempVmStorageContainerId', 'tempVmVirtualNetworkId',
                'tempVmSubnetId'];
            break;
          case 'kStorageAccount':
            nextKSourceType = 'kStorageContainer';
            nextSourceType = 'storageContainer';
            nextTempSourceType = 'tempVmStorageContainerId';
            if (item._resourceGroup) {
              cloudDeployPolicy.target.azureParams.storageResourceGroupId =
                item._resourceGroup.id;
            }
            resetEntities = ['storageContainer'];
            tempResetEntities = ['tempVmStorageContainerId'];
            break;
          case 'kVirtualNetwork':
            nextKSourceType = 'kSubnet';
            nextSourceType = 'subnet';
            nextTempSourceType = 'tempVmSubnetId';
            if (item._resourceGroup) {
              cloudDeployPolicy.target.azureParams.networkResourceGroupId =
                item._resourceGroup.id;
            }
            tempResetEntities = ['tempVmSubnetId'];
            break;
        }

        if (nextKSourceType) {
          // list all storage accounts and virtual networks, irrespective of
          // which resource group is selected
          if (nextKSourceType === 'kStorageAccount') {
            _groupResourcesForAzure('storageAccount',
              'kStorageAccount', item, suffix);
            _groupResourcesForAzure('virtualNetwork',
              'kVirtualNetwork', item, suffix);
          } else {
            $scope.cloudDeployLists[nextSourceType+suffix] =
              filterNodes(item.nodes, nextKSourceType);
          }

          if (azureParams && (azureParams[nextSourceType] ||
            azureParams[nextTempSourceType])) {

            // 'Id' in suffix indicates its a 'temp' Azure resource
            if (suffix.indexOf('Id') > -1 && nextTempSourceType) {
              sourceId = azureParams[nextTempSourceType];
              resetEntities = tempResetEntities;
            } else {
              sourceId = azureParams[nextSourceType];
            }

            // Reset dropdowns dependent on selected entity
            if (cloudDeployPolicy._edited) {
              each(resetEntities, function eachEntity(entity) {
                set(cloudDeployPolicy,
                  'target.azureParams.' + entity, undefined);
              });
            }

            populateSource('kAzure', nextSourceType || nextTempSourceType,
              sourceId, cloudDeployPolicy, suffix);

            // For Resource Group selection, virtual network should also be
            // populated
            if (item._type === 'kResourceGroup') {
              populateSource('kAzure', 'virtualNetwork',
                azureParams['tempVmVirtualNetworkId'],
                  cloudDeployPolicy, suffix);
            }
          }
        }
      };

    /**
     * Storage Account and Virtual Network are to be grouped according to their
     * Resource Group
     *
     * @method  _groupResourcesForAzure
     * @param   {Object}  item    The selected azure entity object
     * @param   {String}  type    The type of entity to be grouped
     * @param   {String}  kType   The kType of entity to be grouped
     * @param   {String}  suffix  Suffix for special identitfication of entities
     */
    function _groupResourcesForAzure(type, kType, item, suffix) {
      $scope.cloudDeployLists[type + suffix] =
        flatten($scope.cloudDeployLists['resourceGroup' + suffix]
          .map(function forEachResourceGroup(src) {
            if (src._envProtectionSource.location ===
              item._envProtectionSource.location) {

              // filter out the required entity types
              var filteredNodes = filterNodes(src.nodes, kType);

              // further filter based on location of selected resource group
              filteredNodes = filter(filteredNodes, [
                '_envProtectionSource.location',
                item._envProtectionSource.location
              ]);

              filteredNodes.forEach(function eachNode(node) {
                node._resourceGroup = src.protectionSource;
                node._groupName = src.protectionSource.name;
              });

              return filteredNodes;
            } else {
              return [];
            }
          })
        );
    }

    /**
     * Handles selection of an Aws entity as cloudDeploy Target.
     *
     * @method   selectAwsEntity
     * @param    {Object}   cloudDeployPolicy   The associated policy
     * @param    {Object}   item                The selected aws entity object
     * @param    {Number}   index               CloudDeploy Policy index
     */
    $scope.selectAwsEntity =
      function selectAwsEntity(cloudDeployPolicy, item, index) {
        var awsParams;
        var nextKSourceType;
        var nextSourceType;

        // when an entity is selected, dependent entities in subsequent
        // dropdowns need to be reset. Populate the entities to be reset in
        // this variable.
        var resetEntities;

        if (!cloudDeployPolicy.isIncremental) {
          return;
        }

        cloudDeployPolicy.target.awsParams =
          cloudDeployPolicy.target.awsParams || {};

        awsParams = cloudDeployPolicy.target.awsParams;

        switch (item._type) {
          case 'kRegion':
            nextKSourceType = ['kVPC'];
            nextSourceType = ['virtualPrivateCloudId'];
            resetEntities = ['virtualPrivateCloudId', 'subnetId'];
            break;

          case 'kVPC':
            nextKSourceType = ['kSubnet'];
            nextSourceType = ['subnetId'];
            resetEntities = ['subnetId'];
            break;
        }

        // Populate the next set of dropdown options
        (nextKSourceType || [])
          .forEach(function eackKSourceType(kSourceType, i) {
            $scope.cloudDeployLists[nextSourceType[i]+index] =
              filterNodes(item.nodes, kSourceType);
          });

        // If an earlier dropdown is changed, reset the further dropdown
        // selections
        if (cloudDeployPolicy._edited) {
          each(resetEntities, function eachEntity(entity) {
            set(cloudDeployPolicy, 'target.awsParams.' + entity, undefined);
          });
        }

        // If the dropdwons have to be populated as a reult of API response
        (nextSourceType || []).forEach(function eachSourceType(sourceType) {
          if (awsParams && awsParams[sourceType]) {
            populateSource('kAWS', sourceType,
              awsParams[sourceType], cloudDeployPolicy, index);
          }
        });
      };

    /**
     * when multiple cloudDeployTargets are available, differentiate them
     * based on index and return the corressponding list options for select
     * dropdown
     *
     * @param    {String}     type       The entityType for dropdown list
     * @param    {Number}     index      The index of cloudDeploy Policy
     * @return   {Array|Object}          The list of options to show in dropdown
     * @param    {String}   suffix       A suffix to identify special
     *                                   (like inline VM) entities
     */
    $scope.getCloudDeployListOptions =
      function getCloudDeployListOptions(type, index, suffix) {
        return $scope.cloudDeployLists[type + index + (suffix || '')];
      };

    /**
     * callback function when cloudDeploy type (full/incremental) is selected
     *
     * @param   {Object}   cloudDeployPolicy   The current cloudDepoly policy
     * @param   {Number}   index               The index of cloudDeploy Policy
     */
    $scope.cloudDeployTypeSelected =
      function cloudDeployTypeSelected(cloudDeployPolicy, index) {
        var region;
        var regionObj;

        switch (cloudDeployPolicy.target.type) {
          case 'kAWS':
            region = get(cloudDeployPolicy, 'target.awsParams.region');

            if (!cloudDeployPolicy.isIncremental) {
              // set only the parameters needed for 'full' and ignore the rest
              set(cloudDeployPolicy, 'target.awsParams', {
                region: region,
              });
            }

            // if region is already selected, populate other dropdowns
            if (region && cloudDeployPolicy.isIncremental) {
              regionObj = find($scope.cloudDeployLists['region' + index],
                function findRegion(node) {
                  return node.protectionSource.id === region;
                });

              $scope.selectAwsEntity(cloudDeployPolicy, regionObj, index);
            }

            break;

          default:
            angular.noop();
        }
      }

    /**
     * filter nodes in a tree based on node type
     *
     * @method   filterNodes
     * @param    {Object[]}   nodeList   The node list
     * @param    {String}     type       The type of nodes to filter
     */
    function filterNodes(nodeList, type) {
      return nodeList.filter(
        function forEachNode(node) {
          return node._type === type;
        }
      );
    }

    /** pre populate sources in dropdown models based on response
     *
     * @method   populateSource
     * @param    {String}     type                The environment type
     * @param    {String}     sourceType          The source type
     * @param    {Number}     id                  The id of source
     * @param    {Object}     cloudDeployPolicy   The associated policy
     * @param    {Number}     index               Index of the policy
     */
    function populateSource(type, sourceType, id, cloudDeployPolicy, index) {
      var source;

      source =
        $scope.cloudDeployLists[sourceType + index]
          .find(function findEntity(src) {
            return isArray(id) ? indexOf(id, src.protectionSource.id) > -1 :
              src.protectionSource.id === id;
          });

      // this will populate the subsequent dropdowns
      switch (type) {
        case 'kAzure':
          if (source) {
            $scope.selectAzureEntity(cloudDeployPolicy, source, index);
          }
          break;

        case 'kAWS':
          $scope.selectAwsEntity(cloudDeployPolicy, source, index);
          break;
      }
    }

    /**
     * Adds a new blackout period to the policy.
     */
    $scope.addBlackoutPeriod = function addBlackoutPeriod() {
      $scope.policy.blackoutPeriods.push(
        angular.copy(defaultBlackoutPeriod)
      );
    };

    /**
     * review Policy Jobs & if needed capture the jobs start time.
     *
     * @method    reviewPolicyJobs
     * @return   {object}     promise successfully resolved when jobs updated or
     *                        reviewed else rejected when modal dissmissed.
     */
    function reviewPolicyJobs() {
      var modalConfig = {
        size: 'md',
        templateUrl:
          'app/protection/policies/modals/review-policy-jobs-modal.html',
        controller: 'reviewPolicyJobsController',
        resolve: {
          params: function reviewPolicyJobsParamResolverFn() {

            // need a copy of policyJobs as reviewPolicyJobs modal mutate the
            // object.
            return {
              requireStartTimes: doPolicyJobsRequireStartTimes($scope.policy),
              policyJobs: cUtils.simpleCopy($scope.policyJobs),
            };
          },
        },
      };

      var windowOptions = {
        actionButtonKey: 'save',
        titleKey: null,
      };

      return cModal.standardModal(modalConfig, windowOptions);
    }

    /**
     * Saves a policy.
     *
     * @method   savePolicy
     * @return   {Function}   promise resolved to updated policy on successfully
     *                        created/updated the policy.
     */
    function savePolicy() {
      var actionFn = null;
      var flowSettings = $scope.flowSettings;

      flowSettings.submitting = true;

      // is create or update the policy
      actionFn = flowSettings.inEditMode ?
        PolicyService.updatePolicy : PolicyService.createPolicy;

      return actionFn($scope.policy).then(
        function actionSuccess(policy) {
          return policy;
        },
        evalAJAX.errorMessage
      ).finally(
        function actionFinally() {
          flowSettings.submitting = false;
        }
      );
    }

    /**
     * test does new Policy updates needs to capture the jobs start times.
     * we need to capture jobs start time only when policy is updated from
     * continous to non-continous.
     *
     * @closure  {object}  doesLoadedPolicyRequireStartTimes  does old policy
     *                                                        needs start time
     *                                                        for jobs.
     *
     * @method   doPolicyJobsRequireStartTimes
     * @param    {object}    newPolicy   The new policy
     * @return   {boolean}   true if need to review policy Jobs else false.
     */
    function doPolicyJobsRequireStartTimes(newPolicy) {
      // if doesLoadedPolicyRequireStartTimes is false means old policy is
      // continous and if newPolicy._requireStartTimes is true means
      // policy is non-continous
      return newPolicy._requireStartTimes && !doesLoadedPolicyRequireStartTimes;
    }

    /**
     * Handles form submission.
     * save the policy and update the run time of associated jobs with policy.
     *
     * @param      {object}  policyForm  The policy form
     */
    $scope.submitForm = function submitForm(policyForm) {
      var jobsUpdatePromise;

      // exit early if the form is invalid
      if (policyForm.$invalid) { return; }

      // review Policy Jobs else go ahead and save the policy
      jobsUpdatePromise =
        $scope.policyJobs.length !== 0 ? reviewPolicyJobs() : $q.resolve({});

      // save the policy
      jobsUpdatePromise.then(function onJobsUpdateSuccess(updatedJobsMap) {
        return savePolicy().then(
          function onSavePolicySuccess(policy) {

            // creating/editing policy in modal view
            if ($scope.flowSettings.inModal) {
              $state.current.help = parentHelpId;

              // need to update the editing job & policy.
              return $uibModalInstance.close({
                policy: policy,
                updatedJobsMap: updatedJobsMap,
              });
            }

            // creating/editing policy in page view
            StateManagementService.goToPreviousState('policies');
          }
        );
      });
    };

    /**
     * Toggles the dataLock of the policy. Only a security officer can toggle
     * it. It issues a warning when the dataLock is turned on.
     *
     * @method   dataLockToggled
     */
    $scope.dataLockToggled = function dataLockToggled() {
      if ($scope.policy.wormRetentionType) {
        cMessage.warn({ textKey: 'policyModify.dataLockWarning' });
      } else {
        $scope.policy.wormRetentionType = undefined;
      }
    };

    /**
     * Reset the replication target when the target 'type' is changed
     *
     * @method  resetCloudReplicationTarget
     * @param   {Object}  policy  The selected replication policy
     */
    $scope.resetCloudReplicationTarget =
      function resetCloudReplicationTarget(policy) {
        if (['aws', 'azure'].includes(policy._replicateTo)) {
          policy.target = undefined;
          policy.cloudTarget =
            angular.copy(defaultCloudDeployPolicy.target);
        } else {
          policy.cloudTarget = undefined;
          policy.target =
            angular.copy(defaultReplicationCopyPolicy.target);
        }
      };

    /**
     * Add temporary Linux VM resources to cloudDeploy policy
     *
     * @method  addTempAzureResources
     * @param   {Object}  policy  The selected cloudDeploy policy object
     */
    $scope.addTempAzureResources = function addTempAzureResources(policy) {
      policy.target.azureParams = policy.target.azureParams || {};

      assign(policy.target.azureParams, {
        tempVmResourceGroupId: undefined,
        tempVmStorageAccountId: undefined,
        tempVmStorageContainerId: undefined,
        tempVmVirtualNetworkId: undefined,
        tempVmSubnetId: undefined,
      });

      policy._hasTempResources = true;
    };

    /**
     * Remove temporary Linux VM resources from cloudDeploy policy
     *
     * @method  removeTempAzureResources
     * @param   {Object}  policy  The selected cloudDeploy policy object
     */
    $scope.removeTempAzureResources = function removeTempAzureResources(policy) {
      policy.target.azureParams = omit(policy.target.azureParams, [
        'tempVmResourceGroupId', 'tempVmStorageAccountId', 'tempVmSubnetId',
        'tempVmStorageContainerId', 'tempVmVirtualNetworkId',
      ]);

      policy._hasTempResources = false;
    };

    activate();
  }

}(angular));
