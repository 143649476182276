import { AppConfig, AppName, EntitlementKeys } from './models';

/**
 * Cohesity appliance manager UI.
 */
const applianceManagerAppConfig: AppConfig = {
  name: 'applianceManager',
  libName: '@cohesity/cluster-manager',
  urlSegment: '/appliance-manager/dashboard',
  params: { serviceType: 'applianceManager' },
  rootContainerClassName: 'appliance-manager-root',
  icon: 'helix:logo-appliance-manager',
  pillar: 'appliance-manager',
  app: 'applianceManager',
};

/**
 * Cohesity cluster manager app aka current iris/helios UI.
 */
const clusterManagerAppConfig: AppConfig = {
  name: 'clusterManager',
  libName: '@cohesity/cluster-manager',
  urlSegment: '/dashboards/summary',
  params: { serviceType: 'clusterManager' },
  rootContainerClassName: 'cluster-manager-root',
  icon: 'helix:logo-cluster-manager',
  pillar: 'protection',
  app: 'clusterManager',
};

/**
 * Cohesity Security Center app.
 */
const securityCenterAppConfig: AppConfig = {
  name: 'securityCenter',
  libName: '@cohesity/argus',
  publicPath: '/datahawk/spa',
  urlSegment: '/datahawk',
  params: { serviceType: 'dgaas' },
  rootContainerClassName: 'argus-root',
  icon: 'helix:logo-security-center',
  pillar: 'security',
  app: 'dgaas',
};

/**
 * Cohesity SiteContinuity V2 aka DRaaS app.
 */
const siteContinuityV2AppConfig: AppConfig = {
  name: 'siteContinuityV2',
  libName: '@cohesity/site-continuity-v2',
  publicPath: '/site-continuity-spa',
  urlSegment: '/site-continuity',
  rootContainerClassName: 'sc-root',
  icon: 'helix:logo-site-continuity',
  pillar: 'mobility',
  app: 'draas',
  entitlements: [EntitlementKeys.SiteContinuityInfo],
};

/**
 * Cohesity SiteContinuity aka DRaaS app.
 */
const siteContinuityAppConfig: AppConfig = {
  name: 'siteContinuity',
  libName: '@cohesity/site-continuity',
  urlSegment: '/dashboards/disaster-recovery',
  params: { serviceType: 'draas' },
  rootContainerClassName: 'sc-root',
  icon: 'helix:logo-site-continuity',
  pillar: 'mobility',
  app: 'draas',
  entitlements: [EntitlementKeys.SiteContinuityInfo],
};

/**
 * Cohesity DataProtect aka DMaaS app.
 */
const dataProtectAppConfig: AppConfig = {
  name: 'dataProtect',
  libName: '@cohesity/data-protect',
  urlSegment: '/dashboards/dms',
  params: { serviceType: 'dms' },
  rootContainerClassName: 'data-protect-root',
  icon: 'helix:logo-data-protect',
  pillar: 'protection',
  app: 'dms',
  entitlements: [EntitlementKeys.DataProtect, EntitlementKeys.DataProtectAzure],
};

/**
 * Cohesity GlobalView.
 */
const globalAppConfig: AppConfig = {
  name: 'global',
  libName: '@cohesity/global',
  urlSegment: '/dashboards/global',
  params: { serviceType: 'global' },
  rootContainerClassName: 'global-root',
  icon: 'helix:logo-global',
  app: 'global',
};

/**
 * Cohesity FortKnox aka RPaaS app.
 */
const fortKnoxAppConfig: AppConfig = {
  name: 'fortKnox',
  libName: '@cohesity/fort-knox',
  urlSegment: '/rpaas/dashboard',
  params: { serviceType: 'rpaas' },
  rootContainerClassName: 'fort-knox-root',
  icon: 'helix:logo-fort-knox',
  pillar: 'security',
  app: 'rpaas',
  entitlements: [
    EntitlementKeys.FortKnoxAzureHot,
    EntitlementKeys.FortKnoxAzureCool,
    EntitlementKeys.FortKnoxCold
  ],
};

/**
 * Cohesity Gaia app.
 */
const gaiaAppConfig: AppConfig = {
  name: 'gaia',
  libName: '@cohesity/gaia',
  publicPath: '/gaia/spa',
  urlSegment: '/gaia',
  params: { serviceType: 'diaas' },
  rootContainerClassName: 'gaia-root',
  icon: 'helix:pillar-insights',
  pillar: 'insights',
  app: 'diaas',
};

/**
 * Cohesity Live Sizer App.
 */
const sizerAppConfig: AppConfig = {
  name: 'sizer',
  libName: '@cohesity/sizer',
  publicPath: '/sizer/spa',
  urlSegment: '/live-sizer-support',
  params: { serviceType: 'piaas' },
  rootContainerClassName: 'sizer-root',
  icon: 'helix:pillar-insights',
  pillar: 'insights',
  app: 'piaas',
};

/**
 * Platform Insights app.
 */
const platformInsightsAppConfig: AppConfig = {
  name: 'platformInsights',
  libName: '@cohesity/global',
  urlSegment: '/monitoring',
  params: { serviceType: 'piaas' },
  rootContainerClassName: 'platform-insights-root',
  icon: 'helix:pillar-insights',
  pillar: 'insights',
  app: 'piaas',
};

/**
 * Cohesity smartfiles helios
 */
const smartFilesAppConfig: AppConfig = {
  name: 'smartFiles',
  libName: '@cohesity/smartfiles',
  urlSegment: '/smartfiles',
  rootContainerClassName: 'sf-cohesity-root',
  icon: 'helix:logo-site-continuity',
  pillar: 'mobility',
  app: 'smartFiles',
  params: { serviceType: 'smartFiles' },
};

/**
 * The app config by app name.
 */
export const appConfigMap: Record<AppName, AppConfig> = {
  clusterManager: clusterManagerAppConfig,
  dataProtect: dataProtectAppConfig,
  fortKnox: fortKnoxAppConfig,
  gaia: gaiaAppConfig,
  global: globalAppConfig,
  platformInsights: platformInsightsAppConfig,
  securityCenter: securityCenterAppConfig,
  siteContinuity: siteContinuityAppConfig,
  siteContinuityV2: siteContinuityV2AppConfig,
  sizer: sizerAppConfig,
  smartFiles: smartFilesAppConfig,
  applianceManager: applianceManagerAppConfig,
};
