import { noop } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: GlobalSearchJob

;(function(angular, undefined) {
  'use strict';

  angular.module('C.globalSearch')
    .controller('GlobalSearchJobCtrl', GlobalSearchJobCtrlFn)
    .component('globalSearchJob', {
      bindings: {
        // @type {Object}  A Job object as provided via global search result.
        globalSearchJob: '<',

        // @type {object} A hash (by id) of remote clusters
        remoteClustersHash: '<',
      },
      controller: 'GlobalSearchJobCtrl',
      templateUrl:
        'app/global-search/global-search-job/global-search-job.html',
    });

  function GlobalSearchJobCtrlFn(_, JobActionService, PubJobService, evalAJAX) {

    var $ctrl = this;

    assign($ctrl, {
      // Lifecycle hooks
      $onInit: $onInit,

      loadingData: false,
    });

    /**
     * Initialization function.
     *
     * @method   $onInit
     */
    function $onInit() {
      // For an RPO Job, we cannot get job results as the jobs are internal. So
      // add RPO actions and skip the job call
      if ($ctrl.globalSearchJob.isRpoJob) {
        _addRpoActions($ctrl.globalSearchJob);
      } else {
        getJob().then(
          function getJobCompleted(job) {
            $ctrl.globalSearchJob.environment = job.environment;
            _addJobActions($ctrl.globalSearchJob);
          }
        );
      }
    }

    /**
     * Gets the job data
     *
     * @method   getJob
     * @return   {object}   The job.
     */
    function getJob() {
      return PubJobService.getJobFromCluster(
        $ctrl.globalSearchJob.jobId, $ctrl.globalSearchJob.clusterId).then(
          function getJobSuccess(job) {
            return $ctrl.job = job;
          },
          evalAJAX.errorMessage
        ).finally(
          function getJobFinally() {
            $ctrl.loadingData = false;
          }
        );
    }

    /**
     * Adds actions (by reference) for the provided search result's Jobs.
     *
     * @param    {object}   rpoJob   Global search RPO job object
     * @return   {object}   The job with relevant actions added
     */
    function _addRpoActions(rpoJob) {
      rpoJob._actions = [{
        translateKey: 'edit',
        icon: 'icn-edit',
        state: 'protection-policy.rpo-modify',
        stateParams: {
          policyId: rpoJob.policyId,
        },
      }];

      return rpoJob;
    }

    /**
     * Adds actions (by reference) for the provided search result's Jobs.
     *
     * @param    {object}   job   Global search job object
     * @return   {object}   The job with relevant actions added
     */
    function _addJobActions(job) {
      var runNowAction = JobActionService.getJobAction('start',
        // faking a private job object because run now modal inexplicable
        // needs an entire backupJobSummary{} provided via resolve.
        {
          jobDescription: {
            jobUid: {
              clusterId: job.clusterId,
            },
            jobId: job.jobId,
            name: job.jobName,
            policyId: job.policyId,
          }
        },
        noop
      );

      job._actions = [
        {
          translateKey: 'edit',
          icon: 'icn-edit',
          state: 'job-modify',
          stateParams: {
            id: [
              job.clusterId,
              job.clusterIncarnationId,
              job.jobId,
            ].join(':'),
            cid: job.clusterId,
            environments: [job.environment],
          },
        },
        runNowAction,
      ];

      return job;
    }

  }

})(angular);
