import { merge } from 'lodash-es';
import { keyBy } from 'lodash-es';
import { reduce } from 'lodash-es';
import { find } from 'lodash-es';
import { forEach } from 'lodash-es';
import { map } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Service: GlobalSearchService

;(function(angular, undefined) {
  'use strict';

  // Cache of the most recently run query value. Used to sync data between
  // implementation points.
  var currentQuery = '';
  var currentResults = [];
  var selectedResult;

  angular.module('C.globalSearch')
    .service('GlobalSearchService', GlobalSearchServiceFn);

  function GlobalSearchServiceFn(_, $rootScope, $http, evalAJAX,
    DateTimeService, PubJobService, SearchService, JobRunsService,
    RestoreService, ENV_GROUPS, API, ENV_TO_DBTYPE, SOURCE_KEYS,
    ENUM_ARCHIVAL_TARGET, SNAPSHOT_TARGET_TYPE, LOCALE_SETTINGS,
    FEATURE_FLAGS, NgMcmViewService) {

    /**
     * A hash of SQL jobs keyed by id for property lookup.
     */
    var DB_JOBS_HASH_CACHE = {};

    $rootScope.$on('logout.initiated', _clearSearchCache);

    return {
      cacheDbJobs: cacheDbJobs,
      getCurrentQuery: getCurrentQuery,
      getCurrentResults: getCurrentResults,
      getResults: getResults,
      getSelectedResult: getSelectedResult,
      getSnapshotsForTimeRange: getSnapshotsForTimeRange,
      setCurrentQuery: setCurrentQuery,
      setSelectedResult: setSelectedResult,
    };

    /**
     * Sets the most recently searched query.
     *
     * @method   setCurrentQuery
     * @param    {string}   query   The query
     * @return   {string}   The current query.
     */
    function setCurrentQuery(query) {
      return currentQuery = query;
    }

    /**
     * Provides the most recently searched query.
     *
     * @method   getCurrentQuery
     * @return   {string}   The current query.
     */
    function getCurrentQuery() {
      return currentQuery;
    }

    /**
     * Provides the most recently searched query.
     *
     * @method   getCurrentResults
     * @return   {string}   The result set for the most recently run query.
     */
    function getCurrentResults() {
      return currentResults;
    }

    /**
     * Calls the API to get global search results.
     *
     * @method   getResults
     * @param    {object}   params   The query params
     * @return   {object}   Promis to resolve the request for search results.
     */
    function getResults(params) {
      currentQuery = params.searchString;

      return $http({
        method: 'GET',
        url: $rootScope.basicClusterInfo.mcmMode ?
          API.mcm('globalSearch') : API.public('search/protectionSources'),
        params: params,
      }).then(function getSearchSuccess(resp) {
        return currentResults = (resp.data || []).map(_transformSearchResult);
      });
    }

    /**
     * Sets/caches the selectedResult.
     *
     * @method   setSelectedResult
     * @param    {object}   result   The result
     */
    function setSelectedResult(result) {
      selectedResult = result;
      _addSnapshotDetailsToResult(result);

      if (FEATURE_FLAGS.enableGlobalSearchCalendarSnapshots &&
        !$rootScope.basicClusterInfo.mcmMode) {
        // The below function makes API calls to populate data for
        // <global-search-snapshot>, which is not shown in MCM Mode.
        _addCalendarDetailsToResult(result);
      }
    }

    /**
     * Provides the previously cached selectedResult
     *
     * @method   getSelectedResult
     * @return   {object}   The selected result.
     */
    function getSelectedResult() {
      var index = currentResults.indexOf(selectedResult);

      // If the selectedResult is in the set and not already the first item,
      // move it to the top of the stack so its presented first to the user.
      if (index > 0) {
        currentResults.splice(index, 1);
        currentResults.unshift(selectedResult);
      }

      return selectedResult;
    }

    /**
     * Calls the API to decorate the provided search result with snapshot info.
     *
     * @method   _addSnapshotDetailsToResult
     * @param    {object}   result   The result to add snapshot info to
     */
    function _addSnapshotDetailsToResult(result) {
      // if this has already happened for the particular result,
      if (result.snapshots) {
        return;
      }

      $http({
        method: 'get',
        url: $rootScope.basicClusterInfo.mcmMode ?
          API.mcm('entityProtectionRun') : API.public('search/protectionRuns'),
        params: {
          uuid: result.uuid,
        }
      }).then(
        function getSnapshotDetailsSuccess(resp) {
          result.snapshotInfo = _transformSnapshotInfo(resp.data || {}, result);
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Function to load all snapshots of an object within a time range (first
     * and last day of the month).
     *
     * TODO(pg): When ENG-78381 and ENG-78405 is resolved (6.4.1 fix version),
     * c-snapshot-picker can be used as intended, and all the workaround code
     * for the above issues can be removed, which makes the bulk of this
     * function extraneous.
     *
     * @param   {number}   startTimeUsecs
     * @param   {number}   endTimeUsecs
     */
    function getSnapshotsForTimeRange(startTimeUsecs, endTimeUsecs) {
      var result = getSelectedResult();
      var versions = [];
      var jobRunsPromise;
      var key;

      // Load snapshots of the current month by default
      startTimeUsecs = startTimeUsecs || moment().startOf('month').toUsecDate();
      endTimeUsecs = endTimeUsecs || moment().endOf('month').toUsecDate();

      // Use this map to avoid loading snapshots for the same month twice
      result.loadedSnapshotsMap = result.loadedSnapshotsMap || {};
      key = [startTimeUsecs, endTimeUsecs].join();

      if (result.loadedSnapshotsMap[key]) {
        // Snapshots for this month are already loaded.
        return;
      }

      // Build an array of all snapshots
      result.snapshots = result.snapshots || [];
      result.loadingCalendar = true;

      // Map to maintain all the valid dates (keys) for the calendar. Also
      // used with c-snapshot-picker to show valid previous and next dates.
      result.usableDatesMap = result.usableDatesMap || {};

      // Promise to get all snapshots for a given month
      jobRunsPromise = JobRunsService.getJobRuns({
        excludeTasks: true,
        entityId: result._entityIds,
        startTimeUsecs: startTimeUsecs,
        endTimeUsecs: endTimeUsecs,
      });

      jobRunsPromise.then(function handleJobRunsResponse(jobRuns) {
        // Build an array of all job runs
        var allRuns = jobRuns.reduce(
          function handleResponseItem(result, responseItem) {
            return result.concat(responseItem.backupJobRuns.protectionRuns);
          }, []
        );
        var timestamp;

        // Loop through each job run to generate snapshot items
        allRuns.forEach(function handleRun(run) {
          // This object has most of the information needed for a snapshot
          var backupRunBase = run.backupRun.base;

          // Attach replication/copy info with actions of each item to the
          // backup run object
          backupRunBase.replicaInfo = {
            replicaVec: run.copyRun.finishedTasks.map(
              function handleFinishedTask(finishedTask) {
                // Build an object which _getSnapshotContextAction can take
                // as input
                var snapshotItem = {
                  protectionJobRunUid: {
                    jobUid: assign(backupRunBase.jobUid, {
                      id: backupRunBase.jobUid.objectId,
                    }),
                    runStartTimeUsecs: backupRunBase.startTimeUsecs,
                  },
                  jobRunId: backupRunBase.jobInstanceId,
                  copyRun: {
                    target: finishedTask.snapshotTarget,
                  },
                  backupRun: {
                    currentSnapshotInfo: {
                      environment: result.source.environment,
                    }
                  },
                  uuid: result.uuid,
                };
                var archivalTarget =
                  snapshotItem.copyRun.target.archivalTarget;

                if (archivalTarget) {
                  // If the snapshot was additionally copied to a non local
                  // (remote, cloud, etc.) location.
                  assign(archivalTarget, {
                    vaultId: archivalTarget.vaultId,
                    vaultName: archivalTarget.name,
                    vaultType: ENUM_ARCHIVAL_TARGET[archivalTarget.type],
                  });
                }

                return {
                  target: assign(finishedTask.snapshotTarget, {
                    actions: _getSnapshotContextAction(snapshotItem, result),
                  }),
                };
              }
            ),
          };

          // Build the snapshot version object which c-snapshot-picker can use
          var version = assign(backupRunBase, {
            _startTimeMsecs: DateTimeService.usecsToMsecs(
              backupRunBase.startTimeUsecs
            ),
            entityId: result._entityIds,
            jobRunId: backupRunBase.jobInstanceId,
            jobUid: assign(backupRunBase.jobUid, {
              id: backupRunBase.jobUid.objectId,
            }),
            instanceId: {
              jobInstanceId: backupRunBase.jobInstanceId,
            },
            jobRunStartTime: backupRunBase.startTimeUsecs,
            snapshotsDeleted: !!run.backupRun.snapshotsDeleted,
          });

          versions.push(version);
        });

        // Filter out unrecoverable versions
        versions = RestoreService.getRestorableVersions(versions);

        versions.forEach(function handleVersion(version) {
          // Extract which date this snapshot belongs to
          timestamp = moment(
            DateTimeService.usecsToMsecs(version.startTimeUsecs)
          ).startOf('day').valueOf();

          // Update usableDatesMap for this date
          result.usableDatesMap[timestamp] = true;

          // Add the version to snapshots array used by c-snapshot-picker
          result.snapshots.push({restoreInfo: version});
        });

        // Options for uib-datepicker
        result.pickerOptions = {
          dateDisabled: function dateDisabled(date) {
            // If the date is not in the usableDatesMap, no snapshots exist
            // for that date, and it should not be   selectable.
            return !result.usableDatesMap[
              moment(date.date).startOf('day').valueOf()
            ];
          },

          // Show abbreviated three character month and full year (MMM YYYY)
          // over the default full month and full year as title.
          formatDayTitle: LOCALE_SETTINGS.uibBootstrapDatePickerDayTitleFormat,
          maxMode: 'day',
          minMode: 'day',
          mode: 'day',
          initDate: new Date(startTimeUsecs / 1000),
          ngModelOptions: {
            allowInvalid: false,
          },

          // Custom options for uib-datepicker

          // Going to current month requires knowing current year and month,
          // this is not available in uib-datepicker and thus passed in options.
          currentMonth: new Date().getMonth(),
          currentYear: new Date().getFullYear(),

          // Only show the current month in calendar
          showCurrentMonth: true,

          // Button to go to the current month as per UX
          showTodayButton: true,
        }}, evalAJAX.errorMessage
      ).finally(function jobsRunFinally() {
        result.loadedSnapshotsMap[key] = true;
        result.loadingCalendar = false;
      });
    }

    /**
     * Function to decorate the selected global search result with data required
     * to show the recovery/clone calendar and c-snapshot-picker.
     *
     * @method   _addCalendarDetailsToResult
     * @param    {object}   result   The result to add snapshot info to
     */
    function _addCalendarDetailsToResult(result) {
      // Generate array of all entityIds of this result
      var entityIds = reduce(
        result.protectionSourceUidList,
        function parseProtectionSourceUid(accumulator, protectionSourceUid) {
          if (result._clusterIds.includes(protectionSourceUid.clusterId)) {
            accumulator.push(protectionSourceUid.sourceId);
          }

          return accumulator;
        },
        []
      );

      // Generate array of all jobsIds protecting this result
      var jobIds = map(result.jobs, 'jobId');

      // Add it to the result. c-snapshot-picker uses this to show relevant
      // items.
      result._entityIds = entityIds;
      result._jobIds = jobIds;

      // Decorate result with snapshots and calendar information
      getSnapshotsForTimeRange();
    }

    /**
     * Decorates the provided search result with convenience properties. NOTE:
     * Updates by reference in addition to returning the updated object.
     *
     * @method   _transformSearchResult
     * @param    {object}   result   The result
     * @return   {object}   The decorated result
     */
    function _transformSearchResult(result) {
      var sourceEnv = result.source.environment;
      var typedProtectionSource = SOURCE_KEYS[sourceEnv];
      var protectionSource = result.source[typedProtectionSource];

      return assign(result, {
        _sourceEnv: sourceEnv,
        _sourceType: !!protectionSource && protectionSource.type,
        _protectionSource: protectionSource,
        _clusterIds: map(result.protectionSourceUidList, 'clusterId'),
        _isProtected: !!get(result, 'jobs.length'),
        _isSystemDatabase: result.source.environment === 'kSQL' &&
          /^(master|msdb|model)$/i.test(
            get(result.source, 'sqlProtectionSource.databaseName')
          ),
      });
    }

    /**
     * Transforms API provided snapshot info into a more UI friendly structure.
     *
     * @method   _transformSnapshotInfo
     * @param    snapshotInfo   snapshotInfo{} as provided by API response
     * @param    result         The result to which snapshotInfo belongs
     */
    function _transformSnapshotInfo(snapshotInfo, result) {
      var totalSnapshots;

      // When there are no snapshots, backend will send null which becomes a
      // string. In such cases, make it an empty object instead.
      snapshotInfo = typeof snapshotInfo === 'string' ? {} : snapshotInfo;

      // Giving these main keys more convenient/readable names for output and
      // putting them in an isolated location for looping purposes.
      snapshotInfo._snapshotLists = {
        local: snapshotInfo.backupRuns || [],
        remote: snapshotInfo.replicationRuns || [],

        // NOTE: Archival also includes tape, but its getting lumped in
        // together. It should really be separated out into its own set.
        cloud: snapshotInfo.archivalRuns || [],
      };

      totalSnapshots = snapshotInfo._snapshotLists.local.length +
        snapshotInfo._snapshotLists.remote.length +
        snapshotInfo._snapshotLists.cloud.length;

      snapshotInfo._hasSnapshots = !!totalSnapshots;

      forEach(snapshotInfo._snapshotLists,
        function handleSnapshotInfoGroup(grouping) {
          grouping.forEach(function passThrough(snapshotItem) {
            snapshotItem._actions = _getSnapshotContextAction(
              snapshotItem.latestSnapshotInfo, result
            );
          });
        }
      );

      return snapshotInfo;
    }

    /**
     * Decorates a snapshot object with actions for user interaction.
     *
     * @method _getSnapshotContextAction
     * @param {object} snapshotItem   specific snapshot detail item
     * @param {object} result         The result to which snapshotItem belongs
     */
    function _getSnapshotContextAction(snapshotItem, result) {
      var source = result.source;
      var environment = source.environment;
      var target;

      var cid = get(snapshotItem, 'protectionJobRunUid.jobUid.clusterId', 0);
      var jobId = get(snapshotItem, 'protectionJobRunUid.jobUid.id', 0);
      var runId = get(snapshotItem, 'protectionJobRunUid', 0);
      var ids = find(result.protectionSourceUidList, { clusterId: cid });

      // Add actions[] before any early exit, as its presence is required for
      // cContextMenu disabled state implementation.
      var actions = [];
      var recoverStateName;
      var recoverTextKey;
      var cloneStateName;
      var cloneTextKey;
      var toStateParams;

      // If we don't have ids then there's really no point.
      if (!ids) { return; }

      toStateParams = {
        cid: ids.clusterId,
        entityId: ids.sourceId,
        jobInstanceId: snapshotItem.jobRunId,
        jobId: runId.jobUid.id,
        jobRunStartTime: runId.startTimeUsecs || runId.runStartTimeUsecs,
        jobUid: runId.jobUid,
      };

      // If copyRun{} is present then this is an archival item and vault related
      // parameters should be added.
      if (snapshotItem.copyRun) {
        target = get(snapshotItem.copyRun, 'target', {});

        if (target.archivalTarget) {
          toStateParams = merge(toStateParams, {
            vaultId: target.archivalTarget.vaultId,
            vaultName: target.archivalTarget.vaultName,
            vaultType: target.archivalTarget.vaultType,

            // Retain archivalTarget
            archiveTarget: _getArchiveTarget(target)
          });
        }
      }

      if (!$rootScope.user.privs.RESTORE_MODIFY) {
        return;
      }

      switch(true) {
        // TODO: cloudSources need volume mount implementation but we are
        // lacking some data (see _run.js generateActionItems())
        // add ENV_GROUPS.cloudSources.includes(environment).. requires
        // runTask.base.type in order to determining if "isNativeSnapshotJob":
        // var isNativeSnapshotJob =
        //   ENV_GROUPS.nativeSnapshotTypes.includes(runTask.base.type);
        case environment === 'kPhysical':
          // Mount point recovery isn't supported for kPhysicalFiles.
          if (get(snapshotItem,
            'backupRun.currentSnapshotInfo.environment'
            ) !== 'kPhysicalFiles') {
            recoverStateName = 'recover-mount-point.options';
            recoverTextKey = 'instantVolumeMount';
          }
          break;

        case environment === 'kView':
          cloneStateName = 'clone-view.options';
          toStateParams.protected = true;
          toStateParams.snapshotUsecs =
            runId.runStartTimeUsecs;

          // TODO: `source` might need to be converted to private struct.
          toStateParams.view = source;
          break;

        // All hypervisors with the exception of cloud sources (which recover
        // via instant volume mount)
        case ENV_GROUPS.hypervisor.includes(environment) &&
          !ENV_GROUPS.cloudSources.includes(environment):
          recoverStateName = 'recover-vm.recover-options';

          if (_doesHypervisorSupportCloning(environment)) {
            cloneStateName = 'clone-vms.clone-options';
          }
          break;

        case environment === 'kPure':
          recoverStateName = FEATURE_FLAGS.restoreStorageVolume ?
          'recover-storage-volume.pure-options' :
          'recover-pure.options';
          break;

        case ENV_GROUPS.nas.includes(environment):
          recoverStateName = FEATURE_FLAGS.restoreStorageVolume ?
            'recover-storage-volume.nas-options' :
            'recover-nas.options';
          break;

        case ENV_GROUPS.databaseSources.includes(environment):
          recoverStateName = 'recover-db.options';

          if ((environment === 'kOracle' && FEATURE_FLAGS.oracleClone) ||
            (environment === 'kSQL' &&
            get(DB_JOBS_HASH_CACHE[jobId], '_envParams.backupType') !== 'kSqlNative')) {
            cloneStateName = 'clone-db.options';
          }

          toStateParams.entityId = ids.sourceId;
          toStateParams.dbType = ENV_TO_DBTYPE[environment];
      }

      if (recoverStateName) {
        actions.push({
          icon: 'icn-recover',
          translateKey: recoverTextKey || 'recover',
          state: recoverStateName,
          stateParams: toStateParams,
          action: function trackRecover() {
            // NOTE: no longer tracking. leaving the method empty to avoid
            // possible side effects
          },
        });
      }

      if (SearchService.getBrowsableEnvironments().includes(environment)) {
        var stateParams = {
          cid: ids.clusterId,
          browseSourceId: ids.sourceId,
          browseSourceName: source.name,

          // TODO(Sam): All of this custom logic needs to be thrown away.
          // Disable opening file browse modal when iframe is in play.
          // Since the transition happens before the iframe is loaded.
          // Doing checks in state guard won't help.
          disableFileBrowseModal:
            NgMcmViewService.isClusterLoadedinIFrame(ids.clusterId),
        };

        if (environment === 'kPhysical') {
          // Both the environments needs to be passed here otherwise
          // v2 protected objects doesnt return the object.
          stateParams.environments = ['kPhysical', 'kPhysicalFiles'];
        }

        actions.push({
          translateKey: 'recoverFiles',
          icon: 'icn-recover',
          state: 'recover-files.search',
          stateParams: stateParams,
        });
      }

      if (cloneStateName) {
        actions.push({
          icon: 'icn-clone',
          translateKey: cloneTextKey || 'clone',
          state: cloneStateName,
          stateParams: toStateParams,
          action: function trackClone() {
            // NOTE: no longer tracking. leaving the method empty to avoid
            // possible side effects
          },
        });
      }

      if (FEATURE_FLAGS.databaseMigration &&
        environment === 'kSQL' &&
        !source._isSystemDatabase &&
        !source._isAagMember &&
        get(DB_JOBS_HASH_CACHE[jobId], '_envParams.backupType') === 'kSqlVSSFile') {
        actions.push({
          icon: 'icn-migrate',
          translateKey: 'migrateDatabase',
          state: 'migrate-db.options',
          stateParams: toStateParams,
        });
      }

      return actions;
    }

    /**
     * Indicates if the provided hypervisor environment supports cloning.
     *
     * @method   _doesHypervisorSupportCloning
     * @param   {string}  environment   The hypervisor environment to check.
     * @return  {boolean} true if cloning is supported, false if not.
     */
    function _doesHypervisorSupportCloning(environment) {
      if (!ENV_GROUPS.hypervisor.includes(environment)) {
        // not hypervisor.
        return false;
      }
      switch (true) {
        case environment === 'kAcropolis':
          return $rootScope.FEATURE_FLAGS.acropolisClone;
      }
      return true;
    }

    /**
     * Clears any cached data related to global search.
     *
     * @method _clearSearchCache
     */
    function _clearSearchCache() {
      currentQuery = '';
      currentResults.length = 0;
      selectedResult = undefined;
      DB_JOBS_HASH_CACHE = {};
    }

    /**
     * Get archive target.
     *
     * @method   _getArchiveTarget
     * @param    {object}   target   ArchiveTarget
     * @return   {object}   The archival target to provide to clone view
     */
    function _getArchiveTarget(target) {
      var archivalTarget = get(target, 'archivalTarget');

      return archivalTarget ? {
        target: {
          archivalTarget: {
            // Name of Archival Vault
            name: archivalTarget.vaultName,

            // Type of the Archival External Target
            type:  ENUM_ARCHIVAL_TARGET[archivalTarget.vaultType],

            // Id of Archival Vault
            vaultId: archivalTarget.vaultId,
          },
          type: SNAPSHOT_TARGET_TYPE[target.type] || 1,
        }
      } : undefined;
    }

    /**
     * Fetches a list of DB jobs and caches them.
     *
     * @method   cacheDbJobs
     */
    function cacheDbJobs() {
      return PubJobService.getJobs({
        // We are interested in caching these DB environments as its necessary
        // to lookup certain params from their jobs config in order to determine
        // which action a user can take on their snapshots.
        environments: ['kSQL', 'kOracle'],
        onlyReturnBasicSummary: true,
        includeLastRunAndStats: false,
        pruneExcludedSourceIds: true,
      }).then(function setJobsCacheFn(jobs) {
        DB_JOBS_HASH_CACHE = keyBy(jobs, 'id');
      });
    }
  }

})(angular);
