import { envGroups, Environment } from 'src/app/shared';
import { ProtectionFlowConfig } from '../models/protection-flow-config.model';

/**
 * Array of all environments which have an NG protection group state. No
 * environment should appear in the `environments` section more than once.
 */
export const ProtectionFlowConfigs: ProtectionFlowConfig[] = [
  {
    environments: [Environment.kAD],
    featureFlag: 'ngProtectionGroupAdModify',
    name: 'active-directory',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickAdModify',
    quickProtectComponentId: 'ad-quick-protection-dialog',
  },
  {
    environments: [Environment.kCassandra],
    featureFlag: 'ngProtectionGroupCassandraModify',
    name: 'cassandra',
  },
  {
    environments: [Environment.kHBase],
    featureFlag: 'ngProtectionGroupHBaseModify',
    name: 'hbase',
  },
  {
    environments: [Environment.kHdfs],
    featureFlag: 'ngProtectionGroupHdfsModify',
    name: 'hdfs',
  },
  {
    environments: [Environment.kHive],
    featureFlag: 'ngProtectionGroupHiveModify',
    name: 'hive',
  },
  {
    environments: [Environment.kPure],
    featureFlag: 'ngProtectionGroupPureModify',
    name: 'pure',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickPureModify',
    quickProtectComponentId: 'pure-quick-protection-dialog',
  },
  {
    environments: [Environment.kIbmFlashSystem],
    featureFlag: 'ibmFlashSystemEnabled',
    name: 'pure',
    quickProtectFeatureFlag: 'ibmFlashSystemEnabled',
    quickProtectComponentId: 'pure-quick-protection-dialog',
  },
  {
    environments: [Environment.kSQL],
    featureFlag: 'ngProtectionGroupSqlModify',
    name: 'sql',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickSqlModify',
    quickProtectComponentId: 'sql-quick-protection-dialog',
  },
  {
    environments: [Environment.kView],
    featureFlag: 'ngProtectionGroupViewModify',
    name: 'view',
  },
  {
    environments: envGroups.nas,
    featureFlag: 'ngProtectionGroupNasModify',
    name: 'nas',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickNasModify',
    quickProtectComponentId: 'nas-quick-protection-dialog',
  },
  {
    environments: envGroups.vms,
    featureFlag: 'ngProtectionGroupVmModify',
    name: 'vm',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickVmModify',
    quickProtectComponentId: 'vm-quick-protection-dialog',
    dmsPgFeatureFlag: 'ngDmsProtectionGroupVm',
  },
  {
    environments: [Environment.kAuroraSnapshotManager, Environment.kRDSSnapshotManager],
    featureFlag: 'ngProtectionGroupAwsRdsModify',
    name: 'aws-rds',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickAwsRdsModify',
    quickProtectComponentId: 'aws-rds-quick-protection-dialog',
    supportedScopes: ['dms'],
  },
  {
    environments: [Environment.kAwsDynamoDB],
    featureFlag: 'awsDynamoDbEnabled',
    name: 'aws-dynamodb',
    quickProtectFeatureFlag: 'awsDynamoDbEnabled',
    quickProtectComponentId: 'aws-db-quick-protection-dialog',
    supportedScopes: ['dms'],
  },
  {
    environments: [Environment.kAzureSQL],
    featureFlag: 'ngProtectionGroupAzureSqlModify',
    name: 'azure-sql',
    quickProtectFeatureFlag: 'ngProtectionGroupAzureSqlModify',
    quickProtectComponentId: 'azure-sql-quick-protection-dialog',
    supportedScopes: ['dms'],
    dmsPgFeatureFlag: 'ngProtectionGroupAzureSqlModify',
  },
  {
    environments: [Environment.kAzureEntraID],
    featureFlag: 'dmsAzureEntraIdWorkload',
    name: 'azure-ad',
    quickProtectFeatureFlag: 'dmsAzureEntraIdWorkload',
    quickProtectComponentId: 'azure-ad-quick-protection-dialog',
    dmsPgFeatureFlag: 'dmsAzureEntraIdWorkload',
  },
  {
    environments: [Environment.kAwsS3],
    featureFlag: 's3Protection',
    name: 'aws-s3',
    quickProtectFeatureFlag: 's3Protection',
    quickProtectComponentId: 'aws-s3-quick-protect-dialog',
  },
  {
    environments: [Environment.kPhysicalFiles],
    featureFlag: 'ngProtectionGroupPhysicalFilesModify',
    name: 'physical-files',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickPhysicalModify',
    quickProtectComponentId: 'physical-quick-protection-dialog',
  },
  {
    environments: [Environment.kPhysical],
    featureFlag: 'ngProtectionGroupPhysicalBlockModify',
    name: 'physical-block',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickPhysicalModify',
    quickProtectComponentId: 'physical-quick-protection-dialog',
  },
  {
    environments: [Environment.kRemoteAdapter],
    featureFlag: 'ngProtectionGroupRemoteAdapterModify',
    name: 'remote-adapter',
  },
  {
    environments: [Environment.kO365],
    featureFlag: 'ngProtectionGroupOffice365Modify',
    improveUiResponseFeatureFlag: 'office365ImproveUiScaleEnabled',
    name: 'office365',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickOffice365Modify',
    quickProtectComponentId: 'office365-quick-protection-dialog',
  },
  {
    environments: [Environment.kO365Exchange],
    featureFlag: 'ngProtectionGroupOffice365Modify',
    improveUiResponseFeatureFlag: 'office365ImproveUiScaleEnabled',
    name: 'office365',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickOffice365Modify',
    quickProtectComponentId: 'office365-quick-protection-dialog',
  },
  {
    environments: [Environment.kO365ExchangeCSM],
    featureFlag: 'ngProtectionGroupOffice365Modify',
    improveUiResponseFeatureFlag: 'office365ImproveUiScaleEnabled',
    name: 'office365',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickOffice365Modify',
    quickProtectComponentId: 'office365-quick-protection-dialog',
  },
  {
    environments: [Environment.kO365OneDrive],
    featureFlag: 'ngProtectionGroupOffice365Modify',
    improveUiResponseFeatureFlag: 'office365ImproveUiScaleEnabled',
    name: 'office365',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickOffice365Modify',
    quickProtectComponentId: 'office365-quick-protection-dialog',
  },
  {
    environments: [Environment.kO365OneDriveCSM],
    featureFlag: 'ngProtectionGroupOffice365Modify',
    improveUiResponseFeatureFlag: 'office365ImproveUiScaleEnabled',
    name: 'office365',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickOffice365Modify',
    quickProtectComponentId: 'office365-quick-protection-dialog',
  },
  {
    environments: [Environment.kO365Sharepoint],
    featureFlag: 'ngProtectionGroupOffice365Modify',
    improveUiResponseFeatureFlag: 'office365ImproveUiScaleEnabled',
    name: 'office365',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickOffice365Modify',
    quickProtectComponentId: 'office365-quick-protection-dialog',
  },
  {
    environments: [Environment.kO365SharepointCSM],
    featureFlag: 'ngProtectionGroupOffice365Modify',
    improveUiResponseFeatureFlag: 'office365ImproveUiScaleEnabled',
    name: 'office365',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickOffice365Modify',
    quickProtectComponentId: 'office365-quick-protection-dialog',
  },
  {
    environments: [Environment.kO365Teams],
    featureFlag: 'ngProtectionGroupOffice365Modify',
    improveUiResponseFeatureFlag: 'office365ImproveUiScaleEnabled',
    name: 'office365',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickOffice365Modify',
    quickProtectComponentId: 'office365-quick-protection-dialog',
  },
  {
    environments: [Environment.kO365Group],
    featureFlag: 'ngProtectionGroupOffice365Modify',
    improveUiResponseFeatureFlag: 'office365ImproveUiScaleEnabled',
    name: 'office365',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickOffice365Modify',
    quickProtectComponentId: 'office365-quick-protection-dialog',
  },
  {
    environments: [Environment.kO365PublicFolders],
    featureFlag: 'ngProtectionGroupOffice365Modify',
    improveUiResponseFeatureFlag: 'office365ImproveUiScaleEnabled',
    name: 'office365',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickOffice365Modify',
    quickProtectComponentId: 'office365-quick-protection-dialog',
  },
  {
    environments: [Environment.kO365Outlook],
    featureFlag: 'ngProtectionGroupOffice365Modify',
    name: 'office365',
  },
  {
    environments: [Environment.kOracle],
    featureFlag: 'ngProtectionGroupOracleModify',
    name: 'oracle',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickOracleModify',
    quickProtectComponentId: 'oracle-quick-protection-dialog',
  },
  {
    environments: [Environment.kRDSSnapshotManager],
    featureFlag: 'ngProtectionGroupRdsModify',
    name: 'rds',
  },
  {
    environments: [Environment.kAuroraSnapshotManager],
    featureFlag: 'ngProtectionGroupAuroraModify',
    name: 'aurora',
  },
  {
    environments: [Environment.kExchange],
    featureFlag: 'ngProtectionGroupExchangeModify',
    name: 'exchange',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickExchangeModify',
    quickProtectComponentId: 'exchange-quick-protection-dialog',
  },
  {
    environments: [Environment.kKubernetes],
    featureFlag: 'ngProtectionGroupKubernetesModify',
    name: 'kubernetes',
  },
  {
    environments: [Environment.kMongoDB],
    featureFlag: 'ngProtectionGroupMongoDBModify',
    name: 'mongodb',
  },
  {
    environments: [Environment.kMongoDBPhysical],
    featureFlag: 'mongodbPhysicalProtection',
    name: 'mongodb-physical',
  },
  {
    environments: [Environment.kCouchbase],
    featureFlag: 'ngProtectionGroupCouchbaseModify',
    name: 'couchbase',
  },
  {
    environments: [Environment.kUDA],
    featureFlag: 'ngProtectionGroupUdaModify',
    name: 'uda',
  },
  {
    environments: envGroups.saphana,
    featureFlag: 'sapHanaEnabledDms',
    name: 'saphana',
    quickProtectFeatureFlag: 'sapHanaEnabledDms',
    quickProtectComponentId: 'saphana-quick-protection-dialog',
    dmsPgFeatureFlag: 'sapHanaEnabledDms',
  },
  {
    environments: [Environment.kSfdc],
    featureFlag: 'ngProtectionGroupQuickSfdcModify',
    name: 'sfdc',
    quickProtectFeatureFlag: 'ngProtectionGroupQuickSfdcModify',
    quickProtectComponentId: 'sfdc-quick-protection-dialog',
    dmsPgFeatureFlag: 'ngDmsProtectionGroupSfdc',
  },
];
