import { isFinite } from 'lodash-es';
import { isBoolean } from 'lodash-es';
import { clamp } from 'lodash-es';
import { isString } from 'lodash-es';
import { get } from 'lodash-es';
// MODULE:  Cohesity AngularJS filters

import { OwnershipContext } from "src/app/shared";

;(function(angular, undefined) {
  'use strict';

  // _constants is used by cCONST to find constants defined in both AJS/Angular.
  // Delay initialization of _constants in _getKeyValue() because if it is
  // initialized here, the constants defined in typescript/angular is not yet
  // available and causes cCONST filter not working because of missing constants
  var _constants;

  // The following arrays are alpha sorted so we can use angular.equals for
  // comparisons
  var weekdayList = [
    'kFriday',
    'kMonday',
    'kThursday',
    'kTuesday',
    'kWednesday',
  ];
  var weekendList = [
    'kSaturday',
    'kSunday',
  ];

  /**
   * Cache of translated strings for use in filters. Each filter that uses
   * translation is responsible for updating this cache.
   *
   * @type   {object}
   */
  var stringsCache = {};

  /**
   * Placeholder for the $translate service once updated in the module.run()
   * routine.
   *
   * @type   {object}
   */
  var _$translate;
  var _cUtils;

  angular
    .module('C.filters', ['ngSanitize', 'C.utils', 'C.NgService'])
    .run(FiltersRun)
    .filter('aagHostIconClass', FilterAagHostIconClass)
    .filter('aagName', FilterAagName)
    .filter('archivalTargetIcon', FilterArchivalTargetIcon)
    .filter('assembleTimeString', FilterAssembleTimeString)
    .filter('backupType', BackupTypeFn)
    .filter('boolLabel', BoolLabelFn)
    .filter('byteSize', byteSizeFilter)
    .filter('byteSizeGb', byteSizeFilterGb)
    .filter('byteUsedOfTotal', byteUsedOfTotalFilter)
    .filter('capitalize', FilterCapitalize)
    .filter('cCONST', FilterConstants)
    .filter('cloudSubscriptionType', FilterCloudSubscriptionType)
    .filter('dateToDatePickerFo rmat', FilterDateToDatePickerFormat)
    .filter('dateToDayOfWeek', FilterDateToDayOfWeek)
    .filter('dateToUsecs', FilterDateToUsecs)
    .filter('displayAlertThreshold', FilterDisplayAlertThreshold)
    .filter('enabledDisabled', enabledDisabledFn)
    .filter('enquote', enquoteFn)
    .filter('entityIconClass', FilterEntityIconClass)

    /**
     * NOTE: $filter:entityName is deprecated. Please use {{entity.displayName}}
     * whenever possible. This is being kept, however, for the odd cases when
     * an EntityProto does not yet have the `displayName` property, for which a
     * bug should be filed with Magneto to correct.
     */
    .filter('entityName', FilterEntityName)
    .filter('entityType', FilterEntityType)
    .filter('environmentIconClass', FilterEnvironmentIconClass)
    .filter('environmentName', FilterEnvironmentName)
    .filter('fileName', FilterFileName)
    .filter('filterLocalized', FilterFilterLocalized)
    .filter('fromUsecs', FilterfromUsecs)
    .filter('highlighter', FilterHighlight)
    .filter('hostType', FilterHostType)
    .filter('humanizeArrayOfDays', FilterHumanizeArrayOfDays)
    .filter('humanizeDuration', FilterHumanizeDuration)
    .filter('humanizeLargeNumber', FilterHumanizeLargeNumber)
    .filter('ordinaryDayCount', FilterOrdinaryDayCount)
    .filter('humanizeMsecsDiff', FilterHumanizeMsecsDiff)
    .filter('idScrub', FilterIdScrub)
    .filter('ifaceGroupName', FilterIfaceGroupName)
    .filter('ioRateString', FilterIoRateString)
    .filter('jobRun', FilterJobRun)
    .filter('msecsToDate', FilterMsecsToDate)
    .filter('msecsToDuration', FilterMsecsToDuration)
    .filter('naFilter', FilterNotAvailable)
    .filter('o365Username', FilterO365Username)
    .filter('o365MailboxIdentifier', FilterO365MailboxIdentifier)
    .filter('o365DomainIdentifier', FilterO365DomainIdentifier)
    .filter('onOff', OnOffFn)
    .filter('pad', FilterPad)
    .filter('percentageCompleted', FilterPercentageCompleted)
    .filter('policyType', FilterPolicyType)
    .filter('principalIcon', FilterPrincipalIcon)
    .filter('principalName', FilterPrincipalName)
    .filter('protectedIcon', FilterProtectedIcon)
    .filter('publicStatus', PublicStatus)
    .filter('publicStatusClass', PublicStatusClass)
    .filter('replaceString', ReplaceString)
    .filter('restoreObjectName', restoreObjectNameFilter)
    .filter('s3KeyMapping', s3KeyMappingFilterFn)
    .filter('secsDuration', FilterSecsDuration)
    .filter('secsToDate', FilterSecsToDate)
    .filter('secsToTime', FilterSecsToTime)
    .filter('simpleList', FilterSimpleList)
    .filter('simpleListConstants', FilterSimpleListConstants)
    .filter('simpleListTranslated', FilterSimpleListTranslated)
    .filter('snapshotTargetIcon', FilterSnapshotTargetIcon)
    .filter('snapshotTargetType', FilterSnapshotTargetType)
    .filter('sourceIcon', FilterSourceIcon)
    .filter('sourceType', FilterSourceType)
    .filter('sqlDbName', FilterSQLDbName)
    .filter('storageSnapshotUsed', FilterStorageSnapshotUsed)
    .filter('tenantId', FilterTenantId)
    .filter('timeDuration', FilterTimeDuration)
    .filter('timeObjectToDate', FilterTimeObjectToDate)
    .filter('timeRemaining', FilterTimeRemaining)
    .filter('timeRange', FilterTimeRange)
    .filter('timezoneAbbreviation', FilterTimezoneAbbreviation)
    .filter('transferRateBytes', FilterTransferRateBytes)
    .filter('usecsDuration', FilterUsecsDuration)
    .filter('usecsToDate', FilterUsecsToDate)
    .filter('usecsToTime', FilterUsecsToTime)
    .filter('viewBox', FilterViewBox)
    .filter('viewBoxCompression', viewBoxCompressionFilterFn)
    .filter('viewBoxDedupeStatus', viewBoxDedupeStatusFilterFn)
    .filter('viewBoxEncryption', viewBoxEncryptionFilterFn)
    .filter('viewBoxFaultTolerance', viewBoxFaultToleranceFilterFn)
    .filter('viewBoxRedundancy', viewBoxRedundancyFilterFn)
    .filter('viewDeduplication', viewDeduplicationFilter)
    .filter('viewProtocols', viewProtocolsFilter)
    .filter('viewSecurityMode', viewSecurityModeFilter)
    .filter('viewWithViewBoxDedupe', viewWithViewBoxDedupeFilter)
    .filter('viewReductionRatio', viewReductionRatioFilter)
    .filter('viewDedupeRatio', viewDedupeRatioFilter)
    .filter('wrapUnits', FilterWrapUnitsFn)
    .filter('yesNo', YesNoFn)
    .filter('removeSpaces', FilterRemoveSpaces);

  /**
   * Run routine for $filters setup
   *
   * @param   {object}   $injector   $injector service.
   */
  function FiltersRun($injector) {
    /**
     * Update the locally scoped reference to $translate for use in the
     * `translateString()` abstraction, which is not a filter itself. The
     * alternative is to inject $filter into every filter to access $translate
     * that way.
     */
    _$translate = $injector.get('$translate');
    _cUtils = $injector.get('cUtils');
  }

  /**
   * Parses the _invokeQueue array on the main app module for constants
   * and populates a simpler flat constants hash for use by ngFilters.
   *
   * @method    _getConstants
   * @returns   {object}   Hash of discovered Constants.
   */
  function _getConstants() {
    return angular.module('C.constants')._invokeQueue.reduce(
      function eachConstant(consts, qItem, ii) {
        if (qItem.length > 2 && qItem[1] == 'constant') {
          consts[qItem[2][0]] = qItem[2][1];
        }

        return consts;
      },

      {}
    );
  }

  /**
   * String translator. Handles caching translated strings internally.
   *
   * @method   translateString
   * @param    {string}   translateKey   The translation library key.
   * @param    {boolean}  skipSanitize   If true, the translation string will
   *                                     not be escaped.
   * @return   {string}   The translated string.
   */
  function translateString(translateKey, skipSanitise) {
    // if skipSanitize is true, use 'null' sanitization strategy.
    // Since it is the 5th parameter passed to instant() method, fill the
    // preceding arguments also with null
    var translateFnArgs = skipSanitise ?
      [translateKey, null, null, null, null] : [translateKey];

    if (!stringsCache[translateKey]) {
      stringsCache[translateKey] =
        _$translate.instant.apply(null, translateFnArgs);
    }

    return stringsCache[translateKey];
  }

  /**
   * @ngdoc   filter
   * @name    C.filters.naFilter
   *
   * @description
   *   Format the provided value to NA if value is null, undefined, empty array
   *   or empty object else return the original value.
   *
   * @example
   *   {{(null || undefined) | naFilter}}        // "-"
   *   {{([] || {})          | naFilter}}        // "-"
   *   {{[...]               | naFilter}}        // [...]
   *   {{{a: 11, b: 22}      | naFilter}}        // {a: 11, b: 22}
   *   {{'hello world'       | naFilter}}        // "hello world"
   *
   *   {{true    | naFilter}}                    // true
   *   {{false   | naFilter}}                    // false
   *   {{'true'  | naFilter}}                    // 'false'
   *   {{'false' | naFilter}}                    // 'false'
   *
   *   {{0       | naFilter}}                    // 0
   *   {{1       | naFilter}}                    // 1
   *   {{'0'     | naFilter}}                    // "0"
   *   {{'1'     | naFilter}}                    // "1"
   *
   * @param    {any}    value    The value to test
   * @return   {any}       Return the formatted result
   */
  function FilterNotAvailable(NaLabelPipeService) {
    return function naFilter(value) {
      return NaLabelPipeService.transform(value);
    };
  }

  /**
   * @ngdoc   filter
   * @name    C.filters.timeRange
   *
   * @description
   *  Formats the input start and end timestamp in 'hh:mm A' time format and returns
   *  'startTime - endTime'.
   *
   * @example
   *    {{ "2020-10-08T16:00:48.475Z" | timeRange: "2020-10-09T00:00:48.475Z" }} // 09:00 AM - 05:00 PM
   *
   * @param {date} startTime "2020-10-08T16:00:48.475Z"
   * @param {date} endTime "2020-10-09T00:00:48.475Z"
   * @return {string} Return formatted time range
   */
  function FilterTimeRange(TimeRangePipeService) {
    return function timeRange(startTime, endTime) {
      return TimeRangePipeService.transform(startTime, endTime);
    };
  }

  /**
   * @ngdoc   filter
   * @name    C.filters.sqlDbName
   *
   * @description
   *   Outputs the combined isntance+database names from a given SQL entity.
   *
   * @example
   *   {{::appEntity.sqlEntity | sqlDbName}}      --> "INSTANCENAME/DatabaseName"
   *   {{::appEntity.sqlEntity | sqlDbName:true}} --> "DatabaseName"
   *
   * @param    {object}    sqlEntity    SQL EntityProto.
   * @param    {boolean}   [nameOnly]   Only output the database name alone.
   * @return   {string}    Output of combined name.
   */
  function FilterSQLDbName() {
    return function sqlDbName(sqlEntity, nameOnly) {
      if (!sqlEntity || !sqlEntity.databaseName) {
        return sqlEntity;
      }

      if (nameOnly) {
        return sqlEntity.databaseName;
      }

      return [ sqlEntity.instanceName, sqlEntity.databaseName ].join('/');
    };
  }

  /**
   * @ngdoc   filter
   * @name C.filters.storageSnapshotUsed
   *
   * @description
   *    Return the storage snapshot type of the job is using. Might be:
   *    "Yes. Enabled for HyperFlex."
   *    "Yes. Enabled for Nutanix."
   *    "Yes. Enabled for Storage Array."
   *    "No"
   *
   * @example
   *    {{::job | storageSnapshotUsed}}
   */
  function FilterStorageSnapshotUsed($translate) {
    return function storageSnapshotUsed(job) {
      if (job.leverageStorageSnapshots ||
        job.leverageStorageSnapshotsForHyperflex ||
        job.leverageNutanixSnapshots) {
          return $translate.instant('displayLeverageType', {
            name: $translate.instant(_getStorageCarrier(job)),
          });
        } else {
          return $translate.instant('no');
        }
    };
  }

  /**
   * Returns snapshot carrier name based on leverage flags in job.
   *
   * @method    _getStorageCarrier
   *
   * @param     {object}   job    job object.
   * @returns   {string}   Name of the storage carrier.
   */
  function _getStorageCarrier(job) {
    if (job.leverageStorageSnapshotsForHyperflex) {
      return 'hyperFlex';
    }

    if (job.leverageNutanixSnapshots) {
      return 'nutanix';
    }

    return 'storageArraySlashNas';
  }

  /**
   * @ngdoc   filter
   * @name    C.filters.tenantId
   *
   * @description
   *   Outputs formatted tenant id
   *
   * @example
   *   {{::'@coke/hr/department_1/' | tenantId}}     @coke/hr/department_1
   *   {{null | tenantId}}                           -
   *   {{null | tenantId : true}}                    '' // empty string
   *
   * @param    {object}    tenantId    The tenant Id to transform
   * @return   {string}    Output formatted tenant Id
   */
  function FilterTenantId($translate) {
    return function getFormattedTenantId(tenantId, noNullState) {
      if (!tenantId) {
        return noNullState ? '' : translateString('naNotAvailable');
      }

      return '@' + tenantId.slice(0, -1);
    };
  }

  /**
   * @ngdoc filter
   * @name     C.filter.byteSize
   *
   * @param    {Number}    bytes               Number of bytes to format
   * @param    {Boolean}   [wrapUnits=false]   Indicates if the units should be
   *                                           wrapped in styling span. output
   *                                           must be rendered using ngBindHtml
   *                                           or cInterpolate (html-safe
   *                                           output)
   * @param    {Number}    [precision=2]       Decimals to display.
   * @param    {Boolean}   useNaLetters        Use 'N/A' instead of '-'
   * @return   {String}    Filesize formatted string
   */
  function byteSizeFilter($sce, cUtils) {
    return function byteSize(bytes, wrapUnits, precision, useNaLetters) {
      if (bytes < 0) {
        return translateString('naNotAvailable');
      }

      var formattedBytes = cUtils.bytesToSize(bytes, precision, useNaLetters);

      if (wrapUnits) {
        return $sce.trustAsHtml([
          formattedBytes.size,
          ' <span class="stat-value-unit">',
          formattedBytes.unit,
          '</span>',
        ].join(''));
      }

      return formattedBytes.string;

    };
  }

  /**
   * @ngdoc filter
   * @name     C.filter.byteSize
   *
   * @param    {Number}    bytes               Number of Giga bytes to format
   * @param    {Number}    [precision=2]       Decimals to display.
   * @param    {Boolean}   useNaLetters        Use 'N/A' instead of '-'
   * @return   {String}    Filesize formatted string
   */
  function byteSizeFilterGb(cUtils) {
    return function (valueInGb, precision, useNaLetters) {
      return cUtils.bytesToSize(
        valueInGb * Math.pow(2, 30), precision, useNaLetters).string;
    }
  }

  /**
   * @ngdoc filter
   * @name     C.filter.byteUsedOfTotal
   *
   * @example
   * {{1024 | byteUsedOfTotal: 2048}}
   * 1 of 2 KiB
   *
   * {{1024 | byteUsedOfTotal: 1048576}}
   * 1 KiB of 1 MiB
   *
   * @param    {Number}   usedBytes           Used bytes.
   * @param    {Number}   totalBytes          Total bytes.
   * @param    {Boolean}  [wrapUnits=false]   Indicates if the units should be
   *                                          wrapped in styling span. output
   *                                          must be rendered using ngBindHtml
   *                                          or cInterpolate (html-safe output)
   * @param    {Number}   [precision=1]       Decimals to display.
   * @return   {String}    formatted used byte of total string
   */
  function byteUsedOfTotalFilter($sce, $translate, cUtils) {
    return function byteUsedOfTotal(usedBytes, totalBytes, wrapUnits,
      precision) {
      var formattedUsedBytes = cUtils.bytesToSize(usedBytes || 0, precision);
      var formattedTotalBytes = cUtils.bytesToSize(totalBytes || 0, precision);
      var sameUnit = formattedUsedBytes.unit === formattedTotalBytes.unit;
      var used = sameUnit ? formattedUsedBytes.size : formattedUsedBytes.string;

      if (wrapUnits) {
        return $sce.trustAsHtml($translate.instant('nOfTotalTemplate', {
          n: formattedUsedBytes.size,
          nUnit: sameUnit ? undefined : formattedUsedBytes.unit,
          total: formattedTotalBytes.size,
          totalUnit: formattedTotalBytes.unit,
        }));
      }

      return $translate.instant('nOfTotal', {
        n: used,
        total: formattedTotalBytes.string
      });
    };
  }

  /**
   * @ngdoc filter
   * @name viewBoxDedupeStatus
   *
   * @description
   *   Provides a string value representing a ViewBox's dedupe policy
   *   (both post and inline combined). Can handle both type variants ViewBox (v1)
   *   & StorageDomain (v2)
   *
   * @param   {Object}  viewBox  ViewBox Proto
   * @returns {String}           Text value of viewbox dedupe settings
   * @example
    <div ng-repeat="viewBox in viewBoxes">
      View Box: {{viewBox.name}}<br>
      Deduplication: {{viewBox | viewBoxDedupeStatus}}
    </div>
   */
  function viewBoxDedupeStatusFilterFn($rootScope) {
    return function filter(viewBox) {
      var text = $rootScope.text.cFilters;
      const storagePolicy = viewBox?.storagePolicy;
      const deduplicationEnabled = storagePolicy?.deduplicationParams?.enabled ?? storagePolicy?.deduplicationEnabled;
      const inlineDeduplicate = storagePolicy?.deduplicationParams?.inlineEnabled ?? storagePolicy?.inlineDeduplicate;

      if (!storagePolicy) {
        return text.na;
      }

      switch (true) {
        // no dedupe
        case !deduplicationEnabled:
          return text.no;

        // inline
        case (deduplicationEnabled && inlineDeduplicate):
          return text.vbDedupeStatus.inline;

        // post dedupe
        case (deduplicationEnabled && !inlineDeduplicate):
          return text.vbDedupeStatus.post;

        // some other case, fallback for uncertainty
        default:
          return text.na;
      }
    };
  }

  /**
   * @ngdoc filter
   * @name viewBoxRedudancy
   *
   * @description
   *   Provides a string value representing a ViewBox's redundancy settings. Can handle both type variants ViewBox (v1)
   *   & StorageDomain (v2)
   *
   * @param   {Object}  viewBox  View Box Proto
   * @returns {String}           Text value of View Boxes redudancy settings
   * @example
    <div ng-repeat="viewBox in viewBoxes">
      View Box: {{viewBox.name}}<br>
      Deduplication: {{viewBox | viewBoxRedundancy}}
    </div>
   */
  function viewBoxRedundancyFilterFn($rootScope) {
    return function filter(viewBox) {
      const storagePolicy = viewBox?.storagePolicy;
      const erasureCodingEnabled = storagePolicy?.erasureCodingParams?.enabled ?? storagePolicy?.erasureCodingInfo?.erasureCodingEnabled ?? false;
      const numFailuresTolerated = storagePolicy?.numDiskFailuresTolerated ?? storagePolicy?.numFailuresTolerated;
      const numDataStripes = storagePolicy?.erasureCodingParams?.numDataStripes ?? storagePolicy?.erasureCodingInfo?.numDataStripes ?? 0;
      const numCodedStripes = storagePolicy?.erasureCodingParams?.numCodedStripes ?? storagePolicy?.erasureCodingInfo?.numCodedStripes ?? 0;

      if (!storagePolicy) {
        return '';
      }
      if (!erasureCodingEnabled) {
        // Hardcode to RF2 for physical robo clusters(ENG-121282).
        if ($rootScope.isPhysicalRobo) {
          return ('RF 2');
        }
        return ['RF ', numFailuresTolerated + 1].join('');
      }

      return [
        'EC ',
        numDataStripes,
        ':',
        numCodedStripes,
      ].join('');

    };
  }

  /**
   * @ngdoc filter
   * @name viewBoxFaultTolerance
   *
   * @description
   *   Provides a string value representing a View Box fault tolerance settings. Can handle both type variants ViewBox (v1)
   *   & StorageDomain (v2)
   *
   * @param      {Object}    viewBox    View Box data object
   * @returns    {String}               String value of View Box fault tolerance
   *                                    settings
   * @example
    <div ng-repeat="viewBox in viewBoxes">
      Fault Tolerance: {{viewBox | viewBoxFaultTolerance}}
      Fault Tolerance: {{viewBox | viewBoxFaultTolerance:'kChassis'}}
    </div>
   */
  function viewBoxFaultToleranceFilterFn() {
    return function filter(viewBox, type) {
      var faultToleranceLevelMap = {
        kNode: 'N',
        kChassis: 'C',
        kRack: 'R',
      };

      type = type || 'kNode';
      if (type[0] !== 'k') {
        type = 'k' + type;
      }

      const storagePolicy = viewBox?.storagePolicy;
      const numFailuresTolerated = storagePolicy?.numDiskFailuresTolerated ?? storagePolicy?.numFailuresTolerated;
      const numNodeFailuresTolerated = storagePolicy?.numNodeFailuresTolerated;

      if (!viewBox || !viewBox.storagePolicy) {
        return '';
      }

      return numFailuresTolerated + 'D:' + numNodeFailuresTolerated + faultToleranceLevelMap[type];
    };
  }

  /**
   * @ngdoc filter
   * @name viewBoxEncryption
   *
   * @description
   *   Provides a string value representing a ViewBox's encryption settings. Can handle both type variants ViewBox (v1)
   *   & StorageDomain (v2)
   *
   * @param   {Object}  viewBox  ViewBox Proto
   * @returns {String}           Text value of viewbox's encryption setting
   * @example
    <div ng-repeat="viewBox in viewBoxes">
      View Box: {{viewBox.name}}<br>
      Encryption: {{viewBox | viewBoxEncryption}}
    </div>
   */
  function viewBoxEncryptionFilterFn($rootScope) {
    return function filter(viewBox) {
      var text = $rootScope.text.cFilters;
      const storagePolicy = viewBox?.storagePolicy;
      const encryptionPolicy = storagePolicy?.encryptionType ?? storagePolicy?.encryptionPolicy;

      if (!encryptionPolicy) {
        return text.na;
      }

      switch (encryptionPolicy) {
        case 'kEncryptionStrong':
        case 'Strong':
          return text.yes;

        case 'kEncryptionWeak':
        case 'Weak':
          return text.yes;

        case 'kEncryptionNone':
        case 'None':
          return text.no;

        default:
          // some other case, fallback for uncertainty
          return text.na;
      }
    };
  }

  /**
   * @ngdoc filter
   * @name viewBoxCompression
   *
   * @description
   *   Provides a string value representing a ViewBox's compression setting. Can handle both type variants ViewBox (v1)
   *   & StorageDomain (v2)
   *
   * @param   {Object}  viewBox  ViewBox Proto
   * @returns {String}           Text value of viewbox's encryption setting
   * @example
    <div ng-repeat="viewBox in viewBoxes">
      View Box: {{viewBox.name}}<br>
      Compression: {{viewBox | viewBoxCompression}}
    </div>
   */
  function viewBoxCompressionFilterFn($rootScope) {
    return function filter(viewBox) {
      var text = $rootScope.text.cFilters;
      const storagePolicy = viewBox?.storagePolicy;
      const compressionPolicy = storagePolicy?.compressionParams?.type ?? storagePolicy?.compressionPolicy;
      const inlineCompress = storagePolicy?.compressionParams?.inlineEnabled ?? storagePolicy?.inlineCompress;

      if (!compressionPolicy) {
        return text.na;
      }

      switch (true) {
        case (compressionPolicy === 'kCompressionNone' || compressionPolicy === 'None'):
          return text.no;

        case inlineCompress:
          return text.vbCompression.inline;

        default:
          return text.vbCompression.post;
      }
    };
  }

  /**
   * @ngdoc filter
   * @name  viewDeduplication
   *
   * @description
   * Provides a string value representing a View's storage policy override
   * setting (inline dedup override).
   *
   * @param     {Object}    view   object as returned from API
   * @returns   {String}           text value of view dedup policy override setting
   *
   * @example
    <div ng-repeat="view in views">
      view: {{view.name}}<br>
      Deduplication: {{view | viewDeduplication}}
    </div>
   */
  function viewDeduplicationFilter($rootScope) {
    var text = $rootScope.text.cFilters;

    return function viewDeduplication(view) {
      if (!view) {
        return text.na;
      }

      if (view.storagePolicyOverride &&
        view.storagePolicyOverride.disableInlineDedupAndCompression) {
        return text.viewDedupeStatus.postProcessed;
      }

      return text.viewDedupeStatus.inherited;
    };
  }

  /**
   * @ngdoc filter
   * @name  viewSecurityMode
   *
   * @description
   * Provides a string value representing a View's Security Mode setting.
   *
   * @param     {Object}    view   object as returned from API
   * @returns   {String}           text value of view security mode setting
   *
   * @example
    <div ng-repeat="view in views">
      view: {{view.name}}
      securityMode: {{view | viewSecurityMode}}
    </div>
   */
  function viewSecurityModeFilter($log) {
    return function viewSecurityMode(view) {
      if (!view || !view.securityMode) {
        return translateString('naNotAvailable');
      }

      switch (view.securityMode) {
        case 'kNativeMode':
          return translateString('native');

        case 'kUnifiedMode':
          return translateString('unified');

        case 'kNtfsMode':
          return translateString('ntfs');

        default:
          $log.info('No `securityMode` for View: ' + view.name);
          return translateString('naNotAvailable');
      }
    };
  }

  /**
   * @ngdoc filter
   * @name  viewWithViewBoxDedupeFilter
   *
   * @description
   * Provides a string value representing a View's storage policy override
   * setting (inline dedup override).
   *
   * @param     {Object}   view       Object as returned from API.
   * @param     {Object}   viewBox    Object as returned from API.
   * @param     {Boolean}  showSuffix Indicates whether to append with the word
   *                                  'Deduplication'.
   * @returns   {String}              View dedup policy override setting.
   *
   * @example
    <div ng-repeat="view in views">
      view: {{view.name}}<br>
      Deduplication: {{view | viewWithViewBoxDedupe: viewbox}}
    </div>
   */
  function viewWithViewBoxDedupeFilter() {
    return function viewWithViewBoxDedupeFn(view, viewBox, showSuffix) {
      if (!view || view._isInactive) {
        return translateString('naNotAvailable');
      }

      if (view.storagePolicyOverride &&
        view.storagePolicyOverride.disableInlineDedupAndCompression) {
        return translateString(showSuffix ? 'postProcessedDeduplication' :
          'postProcessed');
      }

      if (!viewBox) {
        return translateString('naNotAvailable');
      }

      // If the view does not fall in the above category
      // Inherit the properties from the view box
      if (!viewBox.storagePolicy.deduplicationEnabled) {
        return translateString(showSuffix ? 'noDeduplication' : 'no');
      }

      if (viewBox.storagePolicy.deduplicationEnabled &&
        !viewBox.storagePolicy.inlineDeduplicate) {
          return translateString(showSuffix ? 'postProcessedDeduplication' :
            'postProcessed');
        }

      return translateString(showSuffix ? 'inlineDeduplication' : 'inline');
    };
  }

  /**
   * @ngdoc filter
   * @name  viewReductionRatioFilter
   *
   * @description
   * Provides a string value representing a View's overall Reduction Ratio which
   * is logical/storage consumed.
   *
   * @param     {Object}   view       View object.
   * @returns   {String}              Text value of overall Reduction Ratio.
   *
   * @example
   * <div ng-repeat="view in views">
   *  view: {{view.name}}<br>
   *  Reduction: {{view | viewReductionRatio}}
   * </div>
   */
  function viewReductionRatioFilter(_) {
    return function viewReductionRatioFn(view) {
      var dataUsageStats = get(view, 'stats.dataUsageStats', {});

      if (!dataUsageStats.storageConsumedBytes) {
        return translateString('naNotAvailable');
      }

      return clamp(
        dataUsageStats.dataInBytes / dataUsageStats.storageConsumedBytes,
        0, Number.MAX_VALUE
      ).toFixed(1) + 'x';
    };
  }

  /**
   * @ngdoc filter
   * @name  viewDedupeRatioFilter
   *
   * @description
   * Provides a string value representing a View's overall Deduplication Ratio.
   *
   * @param     {Object}   view       View object.
   * @returns   {String}              Text value of overall Deduplication Ratio.
   *
   * @example
   * <div ng-repeat="view in views">
   *  view: {{view.name}}<br>
   *  Deduplication: {{view | viewDedupeRatio}}
   * </div>
   */
  function viewDedupeRatioFilter(_) {
    return function viewReductionRatioFn(view) {
      var dataUsageStats = get(view, 'stats.dataUsageStats');

      if (!get(dataUsageStats, 'dataInBytes')) {
        return translateString('naNotAvailable');
      }

      return (dataUsageStats.dataInBytes /
        dataUsageStats.dataInBytesAfterDedup).toFixed(1) + 'x';
    };
  }

  /**
   * @ngdoc filter
   * @name  viewProtocols
   *
   * @description
   * Provides a string value representing a View's supported protocol(s)
   *
   * @param   {Object} view object as returned from API
   * @returns {String}      text value of View's supported protocols
   *
   * @example
    <div ng-repeat="view in views">
      view: {{view.name}}<br>
      Protocols: {{view | viewProtocols}} <!-- outputs: NFS, SMB, S3, Swift-->
    </div>
   */
  function viewProtocolsFilter(
    $translate, ViewServiceFormatter, FEATURE_FLAGS) {

    var protocolStringKeys = {
      kNFSOnly: 'nfs',
      kSMBOnly: 'smb',
      kS3Only: 's3',
      kSwiftOnly: 'swift',
      kAll: 'nfsSmbS3',
    };

    return function viewProtocols(view) {
      if (!view) { return; }

      // NOTE: not using cached translate string because `nfsSmbS3` keys is
      // dynamic in nature.
      return view.protocolAccess ?
        $translate.instant(protocolStringKeys[view.protocolAccess], {
          isS3ViewAccessEnabled:
            ViewServiceFormatter.isS3ViewAccessEnabled(view),
        }) : translateString('naNotAvailable');
    };
  }

  /**
   * @ngdoc filter
   * @name  capitalize
   *
   * @description
   * Capitalizes all the words of a given string split by the [separator], or
   * optionally just the first letter.
   *
   * @param   {string} input       The string to be formatted.
   * @param   {string} [format]    The format to be applied being the options
   *                               'all', or 'first'. Default: 'all'
   * @param   {string} [separator] The character(s) to be used for separating
   *                               the string. Default: space
   * @returns {string}             Formatted string.
   */
  function FilterCapitalize() {
    function capitalizeWord(input) {
      return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
    }

    return function(input, format, separator) {
      if (!input) {
        return input;
      }

      format = format || 'all';
      separator = separator || ' ';

      if (format === 'first') {
        // Capitalize the first letter of a sentence
        return capitalizeWord(input);
      } else {
        var words = input.split(separator);
        var result = [];

        words.forEach(function(word) {
          result.push(capitalizeWord(word));
        });

        return result.join(separator);
      }
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.fileName
   * @description
   *   Filters a URL to its' fileName.
   *
   * @param  {Int=}     maxDepth   Optionally displays n folders above the
   *                               filename. Default: 1 (filename only)
   * @param  {String=}  delimiter  Tells the filter where to delimit the folders
   *
   * @example
   *   {{myVar | fileName:2:'/'}}
   *
   * @function
   */
  function FilterFileName() {
    return function filterFileName(input, maxDepth, delimiter) {
      var out;
      var outputPrefix;

      if (!input) {
        return input;
      }

      maxDepth = parseInt(maxDepth || 1, 10);
      delimiter = delimiter || '/';
      out = input.split(delimiter);
      outputPrefix = (out.length > 1) ? '...' : '';
      out = out.slice(maxDepth * -1).join(delimiter);
      outputPrefix = (out.length > 1) ? outputPrefix : '';

      return [outputPrefix, out].join(delimiter);
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.cCONST
   * @description
   *   Allows template access to constants defined on the 'C' module without
   *   having to inject them in the controller and $scope.
   *   Doesn't handle arrays yet, but its on the roadmap.
   *
   * @example
   *   {{ 'ENUM_UI_CLONE_OBJECT_STATUS.success' | cCONST }}
   *   <span ng-bind-html="'ENUM_UI_CLONE_OBJECT_STATUS.success' | cCONST"></span>
   *
   * @function
   */
  function FilterConstants() {
    return function cCONST(input) {
      return _getKeyValue(input);
    };
  }


  /**
   * calles deepValueFromObjectWithStringPath to get nested object values
   *
   * @param     {string}    key     the path of the value you want
   * @param     {obj}       obj     the obj that contains the value you want
   *
   * @return                        the value you wanted
   */
  function _getKeyValue(key, obj) {
    if (!obj && !_constants) {
      _constants = _getConstants();
    }
    obj = obj || _constants;

    return _cUtils.deepValueFromObjectWithStringPath(obj, key);
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.dateToUsecs
   * @description
   *   Filters a date to microseconds.
   *
   * @example
   *   {{myDate | dateToUsecs}}
   *
   * @function
   */
  function FilterDateToUsecs(DateTimeService) {
    return function dateToUsecs(date) {
      if (!date) {
        return DateTimeService.getNotAvailableFormat();
      }

      return DateTimeService.dateToUsecs(date);
    };
  }

  /**
   * Formats a date object to day of week string
   * This filter will display a date object as: Tuesday
   *
   * @param {object} date object
   * @return {string} day of week
   */
  function FilterDateToDayOfWeek(DateTimeService) {
    return function dateToDayOfWeek(date) {
      if (!date) {
        return DateTimeService.getNotAvailableFormat();
      }

      var dateFormat = DateTimeService.getLongDayOfWeekFormat();

      return DateTimeService.formatDate(date, dateFormat);
    };
  }

  /**
   * Formats a date object into the c-date-picker format.
   *
   * @param   {object}  date object
   * @return  {string}  Formatted date string
   */
  function FilterDateToDatePickerFormat(DateTimeService) {
    return function DateToDatePickerFormat(date) {
      var dateFormat;

      if (!date) {
        return DateTimeService.getNotAvailableFormat();
      }

      dateFormat = DateTimeService.getDatePickerFormat().toUpperCase();
      return DateTimeService.formatDate(date, dateFormat);
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.usecsToDate
   * @description
   *   Filters a microsecond to formatted date. Can formatting strings as named
   *   formats (see locale_settings.json), or explicit formatting strings.
   *
   * @param   {String}  [dtFormat='mm/dd/yyyy h:MMtt'] Defines the formatting of
   *                                                  the output.
   * @param   {String}  timezone   Defines the time zone in which date is to be
   *                               formatted.
   *
   * @example
   *   // Default
   *   {{myUsecs | usecsToDate}}
   *
   *   // Named formats
   *   {{myUsecs | usecsToDate:'fullDateTimeFormat'}}
   *
   *   // Explicit formats
   *   {{myUsecs | usecsToDate:'mm/dd/yyyy h:MMtt'}}
   *
   *   // Custom time zone
   *   {{myUsecs | usecsToDate: 'mm/dd/yyyy h:MMtt': 'Asia/Calcutta'}}
   *
   * @function
   */
  function FilterUsecsToDate(DateTimeService, LOCALE_SETTINGS) {
    return function usecsToDate(usecs, dtFormat, timezone) {
      if (!usecs) {
        return DateTimeService.getNotAvailableFormat();
      }

      // First check if the passed string is a known locale format, then the
      // passed format (if defined), falling finally to the default preferred
      // format.
      dtFormat = LOCALE_SETTINGS[dtFormat] ||
        dtFormat ||
        DateTimeService.getPreferredFormat();

      return DateTimeService
        .msecsToFormattedDate(usecs / 1000, dtFormat, timezone);
    };
  }

  /**
   * Filters a millisecond to formatted date. Can format strings as named
   * formats (see locale_settings.json), or explicit formatting strings.
   *
   * @param    {numbers}    msecs       the time in milliseconds to be formatted
   *
   * @param    {string}     [dtFormat]  the key of the time format. can be found
   *                                    in local_settings.json
   * @param   {String}      [timezone]  Defines the time zone in which date
   *                                    is to be formatted.
   *
   * @return   {string}                 the formatted date to be displayed
   */
  function FilterMsecsToDate(DateTimeService, LOCALE_SETTINGS) {
    return function msecsToDate(msecs, dtFormat, timezone) {
      if (!msecs) {
        return DateTimeService.getNotAvailableFormat();
      }

      dtFormat = LOCALE_SETTINGS[dtFormat] ||
        dtFormat ||
        DateTimeService.getPreferredFormat();

      return DateTimeService.msecsToFormattedDate(msecs, dtFormat, timezone);
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.secsToDate
   * @description
   *   Filters a seconds to formatted date. Can formatting strings as named
   *   formats (see locale_settings.json), or explicit formatting strings.
   *
   * @param  {String}  [dtFormat='mm/dd/yyyy h:MMtt'] Defines the formatting of
   *                                                  the output.
   *
   * @example
   *   // Default
   *   {{mySecs | secsToDate}}
   *
   *   // Named formats
   *   {{mySecs | secsToDate:'fullDateTimeFormat'}}
   *
   *   // Explicit formats
   *   {{mySecs | secsToDate:'mm/dd/yyyy h:MMtt'}}
   *
   * @function
   */
  function FilterSecsToDate(DateTimeService, LOCALE_SETTINGS) {
    return function secsToDate(secs, dtFormat) {
      if (!secs) {
        return DateTimeService.getNotAvailableFormat();
      }

      // First check if the passed string is a known locale format, then the
      // passed format (if defined), falling finally to the default preferred
      // format.
      dtFormat = LOCALE_SETTINGS[dtFormat] ||
        dtFormat ||
        DateTimeService.getPreferredFormat();

      return DateTimeService.secsToFormattedDate(secs, dtFormat);
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.usecsToTime
   * @description
   *   Filters a microsecond to time duration string
   *
   * @example
   *   {{myUsecs | usecsToTime}}
   *
   * @function
   */
  function FilterUsecsToTime(DateTimeService) {
    return function usecsToTime(usecs, verbose) {
      return DateTimeService.usecsToTime(usecs, verbose).time;
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.msecsToDuration
   * @description
   *   Filters milliseconds to time duration string
   *
   * @example
   *   {{msecs | msecsToTime}} // Default terse mode '3w'
   *   {{msecs | msecsToTime:true}} // Shows verbose mode '3 weeks'
   *
   * @function
   */
  function FilterMsecsToDuration(DateTimeService) {
    return function msecsToDuration(msecs, verbose) {
      if (msecs < 0) {
        // Special case for retention selector component 'Forever' option.
        return translateString('forever');
      }
      return DateTimeService.usecsToTime(msecs * 1000, verbose).time;
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.secsToTime
   * @description
   *   Filters seconds to time duration string
   *
   * @example
   *   {{mySecs | secsToTime}} // Default terse mode '3w'
   *   {{mySecs | secsToTime:true}} // Shows verbose mode '3 weeks'
   *
   * @function
   */
  function FilterSecsToTime(DateTimeService) {
    return function secsToTime(secs, verbose) {
      return DateTimeService.secsToTime(secs, verbose).time;
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.timeObjectToDate
   * @description
   *   Filters an API time object into a formatted timestamp
   *
   * @example
   *   {{myTimeObj | timeObjectToDate}}
   *
   * @function
   */
  function FilterTimeObjectToDate(DateTimeService) {
    return function timeObjectToDate(timeObj) {
      var timeFormat = DateTimeService.getPreferredTimeFormat();
      var dt;

      if (!timeObj ||
        angular.isUndefined(timeObj.hour) ||
        angular.isUndefined(timeObj.minute)) {
        return DateTimeService.getNotAvailableFormat();
      }

      dt = DateTimeService.timeObjectToDate(timeObj);

      return DateTimeService.formatDate(dt, timeFormat);
    };
  }

  /**
   * NOTE: This filter is deprecated. Please use {{entity.displayName}}
   * whenever possible. This filter is being left for the odd case when
   * entity.displayName isn't populated by Magneto yet. For which cases,
   * please file a bug with magneto to add this property.
   *
   * @ngdoc Filter
   * @name          C.filter.entityName
   * @description   displays the name of the provided entity
   *
   * @example       {{entity | entityName}}
   *
   * @function
   */
  function FilterEntityName(ENTITY_KEYS) {
    return function entityName(entity) {
      if (!entity || !entity.type) { return entity; }

      return entity.displayName || entity[ENTITY_KEYS[entity.type]].name;
    };
  }

  /**
   * @ngdoc   Filter
   * @name    C.filter.aagName
   *
   * @description
   *   Outputs the AAG name of the provided SQL entity.
   *
   * @example
   *   {{::entity | aagName}}
   *
   * @function
   */
  function FilterAagName() {
    return function aagName(entity) {
      if (!entity || !entity.sqlEntity) { return entity; }

      return entity.sqlEntity.dbAagName ||
        entity.sqlEntity.dbAagEntityId;
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.entityType
   * @description
   *   displays the type of the provided entity
   *
   * @example
   *   {{entity | entityType}}
   *
   * @function
   */
  function FilterEntityType(ENUM_ENTITY_TYPE, ENTITY_KEYS) {

    return function entityTypeFn(entity) {

      var entityKey;
      var envType;
      var entityType;

      if (!entity) {
        return '';
      }

      envType = entity.type;
      entityKey = ENTITY_KEYS[envType];

      // short-circuit if info is missing, likely from a pub to private entity
      // conversion thats lacking information
      if (!entity[entityKey]) { return ''; }

      entityType = entity[entityKey].type;

      return ENUM_ENTITY_TYPE[envType][entityType];

    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.environmentIconClass
   * @description
   *   outputs an icon class for the provided environment kValue.
   *
   * @example
   *   {{protectionSource.environment | evironmentIconClass}}
   *
   * @function
   */
  function FilterEnvironmentIconClass(ENVIRONMENT_ICON_CLASS) {
    return function envIconFn(environment) {
      return ENVIRONMENT_ICON_CLASS[environment];
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.environmentName
   * @description
   *   outputs a string name for the provided environment kValue.
   *
   * @example
   *   {{protectionSource.environment | evironmentName}}
   *
   * @function
   */
  function FilterEnvironmentName(ENUM_ENV_TYPE) {
    return function envNameFn(environment) {
      return ENUM_ENV_TYPE[environment];
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.sourceIcon
   * @description
   *   displays the icon of the provided node
   *
   * @example
   *   {{node | sourceIcon}}
   *   {{node | sourceIcon: { env: 'kPhysical'}}}
   *
   * @function
   */
  function FilterSourceIcon(PubSourceServiceUtil, ENUM_ENTITY_ICON_CLASS,
    ENV_GROUPS) {

    /**
     * determines the source icon for a particular node.
     *
     * @method   sourceIconFn
     * @param    {Object}   node       source node
     * @param    {Object}   [options]  extra options which you can send for
     *                                 additional logic
     *                                 ex: options.env is used to differentiate
     *                                     a physical server icon from oracle
     *                                     cluster icon.
     *
     * @returns  source icon class
     */
    return function sourceIconFn(node, options) {
      if (!node) {
        return undefined;
      }

      var protectionSource = node.protectionSource || node.source;
      var sourceEnvironment = protectionSource.environment;
      var nodeType =
        PubSourceServiceUtil.getProtectionSourceType(protectionSource);
      var envProtectionSource =
        PubSourceServiceUtil.getEnvProtectionSource(protectionSource) ||
        {};

      nodeType += _isAagDatabase(protectionSource) ? 'AAG' : '';

      // Different icons based on type of Oracle Database.
      if (sourceEnvironment === 'kOracle') {
        nodeType = protectionSource.oracleProtectionSource.dbType;
      }

      // Different icons based on type of UDA herarchy.
      if (sourceEnvironment === 'kUDA' && envProtectionSource.type === 'kObject') {
        return _getUdaIcon(envProtectionSource.objectInfo);
      }

      // Different icons based on type of Cassandra keyspace type.
      if (sourceEnvironment === 'kCassandra' && envProtectionSource.type === 'kKeyspace') {
        return _getCassandraKeyspaceIcon(envProtectionSource);
      }

      // Different icons based on type of Mngodb Objects.
      if (sourceEnvironment === 'kMongoDBPhysical') {
        nodeType = protectionSource.oracleProtectionSource.dbType;
      }

      // Special handling for certain types and default to generic case
      switch (true) {
        // for physical whose node type is one of ENV_GROUPS.oracleClusterTypes,
        // show oracle cluster specific icon.
        case (options && options.env === 'kOracle' &&
          sourceEnvironment === 'kPhysical' &&
          ENV_GROUPS.oracleClusterTypes.includes(nodeType)):
          return 'oracle-rac';


        // For "physical": Show the OS icon only if it is kHost or a oracle
        // cluster under physical.
        // This allows other types of kPhysical to use their distinct icons.
        case (sourceEnvironment === 'kPhysical' &&
          (['kHost'].concat(ENV_GROUPS.oracleClusterTypes)).includes(nodeType)):
          switch (envProtectionSource.hostType) {
            case 'kWindows':
              return 'os-windows';

            case 'kLinux':
              return 'os-linux';

            case 'kAix':
              return 'os-aix';

            case 'kSolaris':
              return 'os-solaris';

            case 'kHPUX':
              return 'os-hpux';

            default:
              // For all others, show the generic Physical icon.
              return 'vmware-compute-resource';
          }

        case (sourceEnvironment === 'kVMware' &&
          envProtectionSource.isVmTemplate):
          return 'vmware-vm-template';

        default:
          // No matches. Try again without special handling.
          return ENUM_ENTITY_ICON_CLASS[sourceEnvironment][nodeType];
      }
    };
  }

  /**
   * Get different Icon based on UDA Object type.
   *
   * @method    _getUdaIcon
   * @returns   {string}   Icon string.
   */
  function _getUdaIcon(objectInfo) {
    // Defaults to database icon.
    if (!objectInfo || !objectInfo.objectType) {
      return 'icn-db';
    }

    let icon;
    switch (objectInfo.objectType) {
      case 'Database':
        icon = 'icn-db';
        break;
      case 'Table':
        icon = 'object-table';
        break;
      default:
        icon = 'icn-db';
    }
    return icon;
  }

  /**
   * Get different Icon based on Cassandra keyspace type.
   *
   * @method    _getUdaIcon
   * @returns   {string}   Icon string.
   */
  function _getCassandraKeyspaceIcon(objectInfo) {
    // Defaults to database icon.
    if (!objectInfo || !objectInfo.objectType) {
      return 'icn-db';
    }

    let icon;
    switch (objectInfo.keyspaceInfo.type) {
      case 'kRegular':
        icon = 'cassandra-keyspace';
        break;
      case 'kSystem':
        icon = 'cassandra-system-keyspace';
        break;
      default:
        icon = 'cassandra-keyspace';
    }
    return icon;
  }

  /**
   * Get different Icon based on MOngoDB object type.
   *
   * @method    _getMongoDBPhysicalIcon
   * @returns   {string}   Icon string.
   */
  function _getMongoDBPhysicalIcon(objectInfo) {
    // Defaults to database icon.
    if (!objectInfo || !objectInfo.keyspaceInfo) {
      return 'cassandra-keyspace';
    }

    let icon;
    switch (objectInfo.objectType) {
      case 'kOrganization':
        icon = 'object-mongodb-organization';
        break;
      case 'kProject':
        icon = 'object-mongodb-project';
        break;
      case 'kCluster':
        icon = 'object-mongodb-cluster';
        break;
      default:
        icon = 'icn-db';
    }
    return icon;
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.sourceType
   * @description
   *   displays the type of the provided protectionSource
   *
   * @example
   *   {{protectionSource | sourceType}}
   *
   * @function
   */
  function FilterSourceType(PubSourceServiceUtil, SOURCE_TYPE_DISPLAY_NAME) {
    return function sourceTypeFn(protectionSource) {
      var envProtectionSource =
        PubSourceServiceUtil.getEnvProtectionSource(protectionSource) || {};
      var sourceEnvironment = protectionSource.environment;
      var sourceType =
        PubSourceServiceUtil.getProtectionSourceType(protectionSource);
      sourceType += _isAagDatabase(protectionSource) ? 'AAG' : '';
      var serverType;

      // Special handling for certain types and default to generic case
      switch(true) {
        case (sourceEnvironment === 'kVMware' &&
          envProtectionSource.isVmTemplate):
          return translateString('vmTemplate');

        case protectionSource._isSystemDb:
          return translateString('systemDb');

        case (sourceEnvironment === 'kAWS'):
          return translateString(
            protectionSource.awsProtectionSource.subscriptionType ===
              'kAWSGovCloud' ? 'awsGov' : 'aws');

        case (sourceEnvironment === 'kAzure'):
          return translateString(
            protectionSource.azureProtectionSource.subscriptionType ===
              'kAzureGovCloud' ? 'azureGov' : 'azure');

        case (sourceEnvironment === 'kPhysical'): {
          serverType = SOURCE_TYPE_DISPLAY_NAME[protectionSource.environment][sourceType];
          if(sourceType === 'kWindowsCluster' && envProtectionSource.clusterSourceType === 'kRole'){
            serverType += '.kRole';
          }
          return translateString(serverType);
        }

        default:
          return translateString(
            SOURCE_TYPE_DISPLAY_NAME[protectionSource.environment][sourceType]
          );
      }
    };
  }

  /**
   * Determines if a given protectionSource is an Aag Database or not.
   *
   * @method   _isAagDatabase
   * @param    {object}    protectionSource   The protectionSource object.
   * @return   {boolean}   True if it's a SQL AAG Database.
   */
  function _isAagDatabase(protectionSource) {
    var sqlSource = get(protectionSource, 'sqlProtectionSource', {});
    return sqlSource.type === 'kDatabase' && !!sqlSource.dbAagEntityId;
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.entityIconClass
   * @description
   *   displays the icon class for provided entity from private API responses
   *
   * @example
   *   <span class="icn-md {{entity | entityIconClass}}"></span>
   *
   * @function
   */
  function FilterEntityIconClass(SourceService, ENUM_ENTITY_ICON_CLASS) {

    return function entityIconClass(entity) {

      var entityKey;

      if (!entity || !entity.type) { return ''; }

      entityKey = SourceService.getEntityKey(entity.type);

      // short-circuit if info is missing, likely from a pub to private entity
      // conversion thats lacking information
      if (!entity[entityKey]) { return ''; }

      // Handle VM templates
      if (entity[entityKey].isVmTemplate) {
        return 'vmware-vm-template';
      }

      // Handle Database AAG's
      if (entity[entityKey].dbAagEntityId) {
        return ENUM_ENTITY_ICON_CLASS.kSQL.kDatabaseAAG;
      }

      return ENUM_ENTITY_ICON_CLASS[entity.type][entity[entityKey].type];

    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.aagHostIconClass
   * @description
   *   displays the icon class for provided AAG Host. This filter is necessary
   *   because we don't get a full Entity Proto.
   *
   * @example
   *   <i class="icn-inline {{::aagHost | aagHostIconClass}}"></i>
   *
   * @function
   */
  function FilterAagHostIconClass(ENUM_ENTITY_ICON_CLASS) {
    var iconsMap = ENUM_ENTITY_ICON_CLASS;

    return function aagIconClassFilter(aagHost) {
      var iconClass = 'icn-help';

      if (iconsMap[aagHost.environment]) {
        switch (aagHost.environment) {
          case 'kPhysical':
            iconClass = iconsMap
              .kPhysical[aagHost.physicalProtectionSource.type];
            break;

          // Expand for other types here.

          default:
            iconClass = iconsMap.kVMware.kVirtualMachine;
        }
      }

      return iconClass;
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.highlight
   * @description
   *   Wraps a given phrase within a string in a `span.c-highlight`
   *
   *   This must be used within ngBindHtml directive because it returns HTML
   *   in the new string. If not used in that directive, it the markup will be
   *   escaped and displayed.
   *
   * @example
   *   <span ng-bind-html="stringVar | highlight:queryPhraseVar"></span>
   *   <span ng-bind-html="stringVar | highlight:'highlight me'"></span>
   *
   * @function
   */
  function FilterHighlight($sce) {
    // TODO(Spencer): Make this accept an array of highlight phrases
    return function highlight(text, phrase) {
      var rxHL;

      if (text && phrase) {
        rxHL = new RegExp('(' + phrase + ')', 'gim');
        text = $sce.trustAsHtml(text.replace(rxHL, '<span class="c-highlight">$1</span>'));
      }

      return text;
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.viewBox
   * @description
   *   Uses the cached list of viewBoxes on the ViewBoxCacheService to retrieve
   *   a ViewBox object easily via filter. Passing '*' as the id retrieves the
   *   entire list which is useful for ngRepeat.
   *
   * @example
   *   Object: {{vmDocument.viewBoxId | viewBox}}
   *   All ViewBoxes: {{'*' | viewBox}}
   *   Object Property: {{(vmDocument.viewBoxId | viewBox).name}}
   *
   * @function
   */
  function FilterViewBox(ViewBoxCacheService) {
    return function filter(id) {
      var vbox = (id === '*') ?
        ViewBoxCacheService.viewBoxes :
        ViewBoxCacheService.viewBoxes[id];

      return vbox || {};
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.jobRun
   * @description
   *   Uses the cached list of cachedJobRuns on the JobRunsService to retrieve a
   *   Job object easily via filter. Passing '*' as the id retrieves the
   *   entire list which is useful for ngRepeat.
   *
   * @example
   *   Job Object: {{jobId | jobRun}}
   *   All Runs: {{'*' | jobRun}}
   *   Job Config: {{(jobId | jobRun).jobDescription}}
   *   List of runs: {{(jobId | jobRun).runs}}
   *
   * @function
   */
  function FilterJobRun(JobRunsService) {
    return function filter(id) {
      var job = (id === '*') ?
        JobRunsService.cachedJobRuns :
        JobRunsService.cachedJobRuns[id];

      return job || {};
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.wrapUnits
   * @description
   *   Given a quantity+unit string, this filter wraps the unit portion in
   *   .stat-value compliant markup.
   *
   * @example
   *   {{'1234 GiB' | wrapUnits}}
   *   {{'36 TiB' | wrapUnits}}
   *
   * @function
   */
  function FilterWrapUnitsFn($sce) {
    var wrapperString = '<span class="stat-value-unit">$1</span>';

    return function filter(input) {
      if (!input) {
        return input;
      }

      return $sce.trustAsHtml(('' + input).replace(/([\D]+)$/i, wrapperString));
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.policyType
   * @description
   *   ouputs the policy type for a provided policy
   *
   * @example
   *   {{policyObj | policyType}}
   *
   * @function
   */
  function FilterPolicyType(POLICY_TYPE) {
    return function filter(policy) {
      var policyType = '';

      if (policy && policy.templateDetails && policy.templateDetails.type) {
        policyType = translateString(POLICY_TYPE[policy.templateDetails.type]);
      }

      return policyType;
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.archivalTargetIcon
   * @description
   *   Provides an icon class string for a provided snapshotArchivalCopyPolicy.
   *
   * @example
   *   {{archivalPolicy.target | archivalTargetIcon}} // 'icn-cloud'
   *
   * @function
   */
  function FilterArchivalTargetIcon() {
      var archiveTypeIcons = {
        kNas: 'icn-cloud',
        Nas: 'icn-cloud',
        kCloud: 'icn-cloud',
        Cloud: 'icn-cloud',
        kTape: 'icn-tape',
        Tape: 'icn-tape',
      };


    return function filter(target) {
      if (!target) { return; }

      return archiveTypeIcons[target.vaultType || target.targetType] || '';
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.snapshotTargetIcon
   * @description
   *   Provides an icon class string for a provided snapshotTarget.
   *
   * @example
   *   {{snapshotTarget | snapshotTargetIcon}}
   *
   * @function
   */
  function FilterSnapshotTargetIcon() {
    return function filter(snapshotTarget) {
      var archiveTypeIcons = {
        0: 'icn-cloud',
        1: 'icn-tape',
        2: 'icn-cloud',
      };
      var defaultTarget = {
        target: {
          type: 1,
        },
      };

      snapshotTarget = angular.extend(defaultTarget, snapshotTarget);
      if (snapshotTarget?.target?.archivalTarget?.ownershipContext === 1) {
        return 'icn-vault';
      }

      switch (snapshotTarget.target.type) {
        case 1:
          return 'icn-local';

        case 2:

          // replication
          return 'icn-remote';

        case 3:

          // archival
          return archiveTypeIcons[snapshotTarget.target.archivalTarget.type];

        case 5:

          // csm
          return 'icn-csm';

      }
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.snapshotTargetType
   * @description
   *   Provides a display string for a snapshot target
   *
   * @example
   *   {{snapshotTarget | snapshotTargetType}}
   *
   * @function
   */
  function FilterSnapshotTargetType($translate, SNAPSHOT_TARGET_TYPE_STRINGS) {
    return function getSnapshotTargetString(snapshotTarget) {
      var key = SNAPSHOT_TARGET_TYPE_STRINGS[snapshotTarget.type];
      if (snapshotTarget.archivalTarget) {
        key = key[snapshotTarget.archivalTarget.type];
      }
      return $translate.instant(key);
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.simpleList
   * @description
   *   Converts an array to an inline delimited list.
   *
   * @example
   *   {{[1, 2, 'three'] | simpleList}}
   *   // Output: 1, 2, three
   *
   *   {{[1, 2, 'three'] | simpleList: ' / '}}
   *   // Output: 1 / 2 / three
   *
   *   {{[{name: 'item1'}, {name: 'item2'}] | simpleList:', ':'name' }}
   *   // Output: item1, item2
   *
   * @function
   */
  function FilterSimpleList() {
    return function filter(list, delimiter, propKey) {
      // Use a default delimiter if its null, undefined or ''
      delimiter = delimiter ? delimiter : ', ';

      // If these conditions match, just return the "list" param as-is.
      if (!Array.isArray(list)) {
        return list;
      }

      // If propKey is provided, lets reduce the list to the desired key's
      // values
      if (propKey) {
        list = list.map(function loopItems(item) {
          return _getKeyValue(propKey, item);
        });
      }

      // Join it together with the delimiter.
      return list.join(delimiter);
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.restoreObjectName
   * @description
   *   Given an Restore Task object and a restoreTask, displays the object name
   *   with any rename prefix & suffix..
   *
   * @example
   *   {{::object | restoreObjectName:restoreTask}}
   *
   *   // Output: "[prefix]objectName[suffix]"
   *
   * @function
   */
  function restoreObjectNameFilter() {
    return function filter(object, restoreTask) {
      var emptyString = '';
      var renameParams;

      if (!object || !restoreTask) { return; }

      renameParams = angular.extend(
        { prefix: emptyString, suffix: emptyString },
        restoreTask._envTaskParams.renameRestoredObjectParam
      );

      return object.entity && [
        renameParams.prefix,
        object.entity.displayName,
        renameParams.suffix,
      ].join(emptyString);
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.simpleListTranslated
   * @description
   *   Converts an array of translation keys to an inline delimited list of
   *   translated strings.
   *
   * @example
   *   {{['create', 'modify', 'delete'] | simpleListTranslated }}
   *   // Output: Create, Modify, Delete
   *
   *   {{['create', 'modify', 'delete'] | simpleListTranslated:' | ' }}
   *   // Output: Create | Modify | Delete
   *
   * @function
   */
  function FilterSimpleListTranslated($filter) {
    return function filter(list, delimiter) {
      list = list.map(translateString);

      // Run array of strings through simpleList filter
      return $filter('simpleList')(list || [], delimiter);
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.simpleListConstants
   * @description
   *   Converts an array of Constant keys to an inline delimited list of
   *   converted strings.
   *
   * @example
   *   {{[3,17] | simpleListConstants:'ENUM_ENV_TYPE':'/ '}}
   *   // MS SQL/ MS Exchange
   *
   * @function
   */
  function FilterSimpleListConstants($filter) {
    return function filter(list, constant, delimiter) {
      delimiter = delimiter !== undefined ? delimiter : ', ';

      list = list.map(function(item) {
        return _getKeyValue([constant, item].join('.'));
      });

      // Run array of strings through simpleList filter
      return $filter('simpleListTranslated')(list || [], delimiter);
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.humanizeDuration
   * @description
   *   Wrapper for moment.js to render a friendlier version of '2880 minutes'.
   *   The argument accepts any typical unit of time: minutes, hours, days, etc.
   *
   * @example
   *   {{totalMinutes | humanizeDuration:'minutes'}}
   *
   * @function
   */
  function FilterHumanizeDuration() {
    return function humanizeDuration(value, unit) {
      return moment.duration(value, unit).humanize();
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.fromUsecs
   * @description
   *   Wrapper for moment.js to render a string that indicates how long ago a
   *   usec value occurred from the current cluster time.
   *   NOTE: This was renamed to humanizeFromUsecs when porting to Angular pipe.
   *
   * @example
   *   {{startTimeUsecs | fromUsecs}}
   *
   * @function
   */
  function FilterfromUsecs() {
    return function fromUsecs(usecs) {
      if (!usecs || !isFinite(usecs)) {
        return translateString('naNotAvailable');
      }
      return moment(usecs / 1000).from(Date.clusterNow());
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.timezoneAbbreviation
   * @description
   *   Generate timezone abbreviation i.e. IST, PST, GMT etc
   *
   * @example
   *   {{'Asia/Calcutta' | timezoneAbbreviation}}
   *
   * @function
   */
  function FilterTimezoneAbbreviation() {
    return function timezoneAbbreviation(timezone) {
      if (!timezone) {
        return;
      }

      return moment().tz(timezone).format('z');
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.yesNo
   * @description
   *   Evaluates the provided expression and provides a 'Yes' or 'No' string
   *   based on the truthiness of the evaluation.
   *
   * @example
   *   {{true | yesNo}}   <!-- Yes -->
   *   {{false | yesNo}}  <!-- No -->
   *
   * @function
   */
  function YesNoFn($filter) {
    return function filter(expr) {
      return $filter('boolLabel')(expr, 'yes', 'no');
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.boolLabel
   * @description
   *   Evaluates the provided expression and applies one of the given labels.
   *   Mostly used internally by other convenience boolean filters, like yesNo,
   *   onOff, etc.
   *
   * @example
   *   {{true | boolLabel:'truthy':'falsey'}}   <!-- Truthy -->
   *   {{false | boolLabel:'truthy':'falsey'}}   <!-- Falsey -->
   *
   * @function
   */
  function BoolLabelFn() {
    /**
     * Boolean label filter.
     *
     * @param   {expr}     expr                The $scope.$eval expression that
     *                                         returns a truthy or falsey value.
     * @param   {string}   [trueLabel='yes']   Translation key for the label to
     *                                         display when expr is true.
     * @param   {string}   [falseLabel='no']   Translation key for the label to
     *                                         display when expr is false.
     */
    return function filter(expr, trueLabel, falseLabel) {
      return expr ?
        translateString(trueLabel || 'yes') :
        translateString(falseLabel || 'no');
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.backupType
   * @description
   *   Evaluates the provided JobRun or Yoda version and provides a
   *   'Full Backup' or 'Incremental Backup' string based on the truthiness of the
   *   evaluation.
   *
   * @example
   *   {{true | backupType}}        <!-- Full Backup -->
   *   {{false | backupType}}       <!-- Incremental Backup -->
   *   {{'kFull' | backupType}}     <!-- Full -->
   *   {{'kRegular' | backupType}}  <!-- Incremental -->
   *   {{'kLog' | backupType}}      <!-- Log -->
   *
   * @function
   */
  function BackupTypeFn($filter) {
    return function filter(thing, abbreviated) {
      var fullKey = abbreviated ? 'full' : 'fullBackup';
      var incrementalKey = abbreviated ? 'incremental' : 'incrementalBackup';
      thing = thing || 'kRegular';

      return !isBoolean(thing) ?
        translateString('enum.jobRunType.'+thing) :
        $filter('boolLabel')(thing, fullKey, incrementalKey);
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.pad
   * @description
   *   Convert value to string and pad with specified number of characters
   *   (default is zeros)
   *
   * @example
   *   {{minutes | pad: 2}}
   *   // Output: "07"
   *
   *   {{pageNumber | pad: 20: '.'}}
   *   // Output: "....................45"
   *
   * @function
   */
  function FilterPad() {
    return function padString(str, length, pad) {
      var padding;

      length = length || 0;
      padding = new Array(length + 1).join(pad || '0');

      if (typeof str === 'undefined') {
        return padding;
      }

      return (padding + str).slice(-padding.length);
    };
  }

  /**
   * Sort days according to calendar order.
   *
   * @method     sortDays
   * @param      {Array}  daysList         array of kValue days
   * @param      {Array}  DAY_PERIODICITY  The days constant
   * @return     {Array}  calendar-ordered array of kValue days
   */
  function sortDays(daysList, DAY_PERIODICITY) {
    var orderedDays = [];

    DAY_PERIODICITY.forEach(function testDay(day) {
      if (daysList.includes(day)) {
        orderedDays.push(day);
      }
    });

    return orderedDays;
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.ordinaryDayCount
   * @description   translate number to ordinary day count
   *
   * @example
   *   {{monthlyScheduledDayCount | ordinaryDayCount}}
   *   // Output 1 : "First"
   *   // Output 5 : "Last"
   * @function
   */
  function FilterOrdinaryDayCount(ENUM_DAY_COUNT_IN_MONTH) {
    return function ordinaryDayCount(dayCounts) {
      return translateString(ENUM_DAY_COUNT_IN_MONTH[dayCounts]);
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.humanizeArrayOfDays
   * @description
   *   Assemble a human friendly display string for the array of days provided.
   *
   * @example
   *   {{window.days | humanizeArrayOfDays | translate}}
   *   // Output ['kSunday', 'kSaturday']: "Weekends"
   *   // Output ['kTuesday']: "Tuesday"
   *   // Output ['kMonday', 'kWednesday', 'kFriday']: "Mon, Wed, Fri"
   *
   * @function
   */
  function FilterHumanizeArrayOfDays(DateTimeService, ENUM_DAY,
    DAY_PERIODICITY, DAY_KVAL) {
    return function humanizeArrayOfDays(days) {
      var dayString;
      if (days) {
        if (typeof days[0] === 'number') {
          days = days.map(function (day) {
            return DAY_KVAL[day];
          });
        }

        days = days.sort();
      }

      switch (true) {
        case (days === undefined || days.length === 7):

          // Every day. Indicated by an undefined `days` property.
          dayString = translateString('daily');
          break;

        case days.length === 1:

          // A single day of the week localized by momentjs.
          dayString = moment(ENUM_DAY[days[0]], 'dddd')
            .format(DateTimeService.getLongDayOfWeekFormat());
          break;

        case angular.equals(days, weekendList):

          // Every weekend day
          dayString = translateString('weekends');
          break;

        case angular.equals(days, weekdayList):

          // Every weekday
          dayString = translateString('weekdays');
          break;

        default:
          days = sortDays(days, DAY_PERIODICITY);
          // CSV list of abbreviated days localized by momentjs.
          dayString = days.map(function mapLocalizedDayAbbr(day) {
            return moment(ENUM_DAY[day], 'dddd')
              .format(DateTimeService.getShortDayOfWeekFormat());
          }).join(', ');
      }

      return dayString;
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.assembleTimeString
   * @description
   *   Assemble a time string from hours and minutes
   *
   * @example
   *   {{ {hour:hours, minute:minutes} | assembleTimeString}}
   *   // Output {hour: 2, minute: 2}: "2:02 am"
   *
   * @function
   */
  function FilterAssembleTimeString(DateTimeService, $filter) {
    return function assembleTimeString(timeObj) {

      if (!timeObj || timeObj.hour < 0 || timeObj.minute < 0) {
        return;
      }

      return moment([

        // Hours, unpadded
        timeObj.hour,

        // Minutes, padded
        $filter('pad')(timeObj.minute, 2)

        // Join the values into a string and provide an internal pattern
        // telling moment what it is receiving
        ].join(''), 'hmm')

        // Format this time for display
        .format(DateTimeService.getPreferredTimeFormat());
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.ioRateString
   * @description
   *   Provide a string interpretation for BackgroundIoRate enum value
   *
   * @example
   *   {{ ioRateStr | ioRateString}}
   *   // Output kHigh: "No Restriction"
   *
   * @function
   */
  function FilterIoRateString() {
    return function ioRateString(ioRateStr) {
      return translateString(`enum.clusterBackgroundIoRate.${ioRateStr}`);
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.transferRateBytes
   * @description
   *   Convert Bytes per sec to human readable "MiB/sec"
   * @param {Boolean}     [useBits=false]  use bits instead of bytes
   *                                       (Mbps instead of MiB/sec)
   *
   * @example
   *   {{rateLimitBytesPerSec | transferRateBytes}}
   *   // Output 104857600: "100 MiB/sec"
   *
   *   {{rateLimitBytesPerSec | transferRateBytes: true}}
   *   // Output 104857600: "800 Mbps"
   *
   * @function
   */
  function FilterTransferRateBytes(FILESIZES) {
    return function transferRateBytes(bytes, useBits) {
      var unitKey =
        useBits ? 'bitrate.mbps' : 'bitrate.mBps';

      // TODO: make this scale smartly
      var units = useBits ? bytes / FILESIZES.bit : bytes;
      return [units / FILESIZES.megabyte, translateString(unitKey)].join(' ');
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.onOff
   * @description
   *   Evaluates the provided expression and provides 'On' or 'Off' string
   *   based on the truthiness of the evaluation.
   *
   * @example
   *   {{true | onOff}}   <!-- On -->
   *   {{false | onOff}}  <!-- Off -->
   *
   * @function
   */
  function OnOffFn($filter) {
    return function filter(expr) {
      return $filter('boolLabel')(expr, 'on', 'off');
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.enabledDisabled
   * @description
   *   Evaluates the provided expression and provides 'Enabled' or 'Disabled'
   *   string based on the truthiness of the evaluation.
   *
   * @example
   *   {{true | enabledDisabled}}   <!-- Enabled -->
   *   {{false | enabledDisabled}}  <!-- Disabled -->
   *
   * @function
   */
  function enabledDisabledFn($filter) {
    return function filter(expr) {
      return $filter('boolLabel')(expr, 'enabled', 'disabled');
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.enquote
   * @description
   *   Wraps a given string in localized quotation marks.
   *
   * @example
   *   {{'clusterName' | translate | enquote}}  <!-- “Cluster Name” -->
   *   {{'my job' | enquote}}  <!-- “my job” -->
   *
   * @function
   */
  function enquoteFn($sce) {
    return function enquoteFilter(quote) {
      return $sce.trustAsHtml([
        translateString('quotationOpen', true),
        quote,
        translateString('quotationClose', true),
      ].join(''));
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.hostType
   * @description
   *   displays the type of the provided host. This method returns either the
   *   standardized value from the host type enum or a customized value for
   *   other complex types.
   *
   * @example
   *   {{node | hostType}}
   *
   * @function
   */
  function FilterHostType(SourceService, ENUM_HOST_TYPE, ENTITY_KEYS) {
    return function hostTypeFn(node) {
      var keyedEntity = SourceService.getTypedEntity(node.entity);

      if (keyedEntity.type === 2) {
        // Windows host with a MS SQL Server app.
        return translateString('msSqlCluster');
      } else {
        return ENUM_HOST_TYPE[keyedEntity.hostType];
      }
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.secsDuration
   * @description
   *   Returns the difference in secs between startTimeSecs and endTimeSecs
   *   properties in the provided object. If endTimeSecs is undefined, then
   *   use current time.
   *
   * @example
   *   {{ job | secsDuration}}
   *   {{ {startTimeSecs: task.base.startTimeSecs,
   *       endTimeSecs: task.base.endTimeSecs} | secsDuration}}
   *
   * @function
   */
  function FilterSecsDuration() {
    return function filter(event) {
      if (!event) {
        return;
      }

      // Use given endTime, or the cluster's now() time.
      var endTimeSecs = event.endTimeSecs || Date.clusterNow() / 1000;

      // If we have no start time, then abort.
      if (isNaN(event.startTimeSecs)) {
        return translateString('naNotAvailable');
      }

      // We're good. Return the sec difference.
      return endTimeSecs - event.startTimeSecs;
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.usecsDuration
   * @description
   *   Returns the difference in usecs between startTimeUsecs and endTimeUsecs
   *   properties in the provided object. If endTimeUsecs is undefined, then
   *   use current time.
   *
   * @example
   *   {{ job | usecsDuration}}
   *   {{ {startTimeUsecs: task.base.admittedTimeUsecs,
   *       endTimeUsecs: task.base.endTimeUsecs} | usecsDuration}}
   *
   * @function
   */
  function FilterUsecsDuration() {
    return function filter(event) {
      // Use given endTime, or the cluster's now() time.
      var endTimeUsecs = event.endTimeUsecs || Date.clusterNow() * 1000;

      var startTimeUsecs = event.startTimeUsecs;

      // If we have no start time, then abort.
      if (isNaN(startTimeUsecs)) {
        return translateString('naNotAvailable');
      }

      // We're good. Return the usec difference.
      return endTimeUsecs - startTimeUsecs;
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.humanizeMsecsDiff
   * @description
   *   Returns the date difference between testTimeMsecs & referenceTimeMsecs
   *   in human redable format, eg. 3 days ago, 2 hours ago, an hours ago etc.
   *   if referenceTimeMsecs is undefined then use current cluster time.
   *   if withoutSuffix is true then difference will be w/o suffix and by
   *   default it will be false.
   *
   * @example
   *   {{ testTimeMsecs | humanizeMsecsDiff}}
   *
   * @function
   */
  function FilterHumanizeMsecsDiff($log) {
    // reset relative time thresholds to default value when called, internally
    // read thresholds value and cache then to used while resetting.
    var resetRelativeTimeThreshold = (
      function resetRelativeTimeThresholdGenerator() {
        var defaultThresholds = {
          ss: null,         // a few seconds to seconds
          s : null,         // seconds to minute
          m : null,         // minutes to hour
          h : null,         // hours to day
          d : null,         // days to month
          M : null          // months to year
        };

        // read thresholds value and cache them for later use
        Object.keys(defaultThresholds).forEach(function eachUnit(unit) {
          defaultThresholds[unit] = moment.relativeTimeThreshold(unit);
        });

        /**
         * reset the thresholds value to default values cached above.
         *
         * @method   resetRelativeTimeThreshold
         */
        return function resetRelativeTimeThreshold() {
          Object.keys(defaultThresholds).forEach(function eachUnit(unit) {
            moment.relativeTimeThreshold(unit, defaultThresholds[unit]);
          });
        };
      }
    )();

    /**
     * Returns the difference b/w test and reference data(default current date).
     *
     * @method   humanizeMsecsDiff
     * @param    {number}   testTimeMsecs                    The test time
     * @param    {String}   [resultUnit='milliseconds']      The unit in which
     *                                                       date difference
     *                                                       result would be
     *                                                       returned like days,
     *                                                       years etc
     * @param    {number}   [referenceTimeMsecs=Date.now()]  The reference time
     *                                                       if not given will
     *                                                       use current time
     * @param    {number}   [withoutSuffix=true]             if false then
     *                                                       suffix like 'ago'
     *                                                       in '2 Days ago'
     *                                                       will be removed
     *
     * @return   {String}   The data difference b/w test and reference time.
     */
    return function humanizeMsecsDiff(testTimeMsecs, resultUnit,
      referenceTimeMsecs, withoutSuffix) {
      var result;
      var difference;
      var isDifferenceZero = false;
      var resultUnits = {
        months: 'M',
        days: 'd',
        hours: 'h',
        minutes: 'm',
        seconds: 's',
        milliseconds: 'ss',
      };

      // initializing the params
      resultUnit = resultUnit || 'milliseconds';
      referenceTimeMsecs = referenceTimeMsecs || Date.clusterNow();
      withoutSuffix =
        (typeof withoutSuffix === 'undefined') ? true : !!withoutSuffix;

      // If we have no test or reference time then abort and log with error.
      if (isNaN(testTimeMsecs) || isNaN(referenceTimeMsecs)) {
        $log.error(
          'C.filter.humanizeMsecsDiff:',
          'either test or reference time is not a number.'
        );
        return translateString('naNotAvailable');
      }

      // ensure resultUnit is defined and valid.
      if (resultUnit && !resultUnits[resultUnit]) {
        $log.error(
          'C.filter.humanizeMsecsDiff:',
          'invalid result unit "',
          resultUnit,
          '" choose one from:',
          Object.keys(resultUnits)
        );
        return translateString('naNotAvailable');
      }

      // find the difference from todays start date
      if (resultUnit === 'days') {
        difference = moment.duration(
          testTimeMsecs - moment().startOf('days')).as(resultUnit);

        if (difference >= 0) {
          return translateString('today');
        }
      }

      // find the difference from current time
      difference =
        moment.duration(testTimeMsecs - referenceTimeMsecs).as(resultUnit);

      // prepare moment to for humanize date diffing.
      // by setting relative time threshold values we are forcing moment to
      // result the humanize date in provided units bigger unit. for example
      // if resultUnit is "hours" the result units would be like
      // - an hours ago
      // - 14 hours ago
      // - 20 hours ago
      // - a day ago
      // - 2 days ago ...
      switch(resultUnit) {
        case 'months':
          moment.relativeTimeThreshold('d', -1);
          /* falls through */
        case 'days':
          moment.relativeTimeThreshold('h', -1);
          /* falls through */
        case 'hours':
          moment.relativeTimeThreshold('m', -1);
          /* falls through */
        case 'minutes':
          moment.relativeTimeThreshold('s', -1);
          /* falls through */
        case 'seconds':
          moment.relativeTimeThreshold('ss', -1);
          /* falls through */
      }

      result = moment.duration(difference, resultUnit).humanize(withoutSuffix);

      // reset moment.js threshold values to default
      resetRelativeTimeThreshold();

      return result;
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.timeDuration
   * @description
   *   Filter to calculate the delta between two timestamp
   *   properties (startTimeUsecs+endTimeUsecs or startTimeSecs+endTimeSecs) of
   *   a single object.
   *
   *   Detects whether to use microseconds or seconds internally. Milliseconds,
   *   being our least common time unit, are not handled internally.
   *
   *   Output, when not humanized, is in the same unit as the inputs. Humanized
   *   output is the default.
   *
   * @example
   *   {{ job | timeDuration:false}}                  // 1068400
   *   {{ job | timeDuration}}                        // 1h 36m
   *   {{ {} | timeDuration:false }}                  // 0
   *   {{ {} | timeDuration }}                        // n/a
   *   {{ {startTimeSecs: thing.startTimeMsecs / 1000} | timeDuration:false}}
   *                                                  // 6542.43
   *
   * @function
   */
  function FilterTimeDuration($filter) {
    return function filter(thing, humanize) {
      thing = thing || {};

      // We want this `true` by default. It's an opt-out argument.
      humanize = !humanize;

      var isSeconds = thing.startTimeSecs > 0;
      var startTime = isSeconds ? thing.startTimeSecs : thing.startTimeUsecs;
      var humanizingFilter = isSeconds ? 'secsToTime' : 'usecsToTime';
      var nowMsecs = Date.clusterNow();
      var oneThousand = 1000;
      var endTime;
      var timeDelta;

      // Sanity check: no startTime
      if (isNaN(startTime)) {
        return !humanize ? 0 : translateString('naNotAvailable');
      }

      // Use given endTime, or the unit-adjusted cluster's now() time.
      endTime = isSeconds ?
        (thing.endTimeSecs || nowMsecs / oneThousand) :
        (thing.endTimeUsecs || nowMsecs * oneThousand);

      timeDelta = endTime - startTime;

      // We're good. Return the timeDelta.
      return !humanize ? timeDelta : $filter(humanizingFilter)(timeDelta);
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.principalName
   * @description
   *   returns a formatted display name for the provided principal
   *
   * @example
   *   {{principalObj | principalName}}
   *   user output: Jeff Howard (jeff@domain.cohesity.com)
   *   group output: Administrators (domain.cohesity.com)
   *
   * @function
   */
  function FilterPrincipalName(NgUserStoreService, $filter) {
    return function principalNameFilterFn(principal, hideDomain) {

      var derivedNameParts;
      var tenantId = NgUserStoreService.getUserTenantId();

      if (!principal) { return; }

      if (typeof principal === 'string') { return principal; }

      // Select the name part which is different for many APIs.
      derivedNameParts = [
        // public/principals (primary)
        // public/principals/searchPrincipals (primary)
        principal.fullName ||

        // public/principals
        // public/principals/searchPrincipals
        principal.principalName ||

        // public/groups
        principal.name ||

        // public/users
        principal.username ||

        // public/views
        principal.ownerSid
      ];

      // If it is a LOCAL tenant user, then show the user with the tenant Id.
      // Else if not a LOCAL tenant user or a tenant user, show the domain
      // name.
      if (tenantId && principal.domain === 'LOCAL') {
        derivedNameParts.push(' (' + $filter('tenantId')(tenantId) + ')');
      } else if (!hideDomain && principal.domain &&
        principal.domain !== 'allDomains') {
        derivedNameParts.push(' (' + principal.domain + ')');
      }

      return derivedNameParts.join('');
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.principalIcon
   * @description
   *   returns an icon class string that matches the provided principal
   *
   * @example
   *   {{principalObj | principalIcon}}
   *   output: 'icn-user'
   *
   * @function
   */
  function FilterPrincipalIcon(cUtils) {
    return function principalIconFilterFn(principal) {

      if (!principal) { return; }

      // Some APIs have different proto. TODO (David): See about getting backend
      // to change proto to match other implementations.
      if (!principal.objectClass && principal.type) {
        principal.objectClass = cUtils.makeKval(principal.type);
      }

      switch (principal.objectClass) {
        case 'kUser':
          return 'icn-sm tag-icn icn-user';

        case 'kGroup':
        case 'kWellKnownPrincipal':
          return 'icn-sm tag-icn icn-group';

        case 'kComputer':
          return 'icn-sm tag-icn icn-machine';

        default:
          return '';
      }

    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.cloudSubscriptionType
   * @description
   *   returns a display string for the cloud subscription types based on type
   *
   * @example
   *   {{subscriptionType | subscriptionType}}
   *   output: 'gov'
   *
   * @function
   */
  function FilterCloudSubscriptionType() {
    return function cloudSubscriptionTypeFilterFn(type) {
      if (type.toLowerCase().indexOf('gov') > -1) {
        return 'gov';
      } else if (type.toLowerCase().indexOf('stack') > -1) {
        return 'stack';
      } else if (type.toLowerCase().indexOf('c2s') > -1) {
        return 'c2s';
      }

      return 'standard';
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.replaceString
   * @description
   *   returns string with spaces replaced with the delimiter provided
   *
   * @param  {string}  sourcePattern   the pattern which has to be replaced
   * @param  {string}  delimiter       the delimiter with which pattern has
   *                                   to be replaced
   * @example
   *  {{roleName | replaceString}}            spaces replaced default by '_'
   *  {{roleName | replaceString:'-':'@'}}    '-' will be replaced by '@'
   *  {{roleName | replaceString:'ab':'xy'}}  'ab' will be replaced by 'xy'
   *
   * @function
   */
  function ReplaceString() {
    return function stringReplaceFn(input, sourcePattern, delimiter) {

      var sourcePatternRegex;

      if (!input || !angular.isString(input)) { return input; }

      // default: spaces will be replaced
      sourcePattern = sourcePattern || ' ';

      // default: delimiter is '_'
      delimiter = delimiter || '_';

      // 'g' flag is added to perform a global search on the pattern
      // new RegExp('pattern', 'flags')
      sourcePatternRegex = new RegExp(sourcePattern, 'g');

      // input trimmed for any leading/trailing spaces
      return input.trim().replace(sourcePatternRegex, delimiter);
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter:filterO365Username
   * @description
   *  Generates the username of the Office 365 server from the SMTP address.
   *
   * @param   {string}   o365SMTPAddress   Specifies the SMTP Address for
   *                                       Office 365 Outlook App.
   *
   * @example
   *  {{'username@cohesity.onmicrosoft.com' | o365Username}} -> username
   *
   * @function
   */
  function FilterO365Username() {
    return function o365UsernameFilterFn(o365SMTPAddress) {
      if (!isString(o365SMTPAddress)) { return; }

      return o365SMTPAddress.split('@')[0];
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter:filterO365MailboxIdentifier
   * @description
   *  Generates the name for uniquely identifying a mailbox with an user in
   *  case of different user name and mailbox name.
   *
   * @param    {string}   mailboxName   Sepcifies the name of Mailbox.
   * @param    {string}   username      Specifies the username of Mailbox.
   *
   * @function
   */
  function FilterO365MailboxIdentifier() {
    return function o365MailboxIdentifierFilterFn(mailboxName, username) {
      if (mailboxName === username) {
        return mailboxName;
      }
      return mailboxName.concat(' - ', username);
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.filterO365DomainIdentifier
   * @description
   *  Generates the GlobalSearch UUID which is stored against the index
   *  magnetostate within ES for Office365 Domain. The Global Search UUID is
   *  the combination of Magneto's EH UUID and the entity type.
   *
   * @param   {string}   domain   Specifies the Office365 domain object
   *
   * @example
   *  {{office365Domain | o365DomainIdentifier}} =>
   *    'kDomain_cohesity.onmicrosoft.com'
   *
   * @function
   */
  function FilterO365DomainIdentifier() {
    return function o365DomainIdentifierFilterFn(domain) {
      var domainEhUuid = domain.o365Entity ?
        domain.o365Entity.uuid : domain.office365ProtectionSource.uuid;
      return ['kDomain', '_', domainEhUuid].join('');
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.displayAlertThreshold
   * @description
   *   Assembles a string to display the Alert Threshold.
   *
   * @example
   *   {{shared.defaultPolicy | displayAlertThreshold}} => 85% (17.5 Gib)
   *
   * @function
   */
  function FilterDisplayAlertThreshold($filter) {
    return function displayAlertThreshold(quota) {
      var quotaBytes = quota && quota.hardLimitBytes;
      var alertBytes = quota && quota.alertLimitBytes;
      var alertPercentage = quota && quota.alertThresholdPercentage;

      if (alertBytes) {
        return $filter('byteSize')(alertBytes, false, 2);
      }

      if (alertPercentage) {
        return [
          alertPercentage,
          '% (',
          $filter('byteSize')(quotaBytes * (alertPercentage / 100), false, 2),
          ')',
        ].join('');
      }

      return translateString('naNotAvailable');
    };
  }

  /**
   * $ngdoc Filter
   * @name C.filter.displayPercentageCompleted
   * @description
   *    Assembles a string to display the percentage completed
   *
   * @example
   *    {{progress.percentFinished | percentageCompleted}} => 50% Completed
   *
   * @function
   */
  function FilterPercentageCompleted($translate) {
    return function displayPercentageCompleted(percentage) {
      // translateString() is not used because we need to send the
      // interpolate params
      return $translate.instant('percentageComplete',
        {percentFinished: percentage});
    };
  }

  /**
   * $ngdoc Filter
   * @name C.filter.displayTimeRemaining
   * @description
   *    Assembles a string to display the Time remaining
   *
   * @example
   *    {{progress.expectedTime | timeRemaining}} => 5m 30s remaining
   *
   * @function
   */
  function FilterTimeRemaining($translate) {
    return function displayTimeRemaining(time) {
      // translateString() is not used because we need to send the
      // interpolate params
      return $translate.instant('timeRemaining',
        {timeRemaining: time});
    };
  }

  /**
   * $ngdoc Filter
   * @name C.filter.protectedIcon
   * @description
   *   Generates the protection status icon class modifier for a node in the
   *   source tree.
   *
   * @example
   *   <i class="source-icon {{::node | protectedIcon}}"></i>
   *
   * @function
   */
  function FilterProtectedIcon(FEATURE_FLAGS) {
    return function filter(node) {
      var nodeEnv = node.entity ?
        node.entity.type :
        node.protectionSource.environment;
      var isSqlEnv = [3, 'kSQL'].includes(nodeEnv);
      var isOracleEnv = [19, 'kOracle'].includes(nodeEnv);

      switch (true) {
        // This node merely has children protected.
        case node._isPartiallyProtected:
          return 'protected-partial';

        // This node is explicitly protected
        case node._isProtected:

        // This node is SQL protected and is a SQL entity.
        case node._isSqlProtected && isSqlEnv:
        case node._isOracleProtected && isOracleEnv:
          return 'protected';
      }

      return '';
    };
  }

  /**
   * @ngdoc filter
   * @name C.filter.filterLocalized
   * @function
   *
   * @description
   *   ~Drop-in replacement for $filter.filter which optionally supports
   *   filtering for translated values using ngTranslate.
   *
   *   This has the same API as $filter.filter, but if you pass a string as the
   *   comparator parameter, that will be used as the object property to
   *   $translate and the value of which to filter against.
   *
   * @example
   *   <ui-select-choices
   *     repeat="item in list | filterLocalized: $select.search: 'nameKey'">
   *     <span
   *       ng-bind-html="item.nameKey | translate | highlight: $select.search">
   *     </span>
   *   </ui-select-choices>
  */
  function FilterFilterLocalized(_, $filter, $log) {
    /**
     * $filter.filter clone that can filter on compiled translation properties
     * instead of the translation key itself.
     *
     * $filter.filter API docs
     *   https://code.angularjs.org/1.5.8/docs/api/ng/filter/filter
     *
     * NOTE: All params are identical to $filter.filter, except for the one
     * listed. below.
     *
     * @method  filterLocalized
     * @param   {string|function|boolean}  [comparator]  Same as $filter.filter,
     *                                                   except here it can also
     *                                                   be a string which will
     *                                                   be used as the property
     *                                                   to pre-translate and
     *                                                   apply filtration
     *                                                   against.
     */
    return function filterLocalized(objs, expression, comparator,
      anyPropertyKey) {
      var _comparator = comparator;

      // Declare this search RegExp here to avoid repeated new RegExps within
      // each iteration of this filter.
      var rxSearch;

      // If `comparator` is a string, use this custom localizedComparator Fn to
      // access that property of the object being filtered, translate the value,
      // and apply filtration on that compiled result.
      if (isString(comparator)) {
        if (!isString(expression)) {
          $log.warn('When using the `filterLocalized` filter and specifying a',
            'property key string, the search expression param also needs to be',
            'a string.');
        }
        rxSearch = new RegExp(expression, 'i');
        _comparator = localizedComparator;
      }

      return $filter('filter')(objs, expression, _comparator, anyPropertyKey);

      /**
       * $filter.filter compatible comparator Fn. See API docs linked above.
       *
       * Uses the string `comparator` from above as the object property to get
       * the translation key from `actual`, translate it, and apply the RegExp
       * search against the compiled result.
       *
       * This gives Users localized string filtration.
       *
       * @method   localizedComparator
       * @param    {*}   actual     The item in being evaluated.
       * @param    {*}   expected   The expression as given to the filter.
       * @return   {boolean}   True if the filter matches. False otherwise.
       */
      function localizedComparator(actual, expected) {
        return rxSearch.test(translateString(get(actual, comparator)));
      }
    };
  }

  /**
   * @ngdoc filter
   * @name C.filter.idScrub
   * @function
   *
   * @description
   *   Converts a given string value into an HTML ID attribute compatible
   *   sub-string for use in generating Automated testing compliant HTML IDs.
   *
   *   ID attribute spec:
   *     https://html.spec.whatwg.org/multipage/dom.html#the-id-attribute
   *
   * @example
   *   <td><a id="click-job-details-{{::job.name | idScrub}}"
   *     ng-click="doAThing()">{{::job.name}}</a></td>
  */
  function FilterIdScrub() {
    return function idScrubFilter(value) {
      return !angular.isString(value) ? value :
        value.replace(/\s/gm, '-');
    };
  }

/**
   * @ngdoc filter
   * @name C.filter.ifaceGroupName
   * @function
   *
   * @description
   *   Returns a interface group name without suffix .0. Other suffixes are
   *   allowed.
   *
   * @example
   *   <div ng-bind-html="target.ifaceGroupName | ifaceGroupName"></div>
  */
  function FilterIfaceGroupName() {
    return function ifaceGroupNameFilter(name) {
      return ('' + name).replace(/\.0$/, '');
    }
  }

  /**
   * @ngdoc filter
   * @name C.filter.publicStatus
   * @function
   *
   * @description
   *   Outputs a status string for the provided publicStatus value.
   *
   * @example
   *   {::jobrun.base.publicStatus | publicStatus}}
   *
   *   input: 'kSuccess',
   *   output: 'Success'
  */
  function PublicStatus(ENUM_PUBLIC_STATUS) {
    return function publicStatusFilter(publicStatus) {
      return translateString(ENUM_PUBLIC_STATUS[publicStatus]);
    };
  }

  /**
   * @ngdoc filter
   * @name C.filter.publicStatusClass
   * @function
   *
   * @description
   *   Outputs a status class string for the provided publicStatus value.
   *
   * @example
   *   {::jobrun.base.publicStatus | publicStatusClass}}
   *
   *   input: 'kSuccess',
   *   output: 'status-success'
  */
  function PublicStatusClass(ENUM_PUBLIC_STATUS_LABEL_CLASSNAME) {
    return function PublicStatusClassFilter(publicStatus) {
      return translateString(ENUM_PUBLIC_STATUS_LABEL_CLASSNAME[publicStatus]);
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.humanizeLargeNumber
   * @description
   *   For any number over a million, the display is rounded and displayed as
   *   "451 Billion"
   *
   * @example
   *   {{metadataActionsCompleted | humanizeLargeNumber}}
   *
   * @function
   */
  function FilterHumanizeLargeNumber($filter) {
    return function humanizeLargeNumber(number) {
      if (number < 1000000) {
        return $filter('number')(number);
      }
      var suffix = ['Thousand', 'Million', 'Billion', 'Trillion'];
      var exp = Math.floor(Math.log(number) / Math.log(1000));
      var result = number / Math.pow(1000, exp);

      return result.toFixed(0) + ' ' + suffix[exp - 1];
    };
  }

  /**
   * @ngdoc filter
   * @name s3KeyMappingFilter
   *
   * @description
   *   Provides a translation key representing a View's S3 Key Mapping setting.
   *
   * @param   {Object}  s3KeyMapping  S3 Key Mapping config.
   * @returns {String}  Translation key mapped to the S3 Key Mapping.
   * @example
    <div class="dl-row">
      <dt>{{::'objectKeyPattern' | translate}}</dt>
      <dd>{{::view.s3KeyMappingConfig | s3KeyMapping | translate}}</dd>
    </div>
   */
  function s3KeyMappingFilterFn() {
    return function filter(s3KeyMapping) {
      if (!s3KeyMapping || (['kHierarchical', 'Hierarchical'].includes(s3KeyMapping))) {
        return 'views.s3.keyPattern.hierarchical';
      }

      if (['kObjectId', 'ObjectId'].includes(s3KeyMapping)) {
        return 'views.s3.keyPattern.objectId';
      }

      if (['kRandom', 'Random'].includes(s3KeyMapping)) {
        return 'views.s3.keyPattern.random';
      }

      if (['kShort', 'Short'].includes(s3KeyMapping)) {
        return 'views.s3.keyPattern.short';
      }

      return 'views.s3.keyPattern.long';
    };
  }

  /**
   * @ngdoc Filter
   * @name  C.filter.removeSpaces
   * @description
   *  Removes spaces from string
   * @example
   *   {{stringWithSpaces | removeSpaces}}
   *
   * @function
   */
  function FilterRemoveSpaces() {
      return function(string) {
        if (!angular.isString(string)) {
            return string;
        }
        return string.replace(/[\s]/g, '');
      }
  };

}(angular));
