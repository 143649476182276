import { assign } from 'lodash-es';
// Component: Policies list component

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.policies';
  var componentName = 'policies';

  var configOptions = {
    bindings: {
      /**
       * Optional attribute to check if the component is opened inside a modal.
       */
      // isModal: '?',

      /**
       * Optional attribute sniffed value. If present, then show the add policy
       * button and allow policy creation inside a modal.
       */
      // allowPolicyCreation: '?',
    },
    require: {
      // Optional ngModel to bind the selected policies to the
      // parent (caller) => to the parent component
      ngModel: '?ngModel',
    },
    controller: 'PoliciesController',
    templateUrl: 'app/protection/policies/policies/policies.html',
  };

  angular.module(modName)
    .controller('PoliciesController', policiesCtrlFn)
    .component(componentName, configOptions);

  function policiesCtrlFn(_, $rootScope, $attrs, evalAJAX, PolicyService,
    DeletePolicyModalService, TenantService, FEATURE_FLAGS) {
    var $ctrl = this;

    var selectedPolicies = {};

    // declare component methods
    assign($ctrl, {
      // Component methods
      addPolicyModal: addPolicyModal,
      deletePolicy: deletePolicy,
      filterPolicies: getPolicies,
      isSelected: isSelected,
      updateSelection: updateSelection,

      // component life cycle methods
      $onInit: $onInit,
    });

    /**
     * initialize component context
     *
     * @method   _initializeCtrl
     */
    function _initializeCtrl() {
      assign($ctrl, {
        // UI States
        isFetchingPolicies: false,

        isModal: $attrs.hasOwnProperty('isModal'),
        allowPolicyCreation: $attrs.hasOwnProperty('allowPolicyCreation'),

        policies: [],

        policyTypes: [
          {
            translateKey: 'rpoPolicy',
            id: 'new-rpo-policy-anchor',
            state: 'protection-policy.rpo-modify',
          },
          {
            translateKey: 'standardPolicy',
            id: 'new-standard-policy-anchor',
            state: FEATURE_FLAGS.ngCreatePolicy ?
              'protection-policy.modify' : 'policy-modify',
          },
        ],

        // Policy filters.
        filters: {
          tenantIds: [],
        },
      });
    }

    /**
     * activation function.
     */
    function $onInit() {
      _initializeCtrl();

      if ($ctrl.ngModel) {
        $ctrl.ngModel.$render = function updateInternalModalOnExternalChange() {
          selectedPolicies = $ctrl.ngModel.$modelValue;
        };
      }

      getPolicies();
    }

    /**
     * presents modal responsible for handling confirmation and deletion of
     * policy. on successful delete, removes policy from listing
     *
     * @param      {Object}  policy  to be deleted
     */
    function deletePolicy(policy) {

      DeletePolicyModalService.open(policy).then(
        function deleteSuccess(deleted) {
          var deletedPolicyIndex = $ctrl.policies.indexOf(policy);
          if (deleted) {
            // remove deleted policy from $ctrl.policies
            $ctrl.policies.splice(deletedPolicyIndex, 1);
          }
        }
      );

    }

    /**
     * interfaces PolicyService to get a list of policies, and
     * adds them to our scope object
     *
     * @method   getPolicies
     */
    function getPolicies() {
      var params = TenantService.extendWithTenantParams({
        _includeTenantInfo: true,
      }, $ctrl.filters.tenantIds);

      $ctrl.isFetchingPolicies = true;

      PolicyService.getPolicies(params).then(
        function getPoliciesSuccess(policies) {
          $ctrl.policies = policies;

          angular.forEach($ctrl.policies,
            function postProcessPolicies(policy) {
              policy._actionsMenu = buildPolicyActions(policy);
            }
          );
        }, evalAJAX.errorMessage)
        .finally(function getPoliciesFinally() {
          $ctrl.isFetchingPolicies = false;
        });
    }

    /**
     * builds and returns the context actions for a given policy
     *
     * @method     buildPolicyActions
     * @param      {object}  policy  to build context actions for
     * @return     {array}   actions available for the policy
     */
    function buildPolicyActions(policy) {
      var config = [];
      var policyModifyState;

      if (!$rootScope.user.privs.PROTECTION_POLICY_MODIFY) {
        return config;
      }

      // Determine policy modify state
      if (policy.type === 'kRPO') {
        policyModifyState = 'protection-policy.rpo-modify'
      } else {
        policyModifyState = FEATURE_FLAGS.ngCreatePolicy ?
          'protection-policy.modify' : 'policy-modify';
      }

      config.push({
        translateKey: 'copyPolicy',
        icon: 'icn-copy',
        state: 'policy-modify',
        stateParams: {
          // make a copy of the Policy to ensure no circular references will
          // be created, since actions config object will be attached directly
          // to the Policy.
          copyPolicy: angular.copy(policy),
        },
      }, {
        translateKey: 'editPolicy',
        icon: 'icn-edit',
        state: policyModifyState,
        disabled: policy._wormLocked && !$rootScope.user.privs.DATA_SECURITY,
        disabledTooltipKey: 'policy.editPolicyWormLockedTooltip',
        stateParams: {
          policyId: policy.id,
        },
      }, {
        translateKey: 'deletePolicy',
        icon: 'icn-delete',
        action: function deletePolicyWrapper() {
          $ctrl.deletePolicy(policy);
        }
      });

      return config;
    }

    /**
     * Checks if the policy is selected.
     *
     * @method   isSelected
     * @param    {Object}   policy   The policy to check.
     * @return   {Object}   the policy if it's selected.
     */
    function isSelected(policy) {
      return selectedPolicies[policy.id];
    }

    /**
     * Updates the selected policies with the given policy.
     *
     * @method   updateSelection
     * @param    {Object}   policy   The policy to be selected.
     */
    function updateSelection(policy) {
      selectedPolicies[policy.id] = isSelected(policy) ? undefined : policy;
      if ($ctrl.ngModel) {
        $ctrl.ngModel.$setViewValue(selectedPolicies);
      }
    }

    /**
     * Opens a modal for policy creation. After successful creation
     * updates the list and selects it by default.
     *
     * @method   addPolicyModal
     */
    function addPolicyModal() {
      PolicyService.modifyPolicyModal()
        .then(function policyCreated(modalResp) {
          $ctrl.policies.push(modalResp.policy);
          updateSelection(modalResp.policy);
        });
    }
  }

})(angular);
