import { Injectable } from '@angular/core';
import { ProtectedObject } from '@cohesity/api/v2';
import { NavItem } from '@cohesity/helix';
import { StateService } from '@uirouter/core';
import { PassthroughOptions, RecoveryAction } from 'src/app/shared';

import { ProtectionGroupService } from '../protection-group-shared';
import { RestorePointSelection } from '../restore/restore-shared';
import { ObjectActionCreator } from './object-action-creator';
import { ProtectedObjectsService } from './protected-objects.service';

@Injectable({
  providedIn: 'root',
})
export class MongodbPhysicalObjectActionCreator extends ObjectActionCreator {
  constructor(
    objectsService: ProtectedObjectsService,
    protectionGroupService: ProtectionGroupService,
    stateService: StateService) {
    super(objectsService, protectionGroupService, stateService);
  }

  /**
   * Creates and returns MOngodb Physical recovery action.
   *
   * @param object The protected object.
   * @returns The recovery NavItem object.
   */
  createMongodbPhysicalRecoverAction(object: ProtectedObject,
    restorePointSelection?: RestorePointSelection,
    objectOptions?: PassthroughOptions): NavItem {
    if (!object?.latestSnapshotsInfo) {
      return;
    }

    const restorePoints = super.getRestorePointSelection([object], restorePointSelection);

    if (!restorePoints) {
      return;
    }

    return {
      displayName: 'Recover',
      icon: 'restore',
      state: 'recover-mongodb-physical-ng',
      stateParams: {
        restorePoints: restorePoints,
        objectId: object.id,
        objectName: object.name,
        recoveryAction:  RecoveryAction.RecoverMongodbClusters,
        cid: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
      }
    };
  }
}
