<!-- Grouping both disk handling options under one section -->


<cog-settings-list-item
    cogFormGroup
    *ngIf="!readOnly && recoverExcludedDisksFlag"
    [label]="'excludeDiskHandling' | translate">

  <cog-settings-list-item-value-display>
    {{ recoverExcludedDisks.value | boolLabel: 'on' : 'off' }}
  </cog-settings-list-item-value-display>

  <cog-settings-list-item-value-display>
    {{ recoverIndependentDisks.value | boolLabel: 'on' : 'off' }}
  </cog-settings-list-item-value-display>

  <!-- Editable form for recoverExcludedDisks with mat-slide-toggle -->
  <cog-settings-list-item-value-editor>
    <mat-slide-toggle
      [formControl]="recoverExcludedDisks"
      [inheritDataId]="true"
      cogDataId="recover-excluded-disks">
      {{ 'recoverExcludedDisksLabel' | translate }}
    </mat-slide-toggle>
  </cog-settings-list-item-value-editor>

  <!-- Editable form for recoverIndependentDisks with mat-slide-toggle -->
  <cog-settings-list-item-value-editor>
    <mat-slide-toggle [formControl]="recoverIndependentDisks"
      [inheritDataId]="true"
      cogDataId="recover-independent-disks">
      {{ 'recoverIndependentDisksLabel' | translate }}
    </mat-slide-toggle>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>

<cog-settings-list-item *ngIf="readOnly"
  cogFormGroup
  [label]="'recoverExcludedDisksLabel' | translate">
  <cog-settings-list-item-value-display>
    {{ recoverExcludedDisks.value | boolLabel: 'on' : 'off' }}
  </cog-settings-list-item-value-display>
</cog-settings-list-item>

<cog-settings-list-item  *ngIf="readOnly"
  cogFormGroup
  [label]="'recoverIndependentDisksLabel' | translate">
  <cog-settings-list-item-value-display>
    {{ recoverIndependentDisks.value | boolLabel: 'on' : 'off' }}
  </cog-settings-list-item-value-display>
</cog-settings-list-item>
