import { forEach } from 'lodash-es';

import { Environment } from './environment.constants';

/**
 * Environment group types. Make sure you check with UX first before adding new env groups.
 * Make sure this type is in sync with envGroups.
 * 'unknown' and 'other' are UI defined groups:
 * 'unknown' is for any environment which UI don't understand.
 * 'other' is for the union of all remaining groups not displayed for cases when UI can only show 3 groups.
 */
export type EnvGroup =
  'adapter' |
  'applications' |
  'dbs' |
  'hadoop' |
  'kubernetes' |
  'nas' |
  'other' |
  'physical' |
  'san' |
  'unknown' |
  'views' |
  'vms' |
  'office365' |
  'uda' |
  'saphana'|
  // TODO(ang): Remove the following group because each environment can only belong to one group
  // and is defined in envGroups.
  // If an environment belongs to other group like the following, create separate tagging data
  // structure outside EnvGroup/envGroups.
  'files' |
  'cloud' |
  'cloudNative' |
  'csm' |
  'aws'|
  'salesforce' |
  's3' |
  'azure' |
  'storageSnapshotProvider';

/**
 * Map environment group to data.
 */
export type GroupData = {
  [K in EnvGroup]?: number[];
};

/**
 * Map environment enum values to environment group names.
 */
export type EnumGroup = {
  [K in Environment]?: EnvGroup;
};

/**
 * Map environment group to environment enum values.
 */
export type GroupEnum = {
  [K in EnvGroup]?: Environment[];
};

/**
 * Map of environment groups to enum values. Note that each environment can only
 * belong to one group.
 *
 * Note: If any groups are added/modified, also
 *   1. add/update the translation in ui.json under enum.envGroup.*
 *   2. update environmentMap in icon.constants.ts
 *   3. update the following protectionEnvGroups
 *
 * Note: These are in a particular order to match the presentation in
 * register/protect/recover buttons.
 */
export const envGroups: GroupEnum = {
  vms: [
    Environment.kAcropolis,
    Environment.kAWS,
    Environment.kAzure,
    Environment.kGCP,
    Environment.kHyperV,
    Environment.kHyperVVSS,
    Environment.kKVM,
    Environment.kVCD,
    Environment.kVMware,
  ],
  dbs: [
    Environment.kAuroraSnapshotManager,
    Environment.kCassandra,
    Environment.kCouchbase,
    Environment.kMongoDB,
    Environment.kOracle,
    Environment.kRDSSnapshotManager,
    Environment.kSQL,
    Environment.kUDA,
    Environment.kSAPHANA,
  ],
  nas: [
    Environment.kNetapp,
    Environment.kIsilon,
    Environment.kGenericNas,
    Environment.kFlashBlade,
    Environment.kGPFS,
    Environment.kElastifile
  ],
  office365: [
    Environment.kO365,
    Environment.kO365Exchange,
    Environment.kO365Group,
    Environment.kO365OneDrive,
    Environment.kO365PublicFolders,
    Environment.kO365Sharepoint,
    Environment.kO365Teams,
  ],

  physical: [
    Environment.kPhysical,
    Environment.kPhysicalFiles
  ],
  applications: [
    Environment.kAD,
    Environment.kExchange,
    Environment.kO365Outlook,

    // N/A for Protection/Dashboard
    Environment.kAgent
  ],
  san: [
    Environment.kNimble,
    Environment.kPure,
    Environment.kIbmFlashSystem,
    Environment.kHpe3Par,
  ],
  views: [
    Environment.kView
  ],
  hadoop: [
    Environment.kHBase,
    Environment.kHive,
    Environment.kHdfs,
  ],
  adapter: [
    Environment.kRemoteAdapter,
    Environment.kPuppeteer
  ],
  kubernetes: [
    Environment.kKubernetes
  ],
  salesforce: [
    Environment.kSfdc,
  ],
  s3: [
    Environment.kAwsS3
  ],
  saphana: [
    Environment.kSAPHANA
  ],
};

/**
 * Logical Grouping of different Cloud Environments
 */
export const cloudGroups: GroupEnum = {
  csm: [
    Environment.kAWS,
    Environment.kAzure,
  ],
  cloudNative: [
    Environment.kAWS,
    Environment.kAzure,
  ],
  cloud: [
    Environment.kAWS,
    Environment.kAzure,
    Environment.kGCP,
  ]
};

/**
 * Map of groups to enum values, for DMaaS context.
 * Most of this is the same as on-prem envGroups (and hence envGroups is used as a starting point for this).
 */
export const dmsGroups: GroupEnum = {
  ...envGroups,
  nas: [
    Environment.kNetapp,
    Environment.kIsilon,
    Environment.kGenericNas,
  ],
  vms: envGroups['vms'].filter(val => ![Environment.kAWS, Environment.kAzure].includes(val)),
  dbs: envGroups['dbs'].filter(
    val => val !== Environment.kAuroraSnapshotManager && val !== Environment.kRDSSnapshotManager
  ),
  aws: [
    Environment.kAWS,
    Environment.kAWSSnapshotManager,
    Environment.kRDSSnapshotManager,
    Environment.kAwsS3,
    Environment.kAwsDynamoDB,
  ],
  azure: [
    Environment.kAzure,
    Environment.kAzureSnapshotManager,
    Environment.kAzureNative,
    Environment.kAzureSQL,
    Environment.kAzureEntraID,
  ],
};

/**
 * @description
 * Map of environment enums to environment groups.
 *
 * @example
 *   { kVMware: 'vms',  kSQL: 'dbs' }
 */
export const enumGroupMap: EnumGroup = {};

//  Map env enums to env groups.
forEach(envGroups, (enums: string[], key: string) => {
  enums.forEach(val => enumGroupMap[val] = key);
});

/**
 * Adapter item with parent group name and its child environments.
 */
export interface Adapter {
  /**
   * Name that summarizes all the included environments.
   */
  envGroupName: EnvGroup;

  /**
   * Environments that will be visible in customization UI.
   */
  environments?: Environment[];

  /**
   * Environments that will be checked, but will be hidden in customization UI.
   */
  hiddenEnvironments?: Environment[];
}

/**
 * List of adapters that will be populated in customization component.
 */
export const adapters: Adapter[] = [
  {
    envGroupName: 'vms',
    environments: [
      Environment.kAcropolis,
      Environment.kAWS,
      Environment.kAzure,
      Environment.kGCP,
      Environment.kHyperV,
      Environment.kHyperVVSS,
      Environment.kKVM,
      Environment.kVCD,
      Environment.kVMware,
    ],
  },
  {
    envGroupName: 'dbs',
    environments: [
      Environment.kAuroraSnapshotManager,
      Environment.kAWS,
      Environment.kCassandra,
      Environment.kCouchbase,
      Environment.kMongoDB,
      Environment.kOracle,
      Environment.kSQL,
      Environment.kRDSSnapshotManager,
    ],
    hiddenEnvironments: [
      Environment.kUDA,
    ]
  },
  {
    envGroupName: 'nas',
    environments: [
      Environment.kElastifile,
      Environment.kFlashBlade,
      Environment.kGenericNas,
      Environment.kGPFS,
      Environment.kIsilon,
      Environment.kNetapp,
    ],
  },
  {
    envGroupName: 'office365',
    hiddenEnvironments: [
      Environment.kO365,
      Environment.kO365Exchange,
      Environment.kO365Group,
      Environment.kO365PublicFolders,
      Environment.kO365OneDrive,
      Environment.kO365Sharepoint,
      Environment.kO365Teams,
    ],
    environments: [Environment.kO365Outlook],
  },
  {
    envGroupName: 'physical',
    environments: [Environment.kPhysical, Environment.kPhysicalFiles],
  },
  {
    envGroupName: 'applications',
    environments: [Environment.kAD, Environment.kExchange],
    hiddenEnvironments: [ Environment.kO365Outlook, Environment.kAgent ]
  },
  {
    envGroupName: 'san',
    environments: [Environment.kNimble, Environment.kPure, Environment.kIbmFlashSystem, Environment.kHpe3Par],
  },
  {
    envGroupName: 'views',
    hiddenEnvironments: [Environment.kView],
  },
  {
    envGroupName: 'hadoop',
    environments: [Environment.kHBase, Environment.kHdfs, Environment.kHive],
  },
  {
    envGroupName: 'adapter',
    environments: [Environment.kRemoteAdapter],
    hiddenEnvironments: [Environment.kPuppeteer],
  },
  {
    envGroupName: 'kubernetes',
    hiddenEnvironments: [Environment.kKubernetes],
  },
  {
    envGroupName: 'uda',
    hiddenEnvironments: [Environment.kUDA],
  },
  {
    envGroupName: 'saphana',
    environments: [Environment.kSAPHANA],
  },
  {
    envGroupName: 's3',
    environments:  [Environment.kAwsS3],
  },
  {
    envGroupName: 'salesforce',
    hiddenEnvironments: [Environment.kSfdc]
  },
  {
    envGroupName: 'storageSnapshotProvider',
    hiddenEnvironments: [Environment.kStorageSnapshotProvider]
  }
];

/**
 * Map adapter group name to adapter object.
 */
export const adaptersMap: { [key in string]: Adapter } = adapters.reduce((p, c) => ({ ...p, [c.envGroupName]: c }), {});

/**
 * reports are using these subtypes of kO365 environment.
 */
export const reportingM365Subtypes = [
  Environment.kO365Exchange,
  Environment.kO365Group,
  Environment.kO365OneDrive,
  Environment.kO365Sharepoint,
  Environment.kO365Teams
];
