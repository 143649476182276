import { chain } from 'lodash-es';
import { keyBy } from 'lodash-es';
import { remove } from 'lodash-es';
import { forEach } from 'lodash-es';
import { uniqBy } from 'lodash-es';
import { assign } from 'lodash-es';
// Module & Component Controller: cRolePrivileges

;(function(angular) {
  'use strict';

  angular.module('C.rolePrivileges', [])
    .controller('RolePrivilegesCtrl', RolePrivilegesCtrlFn)
    .component('cRolePrivileges', {
      bindings: {
        // Optional value to indicate editing an existing role
        editMode: '<?',

        // Filter privileges based on predicate. One of 'none',
        // 'accessManagement', 'helios' and 'nonHelios'.
        filter: '<?',

        // Hash of privileges grouped by category. Optional if readonly.
        privileges: '=?',

        // Array of all available privileges. Optional, without this
        // the component fires additional API calls.
        availablePrivileges: '=?',

        // Optional value to indicate read only view
        readOnly: '<?',

        // Role object which is pushed to the API
        role: '<',
      },
      controller: 'RolePrivilegesCtrl',
      template: require('raw-loader!./c-role-privileges.html').default,
    });

  /**
   * @ngdoc component
   * @name C.rolePrivileges:cRolePrivileges
   * @function
   *
   * @description
   * Displays all the different roles values for creating custom Roles.
   *
   * @example
      <c-role-privileges
        privileges="privileges"
        role="role">
      </c-role-privileges>
   */
  function RolePrivilegesCtrlFn(_, $rootScope, $scope, $state, $q, $translate,
    NgRemoteClusterService, NgUserStoreService, UserService, evalAJAX) {
    var $ctrl = this;

    $ctrl.$onInit = $onInit;

    // This value is optional for readonly view
    $ctrl.privileges = $ctrl.privileges || {};

    // This value is optional. By default show all privileges.
    $ctrl.filter = $ctrl.filter || 'none';

    $scope.userPrivs = NgUserStoreService.getUserPrivs();

    var SOURCE_CATEGORY = 'Source Management';
    var SOURCE_MODIFY = 'PROTECTION_SOURCE_MODIFY';
    var MANAGE_ALL_SOURCES = 'MANAGE_PROTECTION_SOURCE_ALL';

    /**
     * Checks if the user can modify the given role.
     *
     * @method   canModify
     * @param    {Object}   role   The role object to check against.
     * @returns  {Boolean}  True, if the user is able to modify else false.
     */
    $scope.canModify = function canModify(role) {
      return role.isCustomRole && $rootScope.user.privs.PRINCIPAL_MODIFY &&
        role._isRoleOwner;
    };

    /**
     * Function to return the title of the <c-role-privileges> based on the
     * context of usage.
     *
     * @returns   {string}   Formatted string
     */
    $scope.getTitle = function getTitle() {
      switch ($ctrl.filter) {
        case 'accessManagement':
          return $translate.instant('accessManagementPrivileges');
        case 'helios':
          return $translate.instant('multiClusterPrivileges');
        case 'nonHelios':
          return $translate.instant('localClusterPrivileges');
        case 'service':
          return $translate.instant('servicePrivileges');
        default:
          return $translate.instant('setPrivileges');
      }
    };

    /**
     * Component initialization function
     *
     * @method   $onInit
     */
    function $onInit() {
      var mcmMode = $rootScope.basicClusterInfo.mcmMode;
      var selectedScope = NgRemoteClusterService.selectedScope;
      var promise;

      if (!$ctrl.role.hasOwnProperty('isCustomRole')) {
        // Set it by default to true in case of new roles (new role objects are
        // not seeded with this key).
        $ctrl.role.isCustomRole = true;
      }

      if (!$ctrl.role.hasOwnProperty('_isRoleOwner')) {
        // <c-role-privileges> relies on the synthetic `_isRoleOwner` to
        // determine if the role is owned by the logged in user (and then only
        // the role is editable). In Helios, all custom roles are editable by
        // users who have modify privileges and this synthetic key is not added.
        // Also, set it by default to true in case of new roles.
        $ctrl.role._isRoleOwner = true;
      }

      if ($ctrl.availablePrivileges) {
        // If privileges array is provided, use that over making additional
        // API calls.
        promise = new Promise(function resolvePromise(resolve) {
          return resolve($ctrl.availablePrivileges);
        });
      } else if (mcmMode && selectedScope._allClusters) {
        // If privileges are shown in Helios, call MCM API to fetch the
        // privileges.
        promise = UserService.getAllMcmPrivileges();
      } else {
        // Otherwise make the default API call (this also works on Helios, but
        // should not be called).
        promise = UserService.getAllPrivileges();
      }

      promise.then(function getAllSuccess(response) {
        var accessMgmtText = $translate.instant('accessManagement');
        var privileges = response.filter(function filterPrivileges(privilege) {
          var isAccessMgmtPriv = privilege.category.includes(accessMgmtText);
          var isServicePriv = privilege.isAvailableOnHelios &&
            privilege.isSaaSOnly;
          // TODO(pg): ENG-80959 there will be an enum filter present for
          // for categorizing the three kinds of privileges (Access Management,
          // Helios and Non Helios). Until then, rely on existing boolean and
          // category name.
          switch ($ctrl.filter) {
            case 'service':
              return isServicePriv;
            case 'accessManagement':
              // Only show Access Management related privileges
              return isAccessMgmtPriv;
            case 'helios':
              // Only show privileges which are relevant to Helios
              return privilege.isAvailableOnHelios && !isServicePriv &&
                !isAccessMgmtPriv;
            case 'nonHelios':
              // Only show privileges which are not relevant to Helios
              return !privilege.isAvailableOnHelios && !isServicePriv &&
                !isAccessMgmtPriv;
            default:
              // Show all privileges (helios + nonHelios)
              return true;
          }
        });

        // Integrate privileges *after* populating role model because we want
        // to decorate the privileges model to indicate which privileges
        // should already be selected.
        processPrivileges(privileges);
      }, evalAJAX.errorMessage
      ).finally(function getAllFinally() {
        $scope.isDataReady = true;
      });
    }

    /**
     * Process the flat privileges array and create a categorized hash used by
     * ng-repeat for rendering
     *
     * @method     processPrivileges
     * @param      {Array}  privileges   List of privileges
     */
    function processPrivileges(privileges) {
      var tempHashOfPrivileges = {};
      var initialSelections = $ctrl.role.privileges;

      // Since the UI already has an all selector. It is redundant to show the
      // privilege again. We will determine if this privilege needs to be added
      // during the creation or editing of the role.
      remove(privileges, {name:MANAGE_ALL_SOURCES});

      // Get only unique Privilege ids from the backend, In case of Helios,
      // some of privs might be repeated for services like BaaS, RPaaS,...
      privileges = uniqBy(privileges, 'PrivilegeId');

      // The grouped privileges by category.
      var groupedPrivileges = chain(privileges).groupBy('category').value();

      // To process the category with default selections or in case of edit
      // we will be populating selected values.
      var processedCategory = {};

      // 1. For Each category, we will be doing the following
      // 1a. Check if the privileges are present in the initial selection
      //     if so then,  we will mark it as selected.
      // 2a. If all the privileges in a category is selected, we will mark the
      //     the entire category as selected,
      forEach(groupedPrivileges, function processCategory(privileges, key) {
        var currentCount = 0;

        // Loop through all the privilege and mark if it needs to be checked
        privileges.forEach(function addChecked(privilege) {
          var isCheckApplicable =
            $ctrl.editMode ? false : privilege.isCustomRoleDefault;
          privilege.checked = isCheckApplicable ||
            initialSelections.includes(privilege.name)
          currentCount += privilege.checked ? 1 : 0
        });

        var categorySelected = currentCount == privileges.length;

        // Special handling for Source Modification. Select the entire catrgory
        // If the role has protection_source_modify_all.
        if (key === SOURCE_CATEGORY) {
          categorySelected = initialSelections.includes(MANAGE_ALL_SOURCES);
        }

        processedCategory[key] = {
          selectAll: categorySelected,
          privileges: keyBy(privileges, 'name'),
        };
      });

      assign($ctrl.privileges, processedCategory);
    }

    /**
     * Handles the change selection when a privilege is set.
     *
     * @method   toggleAll
     * @param    {object}   catName   The category that is being set to ALL.
     */
    $scope.toggleAll = function toggleAll(catName) {
      // If the category is not "Source Management" then return;
      if (catName !== SOURCE_CATEGORY) {
        return;
      }

      var sourcePrivs = $ctrl.privileges[SOURCE_CATEGORY];
      if (sourcePrivs.selectAll) {
        // User clicked ALL for Source Management, so clear the individual privs within.
        forEach(sourcePrivs.privileges, function setSourceRole(val) {
          val.checked = false;
        });

      }
    }
  }
})(angular);
